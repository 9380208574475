<template>
    <v-alert
        dense
        dark
        prominent
        icon="lock"
        color="_accent"
        class="mb-0"
    >
        <v-row align="center">
            <v-col class="grow">
                Bu sayfayı yalnızca PREMIUM kullanıcılarımız görüntüleyebilir.
            </v-col>
            <v-col class="shrink">
                <v-btn
                    text
                    color="white"
                    outlined
                    @click="toPremiumInfo"
                >
                    Katıl
                </v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>
<script>
export default {
    name: 'PremiumAlert',
    methods: {
        toPremiumInfo() {
            this.$router.push({name: 'Premium'});
        }
    },
}
</script>
<style scoped>
</style>
