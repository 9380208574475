<template>
  <div class="const_width">
    <v-container>
      <v-col
          cols="12"
          md="6"
          class="mx-auto"
      >
        <div class="content_container">
          <div class="px-10 pt-3 pb-6">

            <h2 class="text-center">Kayıt Ol</h2>
            <v-form ref="form" lazy-validation>
              <v-text-field
                  v-model="register.name"
                  color="c2"
                  type="text"
                  :rules="requiredRule"
                  label="Ad Soyad"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="register.username"
                  color="c2"
                  type="text"
                  :rules="requiredRule"
                  label="Kullanıcı Adı"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="register.email"
                  type="email"
                  :rules="requiredRule"
                  color="c2"
                  label="E-posta"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="register.password"
                  color="c2"
                  type="password"
                  :rules="requiredRule"
                  label="Şifre"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="passwordRepeat"
                  color="c2"
                  type="password"
                  :rules="requiredRule"
                  label="Şifre Tekrar"
                  required
              ></v-text-field>

              <v-card-actions class="px-0">
                <v-btn
                    tile
                    block
                    color="_c1"
                    dark
                    @click="validate"
                >
                  Kayıt Ol
                </v-btn>
              </v-card-actions>
            </v-form>
          </div>
        </div>
      </v-col>
    </v-container>
    <v-snackbar
        top
        :color="messageObj.style"
        timeout="2000"
        v-model="snackbar"
    >
      {{ messageObj.text }}
    </v-snackbar>
  </div>
</template>

<script>
import api3 from "../../api";

export default {
  name: "Register",
  data() {
    return {
      snackbar: false,
      messageObj: {
        text: '',
        style: '',
      },
      passwordRepeat: '',
      register: {
        name: '',
        username: '',
        email: '',
        password: '',
      },
      requiredRule: [
        v => !!v || 'Bu alan boş bırakılamaz!',
      ],
    }
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        if (this.register.password === this.passwordRepeat) {
          this.registerRequest();
        } else {
          this.messageObj = {text: 'Girdiğiniz şifreler uyuşmuyor!', style: 'error'}
          this.snackbar = true;
        }
      }
    },

    async registerRequest() {
      await api3.post('api/v5/user/register', this.register,
          {
            headers: {
              Platform: 'FutbolVerileri'
            }
          }
      ).then((data) => {
        if (data.data.success) {
          this.messageObj = {text: 'Üye kaydınız başarılı!', style: 'success'}
          setTimeout(this.goHome, 2000);
        } else {
          this.messageObj = {text: data.data.apiError.errorMessage, style: 'error'}
        }
        this.snackbar = true;
      });
    },

    resetValidation() {
      this.$refs.form.resetValidation()
    },

    goHome() {
      this.$router.push('/');
    }
  },
}
</script>

<style scoped>

</style>