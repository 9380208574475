<template>
  <div>
    <v-skeleton-loader
        v-if="!data"
        class="rf_skeleton"
        type="table-heading@18"
    ></v-skeleton-loader>
    <div v-for="(i,ii) in data.matchesByLeague" :key="ii">
      <div v-for="(r,ri) in i.matches" :key="ri">
        <h2 :key="ri" class="fv_title">{{ r.round }}. Hafta</h2>
        <div :key="`h-${ri}`" class="c_header _header _accent--text">
          <div class="ch_date dis-center">Tarih</div>
        </div>
        <div class="fixture-for-web" :key="r.id">
          <div class="cm_date">{{ matchDate(r.dateTimeUtc) }}</div>
          <div class="teams">
            <div class="cm_home_team">
              <span @click="toTeamDetail(r.homeTeamId)">{{ r.homeTeam }}</span>
            </div>
            <div class="cm_score">
              <router-link :to="{name: 'MDCompare', params: {id: r.id}}" target="_blank">-</router-link>
            </div>
            <div class="cm_away_team">
              <span @click="toTeamDetail(r.awayTeamId)">{{ r.awayTeam }}</span>
            </div>
          </div>
          <div class="empty-section"></div>
        </div>

        <div class="fixture-for-mobile" :key="r.id">
          <div class="cm_date">{{ matchDateForMobile(r.dateTimeUtc) }}</div>
          <div class="cm_home_team">
            <span @click="toTeamDetail(r.homeTeamId)">{{ r.homeTeam }}</span>
          </div>
          <div class="cm_score">
            <router-link :to="{name: 'MDCompare', params: {id: r.id}}" target="_blank">-</router-link>
          </div>
          <div class="cm_away_team">
            <span @click="toTeamDetail(r.awayTeamId)">{{ r.awayTeam }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {fetchTeamFixtures} from "../../api";
import dayjs from "dayjs";

export default {
  name: "Fixture2",
  data() {
    return {
      data: null
    }
  },
  mounted() {
    this.fetchMatches()
  },
  methods: {
    /*async fetchSeason() {
      const response = await fetchTeamSeasonId(this.$route.params.id);
      this.seasonId = response.data.data.seasonGroups[0].seasons[0].seasonId;
      this.fetchMatches();
    },*/
    async fetchMatches() {
      const data = await fetchTeamFixtures(this.$route.params.id);
      this.data = data.data.data;
    },
    matchDate(value) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    matchDateForMobile(value) {
      return dayjs(value).format("DD/MM/YY");
    },
    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
  },
  watch: {
    $route() {
      this.data = null;
      this.fetchMatches();
      this.fetchMatchesFixture()
    },

    tab() {
      this.data = null;
      this.fetchMatches();
      this.fetchMatchesFixture()
    }
  }
}
</script>

<style scoped>

.cm_score, .cm_half_time, .cm_bts, .cm_under_over, .ch_bts, .ch_under_over, .ch_corner, .cm_corner, .ch_half_time {
  text-align: center;
}

.cm_bts, .cm_under_over {
  font-weight: bold;
}

.cm_score {
  font-weight: 800;
}

.cm_half_time, .cm_corner {
  color: #b2b2b2;
}

.fixture-for-web {
  display: flex;
}

.fixture-for-mobile {
  display: none;
}

.cm_date {
  flex: 1.5;
}

.teams {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 2;
}

.dis-center {
  display: flex;
  justify-content: center;
}

.empty-section {
  width: 20%;
  height: auto;
  flex: 1.5;
}

.cm_home_team {
  display: flex;
  justify-content: center;
  width: 160px;
}

.cm_away_team {
  display: flex;
  justify-content: center;
  width: 160px;
}

.cm_home_team span, .cm_away_team span {
  cursor: pointer;
}

.rf_skeleton {
  margin-top: 15px;
}

@media only screen and (min-width: 650px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(120px, 300px) 50px minmax(120px, 300px) 45px 45px 30px 30px;
  }

  .c_header_league {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: auto 45px 45px 30px 30px;
  }

  .fixture-for-web {
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px 36px minmax(84px, 300px) 50px minmax(84px, 300px) 36px 45px 45px 30px 30px;
  }

  .cm_home_team {
    text-align: right;
  }

  .cm_home_team .cards {
    margin-left: auto;
  }

  .cm_half_time .only_mobile, .cm_corner .only_mobile {
    display: none;
  }

  .sub_tab.only_mobile {
    margin-left: auto;
  }

}

@media only screen and (max-width: 650px) {
  .c_header {
    display: none;
  }
  .ch_date {
    grid-area: date;
  }

  .dis-center {
    display: flex;
    justify-content: center;
  }

  .ch_score {
    grid-area: score
  }

  .ch_half_time {
    grid-area: halftime
  }

  .ch_under_over {
    grid-area: underover
  }

  .ch_bts {
    grid-area: bts
  }

  .ch_away_team {
    display: none
  }

  .ch_home_team {
    grid-area: teams
  }

  .fixture-for-web {
    display: none;
  }

  .fixture-for-mobile {
    display: grid;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 40px minmax(90px, 160px) auto minmax(90px, 160px);
    grid-template-areas: "date hometeam score awayteam ";
  }

  .ch_half_time, .ch_corner {
    display: none;
  }

  .cm_date {
    padding-top: 0;
    grid-area: date;
  }

  .cm_home_team {
    grid-area: hometeam;
    width: 100%;
    text-align: right;
  }

  .cm_away_team {
    grid-area: awayteam;
    width: 100%;
    text-align: left;
  }

  .cm_score {
    padding-top: 0;
    grid-area: score;
  }

  .cm_home_team {
    grid-area: hometeam;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .cm_away_team {
    grid-area: awayteam;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .cm_half_time {
    display: none;
  }

  .cm_corner {
    display: none;
  }

  .cm_under_over {
    padding-top: 12px;
    grid-area: underover;
  }

  .cm_bts {
    padding-top: 12px;
    grid-area: bts;
  }

  .cards {
    display: none;
  }

  .cm_match_status {
    display: none;
  }
}

@media only screen and (max-width: 426px) {

  .c_header {
    display: none;
  }

  .fixture-for-web {
    display: none;
  }

  .fixture-for-mobile {
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 50px minmax(50px, 140px) auto minmax(50px, 140px);
  }

  .cm_home_team {
    grid-area: hometeam;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .cm_away_team {
    grid-area: awayteam;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .cm_score {
    padding-top: 0;
    grid-area: score;
    margin: 0 10px;
  }
}

</style>