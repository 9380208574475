<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',
};
</script>
<style>
body {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  scroll-behavior: smooth;
  color: #0d1c2d;
}

.v-application--wrap {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-slide-group__next, .v-slide-group__prev {
  min-width: 24px !important;
}

.header {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 32px 60px 40px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.const_width {
  width: 100%;
  max-width: 1115px;
  padding: 0 10px;
  margin: 0 auto;
  display: flex;
}

.content {
  height: auto;
  min-height: calc(100vh - 132px);
}

.footer {
  background-color: #ffffff;
  padding: 20px 0;
}

.logo {
  width: 247px;
  height: 34px;
  margin: 15px 0;
}

.logo_container {
  width: fit-content;
  padding: 0;
}

.logo_container p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.auth_buttons_container {
  display: flex;
  padding: 14px 0;
}

.auth_button {
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  transition: .3s;
  cursor: pointer;
}

.user_button {
  height: 32px;
  line-height: 32px;
  padding: 0 10px 0 16px;
  background-color: transparent;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  transition: .3s;
  cursor: pointer;
}

.auth_button:hover, .user_button:hover {
  background-color: rgba(255, 255, 255, 0.27);
}

button:focus {
  outline: none;
}

.auth_button:first-child {
  margin-right: 10px;
}

.social_icons {
  margin-left: auto;
  padding: 6px 0;
  margin-right: 30px;
}

.social_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.social_icons a:not(:last-child) {
  margin-right: 5px;
}

.languages {
  padding: 6px 0;
}

.language_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.language_icon:not(:last-child) {
  margin-right: 10px;
}

.fv_title {
  margin-top: 15px;
  text-align: center;
  font-size: 1.5em;
  color: #ffffff;
  background-color: #00aa56;
  font-weight: 400;
}

.menu_tab {
  line-height: 40px;
  font-size: 13px;
  padding: 0 20px;
  height: 40px;
  color: #3e2d56;
  font-weight: bold;
  cursor: pointer;
  min-width: 80px;
  text-align: center;
}

.menu_tab:hover {
  background-color: #fafafa;
}

.menu_tab.active {
  border-bottom: 3px solid #41a775;
}

.content .const_width {
  min-height: calc(100vh - 132px);
}

.content_container {
  width: 100%;
  /*box-shadow: 0 2px 20px 0 rgba(36, 94, 113, .2);*/
  border: solid 1px #e0e6f0;
  border-radius: 4px;
  margin: 10px 0 30px 0;
  background-color: #ffffff;
}

.filter_bar {
  min-height: 50px;
  padding: 10px 0;
  display: flex;
}

.toggle_buttons {
  width: fit-content;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #3063b3;
  color: #3063b3;
  cursor: pointer;
  margin: 0 12px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.date-picker-buttons {
  width: fit-content;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #3063b3;
  color: #3063b3;
  cursor: pointer;
  margin: 0 12px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle_button {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  font-weight: bold;
  min-width: 90px;
  text-align: center;
}

.toggle_button_small {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  min-width: 28px;
  text-align: center;
}

.toggle_button_small:hover {
  background-color: #e0f1eb;
}

.toggle_button_small:first-child {
  border-right: 1px solid #3063b3;
}

.toggle_button_small:not(:first-child) {
  border-left: 1px solid #3063b3;
}

.toggle_button:hover {
  background-color: #f1f4f8;
}

.toggle_button.active {
  background-color: #3063b3;
  color: white;
}

.live {
  color: #cc0000 !important;
  font-weight: 800;
}

.cards {
  width: 24px;
  display: flex;
  padding-top: 5px;
  margin: 0 6px;
}

.card {
  width: 10px;
  height: 13px;
  line-height: 13px;
  text-align: center;
  font-size: 10px;
  margin: 0 auto;
  border-radius: 2px;
}

.card.red-card {
  background-color: #CA2027;
  color: white;
}

.card.yellow-card {
  background-color: #FFCC05;
  color: #000;
}

.date_picker {
  position: absolute;
  min-width: 90px;
  background-color: white;
  text-align: center;
  margin-left: 29px;
  margin-top: 28px;
  border: 1px solid #41a775;
  visibility: hidden;
}

.date_picker_container:hover .date_picker, .date_picker:hover {
  visibility: visible;
}

@media only screen and (min-width: 870px) {
  .content .const_width {
    display: flex;
  }

  .left_part {
    width: 770px;
    margin-right: 10px;
  }

  .right_part {
    width: 315px;
  }
}

@media only screen and (max-width: 870px) {
  .content .const_width {
    display: block;
  }

  .left_part {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
  }

  .left_part .content_container {
    margin-bottom: 0;
  }

  /*TODO*/
  .right_part {
    width: 100%;
  }
}

.tooltip {
  position: relative;
}

.tooltip span {
  visibility: hidden;
  padding: 3px 15px;
  background-color: #363636;
  border-radius: 4px;
  position: absolute;
  width: max-content;
  color: white;
  margin-top: 30px;
  z-index: 9;
}

.tooltip:hover span {
  visibility: visible;
}

.checkboxes {
  display: flex;
  padding: 3px 0;
  margin: 0 auto;
  font-size: 12px;
}

.checkbox {
  display: grid;
  grid-template-columns: 29px auto;
  margin-right: 15px;
  cursor: pointer;
}

.checkbox div:last-child {
  padding-top: 4px;
}

.mobile_menu_button {
  display: none !important;
}

.footer_title {
  color: #3e2d56;
  font-weight: bold;
  font-size: 12px;
}

.footer_flex_subtitle {
  display: flex;
  line-height: 20px;
  margin-bottom: 10px !important;
  color: #778aa4;
}

.footer_flex_subtitle img {
  margin-right: 10px;
}

.footer_logo {
  display: block;
  width: 200px;
  height: 30px;
  margin: 0 auto 15px auto;
}

.footer_text {
  color: #778aa4;
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px !important;
}

.footer_text.red_text {
  color: #ff5252;
  font-weight: bold;
}

.footer_app_store {
  width: 120px;
  height: 38px;
  background-size: cover;
  background-position: center center;
  background-image: url("assets/icons/app-store.svg");
  margin-bottom: 10px;
}

.footer_play_store {
  width: 120px;
  height: 38px;
  background-size: cover;
  background-position: center center;
  background-image: url("assets/icons/play-store.svg");
}

.v-application a {
  color: inherit !important;
  text-decoration: none;
}

@media only screen and (max-width: 650px) {
  .logo_container {
    max-width: 200px;
  }

  .logo {
    width: 200px;
    height: 21px;
    margin-top: 20px;
  }

  .logo_container p {
    display: none;
  }

  .mobile_menu_button {
    display: inline-block !important;
    margin-top: 12px;
  }



  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none !important;
  }

  .filter_bar {
    display: block;
  }

  .filter_bar .toggle_buttons {
    margin: 0 auto 10px auto;
  }

  .checkbox {
    grid-template-columns: auto;
    text-align: center;
    margin: 0 auto;
  }
}

.v-data-footer__select {
  display: none !important;
}

.v-tabs-slider-wrapper {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
}

.v-tabs-slider-wrapper {
  transition: none !important;
}

@media only screen and (max-width: 426px) {
  .header {
    grid-template-rows: none;
  }

  .auth_button {
    padding: 0 8px;
  }

  .toggle_buttons {
    border: none;
  }
}

@media only screen and (max-width: 376px) {
  .auth_button {
    padding: 0 5px;
  }

}

</style>
