<template>
  <div class="position-container">

    <div class="away-goal-section" v-if="selectedEvent.team === 'away'">
      <div class="away-goal-animation-container">
        <v-img src="@/assets/lmt/score.svg" class="match-situation-icon goal-img-reverse" contain/>
      </div>

      <div class="away-goal-container">
        <div class="font-size" >
          {{ matchTeam.teams[selectedEvent.team].name}}
        </div>
        <div>
          <span class="font-size2" >{{i18n.goal}}</span>
        </div>
      </div>
    </div>

    <div class="home-goal-section" v-if="selectedEvent.team === 'home'">
      <div class="home-goal-container">
        <div class="font-size" >
          {{ matchTeam.teams[selectedEvent.team].name}}
        </div>
        <div class="away-goal-text">
          <span class="font-size2" >{{i18n.goal}}</span>
        </div>
      </div>

      <div class="home-goal-animation-container">
        <v-img src="@/assets/lmt/score.svg" class="match-situation-icon" contain/>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Goal",
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    i18n: Object
  },
}
</script>

<style scoped>

.font-size {
  font-size: 18px;
  color: #fa8231;
}

.font-size2 {
  font-size: 18px;
  color: white;
}

.position-container {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  place-items: center;
}

/* Away */

.away-goal-section {
  display: flex;
  place-items: center;
  position: absolute;
}

.away-goal-container {
  align-self: center;
  width: auto;
  height: 4rem;
  padding: 3px;
  border-left: 5px solid red;
  place-items: center;
  flex-direction: column;
}

.away-goal-animation-container {
  align-self: center;
  display: grid;
  place-items: center;
  animation: reveal .4s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.match-situation-icon {
  width: 5rem;
  height: 4rem;
}

.goal-img-reverse {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* Home */

.home-goal-section {
  display: flex;
  place-items: center;
  position: absolute;
  right: 0;
}

.home-goal-container {
  align-self: center;
  text-align: right;
  width: auto;
  height: 4rem;
  padding: 3px;
  border-right: 5px solid red;
  place-items: center;
  flex-direction: column;
}

.home-goal-animation-container {
  align-self: center;
  display: grid;
  place-items: center;
  animation: home-goal-anim .4s forwards;
}

@keyframes home-goal-anim {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.match-situation-icon {
  width: 5rem;
  height: 4rem;
}

@media only screen and (max-width: 1264px) {
  .match-situation-icon {
    width: 3.8rem;
    height: 3.8rem;
  }

  .font-size {
    font-size: 16px;
  }

  .font-size2 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .match-situation-icon {
    width: 3.3rem;
    height: 3.3rem;
  }

  .font-size {
    font-size: 15px;
  }

  .font-size2 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 540px) {
  .match-situation-icon {
    width: 2.7rem;
    height: 2.7rem;
  }

  .font-size {
    font-size: 14px;
  }

  .font-size2 {
    font-size: 14px;
  }
}

</style>