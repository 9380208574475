<template>
  <div class="general-section">

    <!-- Ball Possession -->

    <div style="width: 80%">
      <div class="number-container">
        <span class="home-number">{{ballPossession.value.home}} <span class="percent-size">%</span></span>
        <span class="font-size" style="font-weight: bold">TOPA SAHİP OLMA</span>
        <span class="away-number">{{ballPossession.value.away}} <span class="percent-size">%</span></span>
      </div>

      <div class="home-percent">
        <div class="progress just-ball-possession" :style="{flex: ballPossession.value.home +'%'}">
        </div>
        <div class="away-percent just-ball-possession" :style="{flex: ballPossession.value.away +'%'}">
        </div>
      </div>
    </div>

    <!-- Dangerous Attack -->

    <div style="width: 80%">

      <div class="number-container">
        <span class="home-number">{{dangerousAttack.value.home}} <span class="percent-size">%</span></span>
        <span class="font-size" style="font-weight: normal">TEHLİKELİ ATAK</span>
        <span class="away-number">{{dangerousAttack.value.away}} <span class="percent-size">%</span></span>
      </div>

      <div class="home-percent">
        <div class="progress" :style="{flex: dangerousAttack.value.home +'%'}">

        </div>
        <div class="away-percent" :style="{flex: dangerousAttack.value.away +'%'}">
        </div>
      </div>

      <!-- Attack -->

      <div class="number-container">
        <span class="home-number">{{attack.value.home}} <span class="percent-size">%</span></span>
        <span class="font-size" style="font-weight: normal">HÜCUM</span>
        <span class="away-number">{{attack.value.away}} <span class="percent-size">%</span></span>
      </div>

      <div class="home-percent">
        <div class="progress" :style="{flex: attack.value.home +'%'}">

        </div>
        <div class="away-percent" :style="{flex: attack.value.away +'%'}">
        </div>
      </div>

      <!-- Ball Safe -->

      <div class="number-container">
        <span class="home-number">{{ballSafe.value.home}} <span class="percent-size">%</span></span>
        <span class="font-size" style="font-weight: normal">TOP GÜVENLİ YERDE</span>
        <span class="away-number">{{ballSafe.value.away}} <span class="percent-size">%</span></span>
      </div>

      <div class="home-percent">
        <div class="progress" :style="{flex: ballSafe.value.home +'%'}">

        </div>
        <div class="away-percent" :style="{flex: ballSafe.value.away +'%'}">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "PositionInfo",
  data() {
    return {
      homePercent: null,
      awayPercent: null
    }
  },
  props: {
    ballPossession: Object,
    dangerousAttack: Object,
    attack: Object,
    ballSafe: Object
  }
}
</script>

<style scoped>

.general-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  place-items: center;
  height: 100%;
}

.number-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.home-percent{
  display: flex;
  place-items: center;
  justify-content: space-between;
  width: 100%;
}

.percent-size {
  font-size: 12px;
}

.font-size {
  font-size: 12px;
}

.progress{
  display: flex;
  height: 2px;
  background: darkblue;
}

.away-percent {
  display: flex;
  height: 2px;
  background: red;
}

.home-number {
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 12px;
  color: darkblue;
}

.just-ball-possession {
  height: 4px;
}

.away-number {
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 12px;
  color: red;
}

@media only screen and (max-width: 1264px) {
  .percent-size {
    font-size: 12px;
  }

  .font-size {
    font-size: 14px;
  }

  .home-number {
    font-size: 14px;
  }

  .away-number {
    font-size: 14px;
  }
}

@media only screen and (max-width: 960px) {
  .percent-size {
    font-size: 15px;
  }

  .font-size {
    font-size: 13px;
  }

  .home-number {
    font-size: 13px;
  }

  .away-number {
    font-size: 13px;
  }
}

@media only screen and (max-width: 540px) {
  .percent-size {
    font-size: 13px;
  }

  .font-size {
    font-size: 11px;
  }

  .home-number {
    font-size: 9px;
  }

  .away-number {
    font-size: 9px;
  }
}

@media only screen and (max-width: 420px) {
  .percent-size {
    font-size: 12px;
  }

  .font-size {
    font-size: 10px;
  }

  .home-number {
    font-size: 12px;
  }

  .away-number {
    font-size: 12px;
  }
}

</style>