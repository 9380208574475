<template>
  <div v-if="timeline.length>0">
    <h2 class="fv_title">Zaman Çizelgesi</h2>
    <div v-if="!isMobile">
      <div class="timeline_header _header _accent--text">
        <div class="timeline_header_left">{{ teams.home }}</div>
        <div class="timeline_header_center"></div>
        <div class="timeline_header_right">{{ teams.away }}</div>
      </div>
      <template v-for="(e, ei) in timeline">
        <div v-if="e.eventType === 'Goal'" class="timeline_row" :key="ei">
          <div class="timeline_row_left">
            <template v-if="e.team === 'home'">
              {{ e.scorerName }}
              <img class="timeline_icon" src="@/assets/icons/ball.png" alt=""/>
            </template>
          </div>
          <div class="timeline_row_center">{{ formatMinute(e.matchClock) }}</div>
          <div class="timeline_row_right">
            <template v-if="e.team === 'away'">
              <img class="timeline_icon" src="@/assets/icons/ball.png" alt=""/>
              {{ e.scorerName }}
            </template>
          </div>
        </div>
        <div v-if="e.eventType === 'YellowCard'" class="timeline_row" :key="ei">
          <div class="timeline_row_left">
            <template v-if="e.team === 'home'">
              {{ e.playerName }}
              <img class="timeline_icon" src="@/assets/icons/yellow-card.png" alt=""/>
            </template>
          </div>
          <div class="timeline_row_center">{{ formatMinute(e.matchClock) }}</div>
          <div class="timeline_row_right">
            <template v-if="e.team === 'away'">
              <img class="timeline_icon" src="@/assets/icons/yellow-card.png" alt=""/>
              {{ e.playerName }}
            </template>
          </div>
        </div>
        <div v-if="e.eventType === 'RedCard'" class="timeline_row" :key="ei">
          <div class="timeline_row_left">
            <template v-if="e.team === 'home'">
              {{ e.playerName }}
              <img class="timeline_icon" src="@/assets/icons/red-card.png" alt=""/>
            </template>
          </div>
          <div class="timeline_row_center">{{ formatMinute(e.matchClock) }}</div>
          <div class="timeline_row_right">
            <template v-if="e.team === 'away'">
              <img class="timeline_icon" src="@/assets/icons/red-card.png" alt=""/>
              {{ e.playerName }}
            </template>
          </div>
        </div>
        <div v-if="e.eventType === 'YellowRedCard'" class="timeline_row" :key="ei">
          <div class="timeline_row_left">
            <template v-if="e.team === 'home'">
              {{ e.playerName }}
              <img class="timeline_icon" src="@/assets/icons/yellow-red-card.png" alt=""/>
            </template>
          </div>
          <div class="timeline_row_center">{{ formatMinute(e.matchClock) }}</div>
          <div class="timeline_row_right">
            <template v-if="e.team === 'away'">
              <img class="timeline_icon" src="@/assets/icons/yellow-red-card.png" alt=""/>
              {{ e.playerName }}
            </template>
          </div>
        </div>
        <div v-if="e.eventType === 'scoreChange'" class="timeline_row" :key="ei">
          <div class="timeline_row_left">
            <template v-if="e.team === 'home'">
              <div>
                <small>{{`${e.assistPlayerName !== null ? '(' + e.assistPlayerName + ')' : ''}`}}
                </small>{{ e.scorerName }}
              </div>
              <v-img class="timeline_icon" src="@/assets/icons/ball.png"/>
            </template>
          </div>
          <div class="timeline_row_center">{{ formatMinute(e.matchClock) }}</div>
          <div class="timeline_row_right">
            <template v-if="e.team === 'away'">
              <v-img class="timeline_icon" src="@/assets/icons/ball.png"/>
              <div>
                {{ e.scorerName }}
                <small>{{`${!!e.assistPlayerName ? '(' + e.assistPlayerName + ')' : ''}` }}
                </small>
              </div>
            </template>
          </div>
        </div>
        <div v-if="e.eventType === 'Substitution'" class="timeline_row" :key="ei">
          <div class="timeline_row_left_substitution">
            <template v-if="e.team === 'home'">
              {{ e.playerOffName }}
              <v-img class="timeline_icon" src="@/assets/icons/out.png"/>
              {{ e.playerOnName }}
              <v-img class="timeline_icon" src="@/assets/icons/in.png"/>
            </template>
          </div>
          <div class="timeline_row_center">{{ formatMinute(e.matchClock) }}</div>
          <div class="timeline_row_right_substitution">
            <template v-if="e.team === 'away'">
              <v-img class="timeline_icon" src="@/assets/icons/in.png"/>
              {{ e.playerOnName }}
              <v-img class="timeline_icon" src="@/assets/icons/out.png"/>
              {{ e.playerOffName }}
            </template>
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      <template v-for="(e, ei) in timeline">
        <div class="timeline_row_mobile" :key="`m-${ei}`">
          <div>
            {{ formatMinute(e.matchClock) }}
          </div>
          <div v-if="e.eventType === 'YellowCard'">
            <img class="timeline_icon" src="@/assets/icons/yellow-card.png" alt=""/>
            {{ e.playerName }}
          </div>
          <div v-if="e.eventType === 'RedCard'">
            <img class="timeline_icon" src="@/assets/icons/red-card.png" alt=""/>
            {{ e.playerName }}
          </div>
          <div v-if="e.eventType === 'YellowRedCard'">
            <img class="timeline_icon" src="@/assets/icons/yellow-red-card.png" alt=""/>
            {{ e.playerName }}
          </div>
          <div v-if="e.eventType === 'Goal'">
            <img class="timeline_icon" src="@/assets/icons/ball.png" alt=""/>
            <div>
              {{ e.scorerName }}
<!--              <small>{{
                  `${e.assistPlayerName !== null ?
                      '(' + e.assistPlayerName + ')' : ''}`
                }}
              </small>-->
            </div>
          </div>
          <div v-if="e.eventType === 'Substitution'">
            <img class="timeline_icon" src="@/assets/icons/in.png" alt=""/>
            {{ e.playerOnName }}
            <img class="timeline_icon" src="@/assets/icons/out.png" alt=""/>
            {{ e.playerOffName }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {fetchMatch} from "../../api";

export default {
  name: "Timeline",

  data() {
    return {
      timeline: [],
      poll: null,
      firstHalfEvents: [],
      secondHalfEvents: [],
      windowWidth: window.innerWidth,
    }
  },

  mounted() {
    window.addEventListener('resize', this.setPageWidth);
    this.fetchTimeline();
    this.poll = setInterval(() => {
      this.fetchTimeline();
    }, 60000);
  },

  methods: {
    async fetchTimeline() {
      const data = await fetchMatch(this.$route.params.id);
      if (data.data.data.eventPeriods && data.data.data.eventPeriods[0]) {
        this.firstHalfEvents =  data.data.data.eventPeriods[0].events
      }
      if (data.data.data.eventPeriods && data.data.data.eventPeriods[1]) {
        this.secondHalfEvents =  data.data.data.eventPeriods[1].events
      }
      const allEvents = [...this.firstHalfEvents, ...this.secondHalfEvents]
      this.timeline = allEvents.filter(item => {
        if (
            item.eventType === "Goal" ||
            item.eventType === "YellowCard" ||
            item.eventType === "Substitution" ||
            item.eventType === "RedCard" ||
            item.eventType === 'YellowRedCard'
        ) {
          return item;
        }
      });
      if (this.teams.isEnded) {
        clearInterval(this.poll);
      }
    },
    formatMinute(value) {
      if (value) {
        return `${value.split(":")[0]}'`;
      }
    },
    setPageWidth() {
      this.windowWidth = window.innerWidth;
    }
  },

  computed: {
    teams() {
      if (this.$parent.$parent.$data.match) {
        return {
          home: this.$parent.$parent.$data.response.homeTeam,
          away: this.$parent.$parent.$data.response.awayTeam,
          isEnded: this.$parent.$parent.$data.response.ended,
        }
      }
      return {home: '', away: ''};
    },

    isMobile() {
      return this.windowWidth < 650;
    }
  },

  beforeDestroy() {
    clearInterval(this.poll);
    window.removeEventListener('resize', this.setPageWidth);
  },
}
</script>

<style scoped>
.timeline_header {
  display: grid;
  grid-template-columns: calc(50% - 30px) 60px calc(50% - 30px);
  grid-template-rows: 23px;
  font-weight: bold;
  line-height: 23px;
  padding: 0 10px;
  color: #ffffff;
  background-color: #f3f6f7;
  border-bottom: 1px solid #edf2f4;
}

.timeline_row {
  display: grid;
  grid-template-columns: calc(50% - 30px) 60px calc(50% - 30px);
  grid-template-rows: 23px;
  line-height: 23px;
  padding: 0 10px;
  color: #3e2d56;
  border-bottom: 1px solid #edf2f4;
}

.timeline_header_left, .timeline_row_left {
  text-align: right;
}

.timeline_row_center {
  text-align: center;
  font-weight: bold;
}

.timeline_row_left {
  display: grid;
  grid-template-columns: auto 30px;
}

.timeline_row_right {
  display: grid;
  grid-template-columns: 30px auto;
}

.timeline_row_left_substitution {
  display: grid;
  margin-left: auto;
  grid-template-columns: max-content 30px max-content 30px;
}

.timeline_row_right_substitution {
  display: grid;
  grid-template-columns: 30px max-content 30px max-content;
}

.timeline_icon {
  display: block;
  width: 10px;
  height: 12px;
  margin: 5px auto 0 auto;
}

.timeline_row_mobile {
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: 23px;
  line-height: 23px;
  padding: 0 10px;
  border-bottom: 1px solid #edf2f4;
}

.timeline_row_mobile div:first-child {
  text-align: center;
  font-weight: bold;
}

.timeline_row_mobile div:not(:first-child) {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeline_row_mobile .timeline_icon {
  margin: 5px 5px 0 5px;
}
</style>
