<template>
  <div>
    <div class="live_scores_match">
      <div class="lsm_fav_icon">
        <template v-if="!item.ended || isFav()">
          <img v-if="isFav()" @click="setFav()" src="@/assets/icons/fav-on.svg" alt="fav"/>
          <img v-else @click="setFav()" src="@/assets/icons/fav-off.svg" alt="fav"/>
        </template>
      </div>
      <div class="lsm_status">
        <span v-if="item.ended">MS</span>
        <span class="live" v-if="item.live && item.halfTime">İY</span>
        <span class="live" v-if="item.live && !item.halfTime">{{ item.st }}'</span>
        <span v-if="item.status === 'Fixture' && item.status !== 'Postponed'">{{ startTime(item.dateTimeUtc) }}</span>
        <span v-if="item.status !== 'Fixture' && item.status === 'Postponed'">Ert.</span>
      </div>
      <div class="cards">
        <div v-if="item.hyellow > 0" class="card yellow-card">{{ item.hyellow }}</div>
        <div v-if="item.hred > 0" class="card red-card">{{ item.hred }}</div>
      </div>
      <div class="lsm_home_team moblie-view">
        <span @click="toTeamDetail(item.homeTeamId)">{{ item.homeTeam }}</span>
        <span class="mobile-app-cards">
          <span v-if="item.hyellow > 0" :class="item.hred > 0 ? 'mobil-app-card-margin' : ''" class="card yellow-card">{{ item.hyellow }}</span>
          <span v-if="item.hred > 0" class="card red-card">{{ item.hred }}</span>
        </span>
      </div>
      <div class="lsm_score" :class="{'live': item.live}">
        <router-link :to="toMatchDetail(item)" target="_blank">
          <span v-if="item.status !== 'Fixture' && item.score !== 'null - null'">{{ item.hscoreFT }} - {{ item.ascoreFT }}</span>
          <span v-else class="flex-hidden">v</span>
        </router-link>
      </div>
      <div class="lsm_away_team moblie-view">
        <span @click="toTeamDetail(item.awayTeamId)">{{ item.awayTeam }}</span>
        <span class="mobile-app-cards">
          <span v-if="item.ayellow > 0" :class="item.ared > 0 ? 'mobil-app-card-margin' : ''" class="card yellow-card">{{ item.ayellow }}</span>
          <span v-if="item.ared > 0" class="card red-card">{{ item.ared }}</span>
        </span>
      </div>
      <div class="cards">
        <div v-if="item.ared > 0" class="card red-card">{{ item.ared }}</div>
        <div v-if="item.ayellow > 0" class="card yellow-card">{{ item.ayellow }}</div>
      </div>
      <div class="lms_half_time">
        <span v-if="item.halfTime">{{ item.score1h }}</span>
      </div>
      <div class="lms_corner">
        <span>{{ item.hcorner }} - {{ item.acorner }}</span>
      </div>
      <div class="lms_options">
        <div>
          <div v-if="item.iddaaLive" class="lms_bet_icon_live"></div>
        </div>
        <div>
          <div v-if="item.iddaaId" class="lms_bet_icon"></div>
        </div>
        <div>
          <div v-if="item.lineups" class="lms_lineups"
               @click="toMatchLineups(item)"></div>
        </div>
        <div>
          <div v-if="item.tv" class="lms_tv tooltip">
            <span>{{ item.tv }}</span>
          </div>
        </div>
      </div>
      <div class="betting_odds">
        <div class="betting_odds_part">
          <span class="mbs-container" :class="mbsStyle(item.idddaaMbs)">{{ item.idddaaMbs }}</span>
        </div>
        <div class="betting_odds_part">
          <span class="c-pointer" @click="goToBilyoner(item.iddaaId)">{{ item.ms1 }}</span>
        </div>
        <div class="betting_odds_part">
          <span class="c-pointer" @click="goToBilyoner(item.iddaaId)">{{ item.msX }}</span>
        </div>
        <div class="betting_odds_part">
          <span class="c-pointer" @click="goToBilyoner(item.iddaaId)">{{ item.ms2 }}</span>
        </div>
        <div class="betting_odds_part">
          <span class="c-pointer" @click="goToBilyoner(item.iddaaId)">{{ item.oddsU25 }}</span>
        </div>
        <div class="betting_odds_part">
          <span class="c-pointer" @click="goToBilyoner(item.iddaaId)">{{ item.oddsO25 }}</span>
        </div>
        <div class="betting_odds_part">
          <span class="text-decoration-underline c-pointer" @click="showOdds = true">Diğer</span>
        </div>
      </div>

      <div class="display-none-betting-odds">
          <span class="c-pointer" @click="goToBilyoner(item.iddaaId)">{{ item.ms1 }}</span>
          <span class="c-pointer" @click="goToBilyoner(item.iddaaId)">{{ item.msX }}</span>
          <span class="c-pointer" @click="goToBilyoner(item.iddaaId)">{{ item.ms2 }}</span>
      </div>

    </div>
    <event-odds-dialog
        v-if="showOdds"
        :event="this.item"
        v-on:event-odds-dialog-closed="value => this.showOdds = value"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {mapActions} from 'vuex';
import EventOddsDialog from "@/components/live-scores/EventOddsDialog";

export default {
  name: "LiveScoresMatch",
  components: {EventOddsDialog},
  props: ['item'],
  data() {
    return {
      showOdds: false
    }
  },
  methods: {
    ...mapActions([
      'set_live_score_fav_status'
    ]),
    startTime(value) {
      return dayjs(value).format("HH:mm")
    },
    setFav() {
      this.set_live_score_fav_status(this.item);
    },
    isFav() {
      return this.$store.state.ls_fav_games.find(f => f.id === this.item.id);
    },

    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },

    toMatchDetail(match) {
      if (match.status !== 'Fixture') {
        return {name: 'MDInfo', params: {id: match.id}};
      } else {
        return {name: 'MDCompare', params: {id: match.id}};
      }
    },
    toMatchLineups(match) {
      const routeData = this.$router.resolve({name: 'MDLineups', params: {id: match.id}});
      window.open(routeData.href, '_blank');
    },
    mbsStyle(mbs) {
      switch (mbs) {
        case 1:
          return 'red-bg';
        case 2:
          return 'blue-bg';
        case 3:
          return 'green-bg';
        default:
          return '';
      }
    },
    goToBilyoner(iddaaId) {
      let path = `https://www.bilyoner.com/mac-karti/futbol/${iddaaId}?utm_source=futbolverileri&utm_medium=desktop`
      window.open(path, "_blank");
    }
  },
}
</script>

<style scoped>
.lsm_fav_icon {
  padding-top: 2px;
}

.lsm_fav_icon img {
  cursor: pointer;
}

.lsm_status {
  text-align: center;
}

.lsm_score {
  text-align: center;
  font-weight: 800;
}

.lsm_score span {
  cursor: pointer;
}

.lsm_home_team, .lsm_away_team {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lsm_home_team span, .lsm_away_team span {
  cursor: pointer;
}

.lsm_home_team .cards {
  margin-left: auto;
}

.lms_half_time, .lms_corner {
  text-align: center;
  color: #bbbbbb;
}

.lms_bet_icon {
  background-image: url("../../assets/icons/icon-iddaa-green.svg");
  background-position: 3px 4px;
  background-size: 16px;
}

.lms_bet_icon.live {
  background-image: url("../../assets/icons/icon-iddaa-red.svg");
}

.lms_bet_icon_live {
  background-position: 3px 4px;
  background-size: 16px;
  background-image: url("../../assets/icons/icon-iddaa-red.svg");
}

.lms_lineups {
  background-image: url("../../assets/icons/icon-lineups.svg");
  background-position: 3px 4px;
  background-size: 16px;
  cursor: pointer;
}

.lms_tv {
  background-image: url("../../assets/icons/icon-tv.svg");
  background-position: 3px 4px;
  background-size: 16px;
}

.betting_odds {
  display: grid;
  grid-template-columns: 25px repeat(5, 45px) 50px;
}

.betting_odds_part {
  text-align: center;
}

.mbs-container {
  display: block;
  width: 18px;
  height: 18px;
  line-height: 20px;
  margin: 2px;
  border-radius: 4px;
  color: white;
}

.mbs-container.green-bg {
  background-color: #18bb18;
}

.mbs-container.blue-bg {
  background-color: #00a7ff;
}

.mbs-container.red-bg {
  background-color: #f23232;
}

.c-pointer {
  cursor: pointer;
}

.mobile-app-cards {
  display: none;
}

@media only screen and (min-width: 800px) {
  .live_scores_match {
    display: grid;
    grid-template-columns: 30px 60px 36px minmax(184px, auto) 50px minmax(184px, auto) 36px 40px 40px 96px 300px;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
  }

  .lsm_home_team {
    text-align: right;
  }

  .lms_options {
    display: grid;
    grid-template-columns: 24px 24px 24px 24px;
  }

  .lms_options div {
    width: 24px;
    height: 23px;
  }

  .display-none-betting-odds, .lms_bet_icon_live, .mobile-app-cards {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .live_scores_match {
    display: grid;
    grid-template-columns: 24px 50px minmax(100px, auto) 40px 40px 30px;
    grid-template-rows: 23px 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-areas: "fav status hometeam score halftime" "fav status awayteam score halftime";
  }

  .lms_options {
    display: grid;
    grid-template-columns: 24px;
  }

  .lms_options div {
    width: 24px;
    height: 23px;
    padding-top: 12px;
  }

  .lsm_fav_icon, .lsm_status {
    padding-top: 12px;
  }

  .lsm_home_team {
    grid-area: hometeam
  }

  .lsm_away_team {
    grid-area: awayteam
  }

  .lsm_score {
    grid-area: score;
    padding-top: 12px;
  }

  .display-none-betting-odds {
    display: grid;
    place-items: center;
    grid-template-rows: repeat(3,15px);
  }

  .lms_tv, .betting_odds, .lms_lineups, .lms_corner, .lms_bet_icon, .cards, .lms_bet_icon_live, .betting_odds, .lms_half_time, .mobile-app-cards {
    display: none
  }
}

@media only screen and (max-width: 426px) {
  .live_scores_match {
    display: grid;
    grid-template-columns: 24px 50px minmax(100px, auto) 40px 40px 30px;
    grid-template-rows: 23px 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-areas: "fav status hometeam score halftime" "fav status awayteam score halftime";
  }

  .lms_options {
    display: grid;
    grid-template-columns: 24px;
  }

  .lms_options div {
    width: 24px;
    height: 23px;
    padding-top: 12px;
  }

  .lsm_fav_icon, .lsm_status {
    padding-top: 12px;
  }

  .lsm_home_team {
    grid-area: hometeam
  }

  .lsm_away_team {
    grid-area: awayteam
  }

  .lsm_score {
    grid-area: score;
    padding-top: 12px;
  }

  .lms_half_time {
    grid-area: halftime;
    padding-top: 12px
  }

  .lms_tv, .betting_odds, .lms_lineups, .lms_corner, .lms_bet_icon, .cards, .betting_odds {
    display: none
  }

  .display-none-betting-odds {
    display: flex;
    place-items: center;
    flex-direction: column;
  }

  .c-pointer {
    width: auto;
    height: 13px;
  }

  .mobile-app-cards {
    display: grid;
    grid-template-columns: repeat(2,15px);
    place-items: center;
  }

  .moblie-view {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}

@media only screen and (max-width: 376px) {
  .live_scores_match {
    display: grid;
    grid-template-columns: 24px 50px minmax(100px, auto) 40px 40px 30px;
    grid-template-rows: 23px 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-areas: "fav status hometeam score halftime" "fav status awayteam score halftime";
  }

  .lms_options {
    display: grid;
    grid-template-columns: 24px;
  }

  .lms_options div {
    width: 24px;
    height: 23px;
    padding-top: 12px;
  }

  .lsm_fav_icon, .lsm_status {
    padding-top: 12px;
  }

  .lsm_home_team {
    grid-area: hometeam
  }

  .lsm_away_team {
    grid-area: awayteam
  }

  .lsm_score {
    grid-area: score;
    padding-top: 12px;
  }

  .lms_half_time {
    grid-area: halftime;
    padding-top: 12px
  }

  .lms_tv, .betting_odds, .lms_lineups, .lms_corner, .lms_bet_icon, .cards, .betting_odds {
    display: none
  }

  .display-none-betting-odds {
    display: flex;
    place-items: center;
    flex-direction: column;
  }

  .c-pointer {
    width: auto;
    height: 13px;
  }

  .mobile-app-cards {
    display: grid;
    grid-template-columns: repeat(2,12px);
    place-items: center;
  }

  .moblie-view {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}

</style>
