<template>
  <div>
    <div class="live_scores_header _header">
      <div class="country-flag">
        <v-img class="league_logo clickable"
               :aspect-ratio="1"
               :lazy-src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/categories/${league.areaId}.png`"
               :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/categories/${league.areaId}.png`"
               alt=""
               @click="toLeagueDetail(league.areaId, league.seasonId, league.leagueId)"
        />
      </div>
      <div class="flexible-league-name">
                <span class="clickable" @click="toLeagueDetail(league.areaId, league.seasonId,league.leagueId)">
                {{ league.area }}: {{ league.matches[0].competitionName }}
                </span>
      </div>
      <div class="live_scores_header_stat">İY</div>
      <div class="live_scores_header_stat">K</div>
      <div class="empty-flex"></div>
      <div class="betting_odds">
        <div class="betting_odds_part empty-betting"></div>
        <div class="betting_odds_part">1</div>
        <div class="betting_odds_part">X</div>
        <div class="betting_odds_part">2</div>
        <div class="betting_odds_part"></div>
      </div>
    </div>
    <LiveScoresMatch v-for="item in league.matches" :key="item.id" :item="item"/>
  </div>
</template>

<script>
import LiveScoresMatch from "./LiveScoresMatch";

export default {
  name: "LiveScoresGrid",
  props: ['league'],
  components: {LiveScoresMatch},
  methods: {
    toLeagueDetail(ai, si, lid) {
      let routeData = this.$router.resolve({
        name: 'LeagueDetail', params: {
          categoryId: ai,
          seasonId: si,
          leagueId: lid,
        }
      });
      window.open(routeData.href, '_blank');
    },
  },
}
</script>

<style scoped>
.betting_odds {
  display: grid;
  grid-template-columns: 25px repeat(5, 45px) 50px;
}

.betting_odds_part {
  text-align: center;
}

.country-flag {
  display: grid;
  justify-content: flex-start;
  place-items: center;
}

.league_logo {
  width: 23px;
  height: 15px;
}

.clickable {
  cursor: pointer;
}

@media only screen and (min-width: 800px) {
  .live_scores_header {
    grid-template-columns: 29px auto 40px 40px 96px 300px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 800px) {
  .live_scores_header {
    grid-template-columns: 29px auto;
    line-height: 24px;
  }

  .betting_odds {
    display: none;
  }
}

@media only screen and (max-width: 426px) {
  .live_scores_header {
    grid-template-columns: 29px auto;
    line-height: 24px;
  }

  .country-flag {
    display: grid;
    justify-content: flex-start;
    place-items: center;
  }

  .league_logo {
    width: 23px;
    height: 15px;
  }

  .clickable {
    cursor: pointer;
  }

  .empty-flex {
    display: none;
  }

  .betting_odds {
    display: none;
  }
}

@media only screen and (max-width: 376px) {
  .live_scores_header {
    grid-template-columns: 29px auto;
    line-height: 24px;
  }

  .country-flag {
    display: grid;
    justify-content: flex-start;
    place-items: center;
  }

  .league_logo {
    width: 23px;
    height: 15px;
  }

  .clickable {
    cursor: pointer;
  }

  .empty-flex {
    display: none;
  }

  .betting_odds {
    display: none;
  }
}



</style>
