import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: {
                _header: '#dce0e7',
                _header_alt: '#ececec',
                _accent: '#3063b3',
                _dark_blue: '#0d1c2d',
                _accent_alt: '#f3dbdb',
                _c1: '#00aa56',
                _c11: '#00aa71',
                _c2: '#00944b',
            },
        },
    },
});
