<template>
  <div class="a">
    <div class="row">
      <div class="col-12">
        <div class="fv-lmt-wrapper">

          <!--BG NOISE-->
          <div class="fv-lmt-bg-noise"></div>

          <!--STRIPES-->
          <div class="fv-lmt-stripes"></div>

          <!--OVERLAY Z-1-->
          <div class="fv-lmt-overlay"></div>

          <!--CONTENT Z-2-->
          <div :class="isExpandedEvent() ? 'fv-lmt-content-wrapper-expanded' : 'fv-lmt-content-wrapper'">
            <position-indicators v-if="!!matchTeam && !!cards"
                                 :selected-event="selectedEvent" :matchTeam="matchTeam" :referee="referee"
                                 :manager="manager" :stadium="stadium" :corner="corner" :ballPossession="ballPossession"
                                 :dangerousAttack="dangerousAttack" :attack="attack" :ballSafe="ballSafe" :cards="cards"
            />
          </div>

          <!-- Time and Round -->
          <div class="time-container" v-if="!!matchTeam">
            <div class="time">
              <div class="round-container">
                <div class="round-p" v-if="matchTeam.status._id === 0 ">
                  {{ matchTeam.status.name }}
                </div>

                <div class="round-p" v-if="matchTeam.status._id === 6">
                  {{ matchTeam.p }}st
                </div>

                <div class="round-p" v-if="matchTeam.status._id === 41">
                  {{ matchTeam.status.name }}
                </div>

                <div class="round-p" v-else-if="matchTeam.status._id === 31 ">
                  {{ matchTeam.status.name }}
                </div>

                <div class="round-p" v-else-if="matchTeam.status._id === 80 ">
                  {{ matchTeam.status.name }}
                </div>

                <div class="round-p match-result" v-else-if="matchTeam.status._id === 100">
                  {{ matchTeam.status.name }}
                  <br>
                  <div>
                    {{ matchTeam.result.home }} : {{ matchTeam.result.away }}
                  </div>
                </div>

                <div class="round-p" v-else-if="matchTeam.status._id === 7">
                  {{ matchTeam.p }}nd
                </div>

                <div class="round-p" v-if="matchTeam.status._id === 42">
                  {{ matchTeam.status.name }}
                </div>

                <div class="round-p match-result" v-else-if="matchTeam.status._id === 32">
                  {{ matchTeam.status.name }}
                </div>

                <div class="round-p match-result" v-else-if="matchTeam.status._id === 120">
                  {{ matchTeam.status.name }}
                </div>

                <span class="vertical-line"
                      v-if="matchTeam.status._id !== 31 && matchTeam.status._id !== 100 && matchTeam.status._id !== 32 && matchTeam.status._id !== 0 && matchTeam.status._id !== 120">|</span>

                <div class="time-min-sec"
                     v-if="matchTeam.status._id !== 31 && matchTeam.status._id !== 100 && matchTeam.status._id !== 32 && matchTeam.status._id !== 0 && matchTeam.status._id !== 120">
                  {{ this.min }}:{{ this.sec }}
                </div>

                <div class="injury-time" v-if="matchTeam.timeinfo.injurytime !== null && matchTeam.status._id !== 31 && matchTeam.status._id !== 100 ">
                  <span>+</span> <span>{{ matchTeam.timeinfo.injurytime }}</span>
                </div>
              </div>

              <div v-if="matchTeam.status._id !== 100" class="score-container">
                <div>
                  {{ matchTeam.result.home }} - {{ matchTeam.result.away }}
                </div>
              </div>
            </div>
          </div>

          <!--LINES-->

<!--          <div id="asd">
            <div id="animation-background">
              <img style="width: 100%; height: 100%;" src="@/assets/images/pitch.jpg" alt="pitch">

            </div>
          </div>-->

          <div class="fv-lmt-pitch-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 568 352"
                 style="stroke: rgb(255, 255, 255); margin-bottom: -5px">
              <path vector-effect="non-scaling-stroke" stroke-width="3" fill="none"
                    d="M10,10h548v332H10V10z"></path>
              <path vector-effect="non-scaling-stroke" stroke-width="3" fill="none"
                    d="M83.5,211c12-7.2,20-20.2,20-35s-8-27.8-20-35 M10.5,87h73v178h-73 M557.5,87h-73v178h73 M484.5,211c-12-7.2-20-20.2-20-35s8-27.8,20-35"></path>
              <path vector-effect="non-scaling-stroke" stroke-width="3" fill="none"
                    d="M10.5,139h29v74h-29 M557.5,139h-29v74h29 M284,10v332"></path>
              <circle vector-effect="non-scaling-stroke" stroke-width="3" fill="none" cx="284" cy="176"
                      r="44"></circle>
              <path vector-effect="non-scaling-stroke" stroke-width="3" fill="none"
                    d="M10,156H4v40h6"></path>
              <path vector-effect="non-scaling-stroke" stroke-width="3" fill="none"
                    d="M558,156h6v40h-6"></path>
              <line vector-effect="non-scaling-stroke" stroke-width="7" fill="none" stroke-linecap="round"
                    x1="284"
                    y1="176" x2="284" y2="176"></line>
              <line vector-effect="non-scaling-stroke" stroke-width="5" fill="none" stroke-linecap="round"
                    x1="59"
                    y1="176" x2="59" y2="176"></line>
              <line vector-effect="non-scaling-stroke" stroke-width="5" fill="none" stroke-linecap="round"
                    x1="509"
                    y1="176" x2="509" y2="176"></line>
              <path vector-effect="non-scaling-stroke" stroke-width="3" fill="none"
                    d="M10,18c4.4,0,8-3.6,8-8 M18,342c0-4.4-3.6-8-8-8 M550,342c0-4.4,3.6-8,8-8 M558,18c-4.4,0-8-3.6-8-8"></path>
              <circle v-if="ballPosition.show && this.selectedEvent.type === 'matchsituation'" :cx="ballPosition.x"
                      :cy="ballPosition.y" r="0.5rem" stroke="white"
                      stroke-width="7" fill="black"/>
              <circle v-if="ballPosition.show && this.selectedEvent.type === 'throwin'" :cx="ballPosition.x"
                      :cy="ballPosition.y" r="0.5rem" stroke="white"
                      stroke-width="7" fill="white"/>
              <circle
                  v-if="ballPosition.show && this.selectedEvent.type !== 'throwin' && this.selectedEvent.type !== 'matchsituation'"
                  :cx="ballPosition.x" :cy="ballPosition.y" r="0.5rem" stroke="white"
                  stroke-width="7" fill="red"/>
              <path class="blink-animation" v-if="segmentPosition" :d="segmentPosition"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventManager from "@/components/fv-lmt-v2/utils/eventManager";
import * as dayjs from "dayjs";
import PositionIndicators from "@/components/fv-lmt-v2/components/PositionIndicators";
import {getPathD} from "../../../utils/segment_calculator";
import {fetchMatchDetail} from "../../../api";

export default {
  name: "FVLmtV2",
  components: {PositionIndicators},
  data() {
    return {
      min: 0,
      sec: 0,
      overlay: false,
      //match
      match: null,
      //matchTeam
      matchTeam: null,
      //match details
      matchStatus: null,
      matchTime: -1,
      matchInfo: null,
      //match events
      selectedEvent: null,
      events: [],
      allEvents: [],
      ballCoordinates: null,
      coordinate: null,
      //referee
      referee: null,
      //manager
      manager: null,
      //stadium
      stadium: null,
      // PositionInfo
      ballPossession: null,
      dangerousAttack: null,
      attack: null,
      ballSafe: null,
      //cornerCount
      corner: null,
      //Cards
      cards: null,
      //interval holder
      interval: null,
      //timeout default = 4000ms
      timeout: 1000,
      eventManager: new EventManager(),
      radarId: null
    }
  },
  props: ['matchId'],
  mounted() {
    const isOldUrl = `${this.matchId}`.length === 8
    if (isOldUrl) {
      this.radarId = this.matchId
      this.fetchFeedV1()
      this.fetchMatchInfo();
      this.fetchDetail();
    } else {
      this.fetchFeed();
    }
    setInterval(() => {
      this.setTime();
    }, 1000);
    setInterval(() => {
      this.ball();
    }, 2000);
    this.interval = setInterval(() => {
      if (isOldUrl) {
        this.fetchFeedV1()
      } else {
        this.fetchFeed();
      }
    }, this.timeout);
  },

  methods: {
    isExpandedEvent() {
      if (this.selectedEvent) {
        return ['corner', 'goal'].includes(this.selectedEvent.type)
      }
    },
    async fetchFeedV1() {
      const response = await axios.get(`https://lmt.fn.sportradar.com/common/en/Etc:UTC/gismo/match_timelinedelta/${this.matchId}`);
      console.log("response1", response)
      let match = response.data?.doc[0]?.data?.match;
      let allEvents = response.data?.doc[0]?.data?.events;
      this.eventManager.resolve(match, allEvents);
      this.matchTeam = match;
      this.matchStatus = this.eventManager.status;
      this.matchTime = this.eventManager.time;
      this.selectedEvent = this.eventManager.selectedEvent;
      this.events = this.eventManager.specialEvents;
      this.allEvents = response.data?.doc[0]?.data?.events;
      //this.matchStatus = getMatchStatus(response.data?.doc[0]?.data.match.status._id, response.data?.doc[0]?.data?.events, this.matchTime)
      //this.handleNewEventList(response.data?.doc[0]?.data?.events);
    },

    async fetchFeed() {
      const data = await fetchMatchDetail(this.matchId)
      console.log("detay", data)
      if (data.data.data) {
        this.radarId = data.data.data.radarId
      }
      const response = await axios.get(`https://lmt.fn.sportradar.com/common/en/Etc:UTC/gismo/match_timelinedelta/${this.radarId}`);
      console.log("response2", response)
      let match = response.data?.doc[0]?.data?.match;
      let allEvents = response.data?.doc[0]?.data?.events;
      this.eventManager.resolve(match, allEvents);
      this.matchTeam = match;
      this.matchStatus = this.eventManager.status;
      this.matchTime = this.eventManager.time;
      this.selectedEvent = this.eventManager.selectedEvent;
      this.events = this.eventManager.specialEvents;
      this.allEvents = response.data?.doc[0]?.data?.events;
      this.fetchMatchInfo();
      this.fetchDetail();
    },
    async fetchMatchInfo() {
      const response = await axios.get(`https://ls.fn.sportradar.com/common/en/Etc:UTC/gismo/match_info/${this.radarId}`);
      let info = response.data?.doc[0]?.data
      this.referee = info.referee;
      this.manager = info.manager;
      this.stadium = info.stadium;
    },
    async fetchDetail() {
      const response = await axios.get(`https://lmt.fn.sportradar.com/common/en/Etc:UTC/gismo/match_detailsextended/${this.radarId}`);
      let info = response.data?.doc[0]?.data;
      if (info.values) {
        this.corner = info.values[124];
        this.cards = info.values[40];
        this.ballPossession = info.values[110];
        this.dangerousAttack = info.values.dangerousattackpercentage;
        this.attack = info.values.attackpercentage;
        this.ballSafe = info.values.ballsafepercentage;
      }
    },

    setTime() {
      if (this.matchTeam) {
        let seconds = (new Date().getTime() / 1000) - this.matchTeam.ptime;
        if (this.matchTeam.status._id === 7) {
          const minute = 45 * 60
          seconds = minute + seconds
        }

        this.sec = this.pad(parseInt(seconds % 60));
        this.min = this.pad(parseInt(seconds / 60));
      }
    },

    pad(val) {
      let valString = val + "";
      if (valString.length < 2) {
        return "0" + valString;
      } else {
        return valString;
      }
    },

    ball() {
      if (this.allEvents) {
        let i = 0;
        for (i; i < this.allEvents.length; i++) {
          if (this.allEvents[i].type === 'ballcoordinates') {
            this.ballCoordinates = this.allEvents[i];
          }
        }
      }
      if (!!this.selectedEvent && ['freekick', 'offside', 'shotontarget'].includes(this.selectedEvent.type)) {
        this.coordinate = this.selectedEvent
      } else if (this.ballCoordinates) {
        this.coordinate = this.ballCoordinates.coordinates[0];
      }
    }
  },

  computed: {
    ballPosition() {
      if (this.selectedEvent && this.selectedEvent.type !== 'goal' && this.matchTeam.status._id !== 31 && this.matchTeam.status._id !== 100 && this.selectedEvent.type !== 'corner') {
        if (this.selectedEvent.type === 'goal_kick' && this.selectedEvent.team === 'home') {
          return {show: true, x: 30, y: 175}
        }
        if (this.selectedEvent.type === 'goal_kick' && this.selectedEvent.team === 'away') {
          return {show: true, x: 535, y: 175}
        }
        if (this.selectedEvent.type === 'corner' && this.selectedEvent.Y < 7) {
          return {show: true, x: this.selectedEvent.X * 5, y: 3}
        }
        if (this.selectedEvent.type === 'corner' && this.selectedEvent.Y > 85) {
          return {show: true, x: this.selectedEvent.X * 5, y: 345}
        }
        if (this.selectedEvent.type === 'throwin' && this.selectedEvent.Y <= 7) {
          return {show: true, x: this.selectedEvent.X * 5.5, y: 3}
        }
        if (this.selectedEvent.type === 'throwin' && this.selectedEvent.Y >= 93) {
          return {show: true, x: this.selectedEvent.X * 5.5, y: 345}
        }
        if (this.selectedEvent.X && this.selectedEvent.Y) {
          let fixedX;
          let fixedY;
          if (this.selectedEvent.X < 7) {
            fixedX = 5;
          } else if (this.selectedEvent.X > 93) {
            fixedX = 398;
          } else {
            fixedX = this.coordinate.X * 5.7;
          }
          if (this.selectedEvent.Y <= 8) {
            fixedY = 5;
          } else if (this.selectedEvent.Y > 91) {
            fixedY = 345;
          } else {
            fixedY = Math.floor(this.coordinate.Y * 3.5)
          }
          return {show: true, x: fixedX, y: fixedY};
        } else {
          return {show: false}
        }
      } else {
        return {show: false}
      }
    },

    segmentPosition() {
      if (this.selectedEvent && this.ballPosition.show && this.selectedEvent.type !== 'matchsituation' && this.selectedEvent.type !== 'offside' && this.selectedEvent.type !== 'injury' && this.selectedEvent.type !== 'shotontarget' && this.selectedEvent.type !== 'corner') {
        const basePosition = this.selectedEvent.team === 'home' ? {x: 500, y: 170} : {x: 0, y: 170};
        return getPathD(basePosition.x, basePosition.y, this.ballPosition.x, this.ballPosition.y, 60);
      } else {
        return null;
      }
    }
  },

  filters: {
    formatDateTime: function (value) {
      return dayjs.unix(value).format('YYYY-MM-DD - HH:mm:ss');
    },
    formatSeconds: function (value) {
      if (value === -1) return null;
      return `${(value / 60).toFixed(0)}:${(value % 60)}`
    }
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
}
</script>

<style scoped>

.fv-lmt-content-wrapper-expanded {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.a {
  width: 100%;
  height: auto;
}

.row {
  margin: 0;
  padding: 0;
}

.col-12 {
  margin: 0;
  padding: 0;
}

.circle-ripple {
  background-color: white;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(192, 192, 192, 0.3),
    0 0 0 1em rgba(192, 192, 192, 0.3),
    0 0 0 2em rgba(192, 192, 192, 0.3);
  }
  100% {
    box-shadow: 0 0 0 1em rgba(192, 192, 192, 0.3),
    0 0 0 2em rgba(192, 192, 192, 0.3),
    0 0 0 3em rgba(192, 192, 192, 0.3);
  }
}

.img {
  position: absolute;
}

.vertical-line {
  margin: 0 5px;
}

.time-min-sec {
  margin-top: auto;
  font-size: 13px;
}

.injury-time {
  color: red;
  margin-left: 3px
}

.blink-animation {
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    fill: #00000044;
  }
  25% {
    fill: #00000022;
  }
  50% {
    fill: #00000000;
  }
  75% {
    fill: #00000022;
  }
  100% {
    fill: #00000044;
  }
}

@-webkit-keyframes blink {
  0% {
    color: red;
  }
  100% {
    color: black;
  }
}

.header-container {
  display: flex;
  width: 568.5px;
  background-color: #e2e2e2;
}

.score-and-match-status {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header-teams-times-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: large;
}

.time-container {
  position: absolute;
  display: flex;
  justify-content: center;
  place-items: center;
  top: 3%;
  z-index: 2;
  width: 100%;
  height: auto;
}

.time {
  display: flex;
  place-items: center;
  padding: 0 0.5em;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  border-radius: 1px;
  font-size: 14px;
  min-width: 10%;
}

.round-container {
  display: flex;
  place-items: center;
}

.score-container {
  display: flex;
}

.round-p {
  font-size: 12px;
  font-weight: lighter;
}

.match-result {
  display: grid;
  place-items: center;
}

.fv-lmt-wrapper {
  position: relative;
  width: 100%;
  /*background-color: #5c8301;*/
  background-color: #459153;
  background-repeat: repeat;
}

.fv-lmt-bg-noise {
  background-image: url("../../../assets/lmt-v2/lmt-substructure.png");
  background-size: 40px 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-repeat: initial;
}

.fv-lmt-stripes {
  position: absolute;
  left: 6.7%;
  right: 1.8%;
  top: 3%;
  bottom: 3%;
  background: repeating-linear-gradient(
      to right,
      #4B9D5B,
      #4B9D5B 6.66%,
      transparent 6.66%,
      transparent 13.33%
  );
  /*background: repeating-linear-gradient(
      to right,
      hsla(0, 0%, 100%, .08),
      hsla(0, 0%, 100%, .08) 6.66%,
      transparent 6.66%,
      transparent 13.33%
  );*/
}

.fv-lmt-overlay {
  position: absolute;
  background: -webkit-radial-gradient(ellipse closest-corner at 50% 50%, transparent 0, rgba(0, 0, 0, .15) 100%);
  background: radial-gradient(ellipse closest-corner at 50% 50%, transparent 0, rgba(0, 0, 0, .15) 100%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.fv-lmt-content-wrapper {
  position: absolute;
  left: 1.8%;
  right: 1.8%;
  top: 3%;
  bottom: 3%;
  z-index: 2;
}

.fv-lmt-pitch-wrapper {
  width: 100%;
  height: 100%;
  opacity: .54;
}

@media only screen and (max-width: 420px) {
  .round-p {
    font-size: 10px;
    font-weight: lighter;
  }
}



</style>
