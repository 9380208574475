import Vue from 'vue';
import VueRouter from 'vue-router';
import LiveScores from "./components/live-scores/LiveScores";
import Compare from "./components/match-detail/Compare";
import MatchInfo from "./components/match-detail/MatchInfo";
import TeamMatches from "./components/team-detail/TeamMatches";
import Register from "./components/auth/Register";
import BaseLayout from "./components/layout/BaseLayout";
import Forum from "./components/match-detail/Forum";
import Lineups from "./components/match-detail/Lineups";
import Predictions from "@/components/predictions/Predictions";
import Tips from "./components/TipsterWho/Tips";
import CreateNewTip from "./components/TipsterWho/CreateNewTip";
import Fixture2 from "./components/team-detail/Fixture2";
import Tv from "./components/Tv/Tv";
import AddNews from "./components/News/AddNews";
import Privacy from "./components/Terms/Privacy";
import CookiePolicy from "./components/Terms/CookiePolicy";
import NewsView from "./components/News/NewsView";
import NewsList from "./components/News/NewsList";
import UpdateTeamDetail from "./components/UpdateTeamDetail/UpdateTeamDetail";

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
    if (localStorage.getItem('user-token')) {
        next({name: 'LiveScores'});
    } else {
        next();
    }
};

const ifNotAuthenticated = (to, from, next) => {
    if (localStorage.getItem('user-token')) {
        next();
    } else {
        next({name: 'LiveScores'});
    }
};

const routes = [
    /*BASE LAYOUT*/
    {
        name: 'Privacy',
        path: '/privacy',
        component: Privacy,
    },
    {
        name: 'cookie-policy',
        path: '/privacy/storage',
        component: CookiePolicy,
    },
    {
        path: '/',
        component: BaseLayout,
        children: [
            //LIVE SCORES
            {
                name: 'LiveScores',
                path: '',
                component: LiveScores,
            },
            //BET MODE
            {
                name: 'BetMode',
                path: 'bet-mode',
                component: () => import('@/components/bet-mode/BetMode'),
            },
            //BET ANALYZE
            {
                name: 'BetAnalyze',
                path: 'bet-analyze',
                component: () => import('@/components/bet-analyze/BetAnalyze'),
            },
            //BET RADAR
            {
                name: 'BetRadar',
                path: 'bet-radar',
                component: () => import('@/components/bet-radar/BetRadar'),
            },
            //PREDICTIONS
            {
                name: 'Predictions',
                path: 'predictions',
                component: Predictions,
            },
            {
                name: 'TipsterWho',
                path: 'tipsterWho',
                component: Tips,
            },
            {
                name: 'AddPrediction',
                path: 'addPrediction',
                component: CreateNewTip,
            },
            //MULTI MATCH TRACKING
            {
                name: 'MultiMatchTracking',
                path: 'multi-match-tracking',
                component: () => import('@/components/multi-match-tracking/MultiMatchTracking'),
            },
            //TV
            {
                name: 'Tv',
                path: 'tv',
                component: Tv,
            },
            //NEWS
            {
                name: 'News',
                path: 'news',
                component: NewsView,
            },

            //MATCH DETAIL
            {
                path: 'match-detail/:id',
                component: () => import('@/components/match-detail/MatchDetail'),
                children: [
                    {
                        name: 'MDInfo',
                        path: 'info',
                        component: MatchInfo,
                    },
                    {
                        name: 'MDCompare',
                        path: 'compare',
                        component: Compare,
                    },
                    {
                        name: 'MDForum',
                        path: 'forum',
                        component: Forum
                    },
                    {
                        name: 'MDLineups',
                        path: 'lineups',
                        component: Lineups,
                    },
                ]
            },
            //TEAM DETAIL
            {
                path: 'team-detail/:id',
                component: () => import('@/components/team-detail/TeamDetail'),
                children: [
                    {
                        name: 'TeamDetail',
                        path: '',
                        component: TeamMatches,
                    },
                    {
                        name: 'TeamFixture2',
                        path: 'fixture2',
                        component: Fixture2
                    },

                ]
            },
            //LEAGUE DETAIL
            {
                name: 'LeagueDetail',
                path: 'league-detail/:categoryId?/:leagueId?/:seasonId?/:tab?',
                component: () => import('@/components/league-detail/LeagueDetail'),
            },
            //REGISTER
            {
                name: 'Register',
                path: 'user/register',
                component: Register,
                beforeEnter: ifAuthenticated,
            },
            //FORGOT PASSWORD
            {
                name: 'ForgotPassword',
                path: 'user/forgotPassword',
                component: () => import('@/components/auth/ForgotPassword'),
            },
            //TERMS
            {
                name: 'Terms',
                path: 'terms',
                component: () => import('@/components/base-pages/Terms'),
            },
            //CONTACT
            {
                name: 'Contact',
                path: 'contact',
                component: () => import('@/components/base-pages/Contact'),
            },
            //ACCOUNT DETAIL
            {
                name: 'AccountDetail',
                path: 'account',
                component: () => import('@/components/account/AccountDetails'),
                beforeEnter: ifNotAuthenticated,
            },
            //PREMIUM INFO
            {
                name: 'Premium',
                path: 'premium',
                component: () => import('@/components/premium/PremiumInfo'),
            },
            //PREMIUM INFO
            {
                name: 'Payment',
                path: 'payment',
                component: () => import('@/components/payment/Payment'),
            },
            //NEWS
            {
                name: 'AddNews',
                path: '/admin/news/add',
                component: AddNews
            },
            //Update Team Detail
            {
                name: 'UpdateTeamDetail',
                path: '/admin/update-team-details',
                component: UpdateTeamDetail
            },
            {
                name: 'NewsList',
                path: 'news-list',
                component: NewsList
            },
            {
                name: 'NewsView',
                path: 'news/:id?',
                component: NewsView
            },
        ]
    },
    //LMT
    {
        name: 'lmt',
        path: '/lmt/:id',
        component: () => import('@/components/fv-lmt/FvLmt')
    },
    //LMT CA
    {
        name: 'lmt-ca',
        path: '/lmt/ca/:id',
        component: () => import('@/components/fv-lmt/FvLmt')
    },
    //FV LMT DEV TEST
    {
        name: 'fv-lmt',
        path: '/fv-lmt/:id',
        component: () => import('@/components/fv-lmt/FvLmt')
    },
    //FV LMT V2
    {
        name: 'fv-lmt-v3',
        path: '/fv-lmt-v3/:id',
        component: () => import('@/components/fv-lmt-v2/views/FVLmtV2Container')
    },
    //FV LMT Basketball
    {
        name: 'fv-lmt-basketball',
        path: '/fv-lmt-basketball/:id',
        component: () => import('@/components/fv-lmt-basketball/views/LMTBasketball')
    },
    //FV LMT Tennis
    {
        name: 'fv-lmt-tennis',
        path: '/fv-lmt-tennis/:id',
        component: () => import('@/components/fv-lmt-tennis/views/LmtTennis')
    },
];

export default new VueRouter({
    mode: 'history',
    routes: routes
});
