<template>
  <div>
    <!--h2h-->
    <h2 class="fv_title" v-if="h2h">Aralarındaki Maçlar</h2>
    <!--<v-skeleton-loader
        v-if="!h2h"
        class="w-100"
        type="table-heading@11"
    ></v-skeleton-loader>-->
    <template v-if="h2h">
      <div class="c_header _header _accent--text">
        <div class="ch_date dis-center">Tarih</div>
        <div class="ch_league dis-center">Lig</div>
        <div class="ch_home_team"></div>
        <div class="ch_score"></div>
        <div class="ch_away_team"></div>
        <div class="ch_half_time">İY</div>
        <div class="ch_corner">Korner</div>
        <div class="ch_under_over">A/Ü</div>
        <div class="ch_bts">KG</div>
      </div>
      <div class="c_match-for-web" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in h2h"
           :key="`h2h-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_league dis-center">{{ match.competitionName }}</div>
        <div class="cards">
          <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
          <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
        </div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div class="cards">
          <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
          <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
        </div>
        <div class="cm_half_time">
          <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY<br></span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
        </div>
        <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.hcorner }} - {{ match.acorner }}</div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>

      <div class="c_match-for-mobile" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in h2h"
           :key="`h2h-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div>
          <div class="cm_half_time">
            <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY </span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
          </div>
          <div class="cm_corner"><span class="only_mobile">K </span>{{ match.hcorner }} - {{ match.acorner }}</div>
        </div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>
    </template>


    <!-- TEAM1 -->
    <h2 class="fv_title" v-if="teamHome">{{teamHome[0].homeTeam}} Form Durumu</h2>
    <v-skeleton-loader
        v-if="!team1"
        class="w-100"
        type="table-heading@11"
    ></v-skeleton-loader>
    <template v-if="team1">
      <div class="c_header _header _accent--text">
        <div class="ch_date dis-center">Tarih</div>
        <div class="ch_league dis-center">Lig</div>
        <div class="ch_home_team"></div>
        <div class="ch_score"></div>
        <div class="ch_away_team"></div>
        <div class="ch_half_time">İY</div>
        <div class="ch_corner">Korner</div>
        <div class="ch_under_over">A/Ü</div>
        <div class="ch_bts">KG</div>
      </div>
      <div class="c_match-for-web" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in team1"
           :key="`team1-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_league dis-center">{{ match.competitionName }}</div>
        <div class="cards">
          <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
          <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
        </div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div class="cards">
          <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
          <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
        </div>
        <div class="cm_half_time">
          <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY<br></span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
        </div>
        <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.hcorner }} - {{ match.acorner }}</div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>

      <div class="c_match-for-mobile" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in team1"
           :key="`team1-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div>
          <div class="cm_half_time">
            <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY </span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
          </div>
          <div class="cm_corner"><span class="only_mobile">K </span>{{ match.hcorner }} - {{ match.acorner }}</div>
        </div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>
    </template>

    <!-- TEAM2 -->
    <h2 class="fv_title" v-if="teamAway">{{teamAway[0].awayTeam}} Form Durumu</h2>
    <v-skeleton-loader
        v-if="!team2"
        class="w-100"
        type="table-heading@11"
    ></v-skeleton-loader>
    <template v-if="team2">
      <div class="c_header _header _accent--text">
        <div class="ch_date dis-center">Tarih</div>
        <div class="ch_league dis-center">Lig</div>
        <div class="ch_home_team"></div>
        <div class="ch_score"></div>
        <div class="ch_away_team"></div>
        <div class="ch_half_time">İY</div>
        <div class="ch_corner">Korner</div>
        <div class="ch_under_over">A/Ü</div>
        <div class="ch_bts">KG</div>
      </div>
      <div class="c_match-for-web" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in team2"
           :key="`team2-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_league dis-center">{{ match.competitionName }}</div>
        <div class="cards">
          <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
          <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
        </div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div class="cards">
          <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
          <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
        </div>
        <div class="cm_half_time">
          <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY<br></span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
        </div>
        <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.hcorner }} - {{ match.acorner }}</div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>

      <div class="c_match-for-mobile" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in team2"
           :key="`team2-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div>
          <div class="cm_half_time">
            <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY </span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
          </div>
          <div class="cm_corner"><span class="only_mobile">K </span>{{ match.hcorner }} - {{ match.acorner }}</div>
        </div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>
    </template>

    <!-- TEAM HOME -->
    <h2 class="fv_title" v-if="teamHome">{{teamHome[0].homeTeam}} İç Saha</h2>
    <v-skeleton-loader
        v-if="!teamHome"
        class="w-100"
        type="table-heading@11"
    ></v-skeleton-loader>
    <template v-if="teamHome">
      <div class="c_header _header _accent--text">
        <div class="ch_date dis-center">Tarih</div>
        <div class="ch_league dis-center">Lig</div>
        <div class="ch_home_team"></div>
        <div class="ch_score"></div>
        <div class="ch_away_team"></div>
        <div class="ch_half_time">İY</div>
        <div class="ch_corner">Korner</div>
        <div class="ch_under_over">A/Ü</div>
        <div class="ch_bts">KG</div>
      </div>
      <div class="c_match-for-web" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in teamHome"
           :key="`teamHome-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_league dis-center">{{ match.competitionName }}</div>
        <div class="cards">
          <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
          <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
        </div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div class="cards">
          <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
          <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
        </div>
        <div class="cm_half_time">
          <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY<br></span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
        </div>
        <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.hcorner }} - {{ match.acorner }}</div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>

      <div class="c_match-for-mobile" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in teamHome"
           :key="`teamHome-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div>
          <div class="cm_half_time">
            <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY </span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
          </div>
          <div class="cm_corner"><span class="only_mobile">K </span>{{ match.hcorner }} - {{ match.acorner }}</div>
        </div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>
    </template>

    <!-- TEAM AWAY -->
    <h2 class="fv_title" v-if="teamAway">{{teamAway[0].awayTeam}} Dış Saha</h2>
    <v-skeleton-loader
        v-if="!teamAway"
        class="w-100"
        type="table-heading@11"
    ></v-skeleton-loader>
    <template v-if="teamAway">
      <div class="c_header _header _accent--text">
        <div class="ch_date dis-center">Tarih</div>
        <div class="ch_league dis-center">Lig</div>
        <div class="ch_home_team"></div>
        <div class="ch_score"></div>
        <div class="ch_away_team"></div>
        <div class="ch_half_time">İY</div>
        <div class="ch_corner">Korner</div>
        <div class="ch_under_over">A/Ü</div>
        <div class="ch_bts">KG</div>
      </div>
      <div class="c_match-for-web" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in teamAway"
           :key="`teamAway-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_league dis-center">{{ match.competitionName }}</div>
        <div class="cards">
          <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
          <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
        </div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div class="cards">
          <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
          <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
        </div>
        <div class="cm_half_time">
          <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY<br></span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
        </div>
        <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.hcorner }} - {{ match.acorner }}</div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>

      <div class="c_match-for-mobile" @mouseover="mouseOverMatch(match)" @mouseleave="mouseLeaveMatch(match)"
           v-for="match in teamAway"
           :key="`teamAway-${match.id}`">
        <div class="cm-date-for-web">{{ matchDateForWeb(match.dateTimeUtc) }}</div>
        <div class="cm-date-for-mobile">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
        <div class="cm_home_team">
          <span @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
        </div>
        <div class="cm_score">
          <router-link :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
            {{ match.hscoreFT }} - {{ match.ascoreFT }}
          </router-link>
        </div>
        <div class="cm_away_team">
          <span @click="toTeamDetail(match.awayTeamId)">{{ match.awayTeam }}</span>
        </div>
        <div>
          <div class="cm_half_time">
            <span v-if="match.halfTime || !match.halfTime"><span class="only_mobile">İY </span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
          </div>
          <div class="cm_corner"><span class="only_mobile">K </span>{{ match.hcorner }} - {{ match.acorner }}</div>
        </div>
        <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
        <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
      </div>
    </template>

  </div>
</template>

<script>
import {fetchH2h, fetchMatchesHead} from "../../api";
import dayjs from 'dayjs';

export default {
  name: "Compare",

  data() {
    return {
      h2h: null,
      team1: null,
      team2: null,
      teamHome: null,
      teamAway: null,
      homeSide: 0,
      awaySide: 0,
    }
  },

  mounted() {
    this.fetchMatchDetail();
    this.fetchData();
  },
  methods: {
    async fetchMatchDetail() {
      const response = await fetchMatchesHead(this.$route.params.id)
      this.response = response.data.data[0];
    },
    async fetchData() {
      const data = await fetchH2h(this.$route.params.id);
      let arr = [];
      for (let i =0; i<data.data.data.h2h.length; i++) {
        arr.push(data.data.data.h2h[i]);
        if (arr[i].type === 'H2H') {
          this.h2h = data.data.data.h2h[i].matches;
        }
        else if (arr[i].type === 'TEAM' && this.response.homeTeamId === data.data.data.h2h[i].teamId) {
          this.team1 = data.data.data.h2h[i].matches;
        }
        else if (arr[i].type === 'TEAM' && this.response.awayTeamId === data.data.data.h2h[i].teamId) {
          this.team2 = data.data.data.h2h[i].matches;
        }
        else if (arr[i].type === 'TEAM_HOME') {
          this.teamHome = data.data.data.h2h[i].matches;
        }
        else {
          this.teamAway = data.data.data.h2h[i].matches;
        }
      }
    },

    matchDateForWeb(value) {
      return dayjs(value).format("YYYY-MM-DD");
    },

    matchDateForMobile(value) {
      return dayjs(value).format("DD/MM/YY");
    },

    underOver(match) {
      return (match.hscoreFT + match.ascoreFT) > 2.5 ?
          {value: 'Ü', style: 'success--text'} :
          {value: 'A', style: 'error--text'}
    },

    bts(match) {
      return (match.hscoreFT > 0 && match.ascoreFT > 0) ?
          {value: 'Var', style: 'success--text'} :
          {value: 'Yok', style: 'error--text'}
    },

    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },

    mouseOverMatch(match) {
      let home = document.getElementById(`std-${match.homeTeamId}`);
      let away = document.getElementById(`std-${match.awayTeamId}`);
      if (home && away) {
        home.style.backgroundColor = '#e7e7e7';
        away.style.backgroundColor = '#e7e7e7';
      }
    },
    mouseLeaveMatch(match) {
      let home = document.getElementById(`std-${match.homeTeamId}`);
      let away = document.getElementById(`std-${match.awayTeamId}`);
      if (home && away) {
        home.style.backgroundColor = 'transparent';
        away.style.backgroundColor = 'transparent';
      }
    },

    matchStatus(match, id) {
      if (match.ended) {
        let winnerId = 0;
        if (match.hscoreTOT > match.ascoreTOT) {
          winnerId = match.homeTeamId;
        } else if (match.hscoreTOT === match.ascoreTOT) {
          winnerId = -1;
        } else {
          winnerId = match.awayTeamId;
        }

        if (winnerId === -1) {
          return {style: 'draw', text: 'B'}
        } else if (winnerId === id) {
          return {style: 'win', text: 'G'}
        } else {
          return {style: 'loss', text: 'M'}
        }
      } else {
        return {style: '', text: ''}
      }
    }
  },

  watch: {
    $route() {
      this.h2h = null;
      this.home = null;
      this.away = null;
      this.fetchData();
    },
  }
}
</script>

<style scoped>
.cm_score, .cm_half_time, .cm_bts, .cm_under_over, .ch_bts, .ch_under_over, .ch_corner, .cm_corner, .ch_half_time {
  text-align: center;
}

.cm_bts, .cm_under_over {
  font-weight: bold;
}

.cm-date-for-mobile {
  display: none;
}

.cm_score {
  font-weight: 800;
}

.cm_half_time, .cm_corner {
  color: #b2b2b2;
}

.cm_league, .cm_home_team, .cm_away_team {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cm_home_team span, .cm_away_team span {
  cursor: pointer;
}

.c_match-for-mobile {
  display: none;
}

.cm_match_status div {
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  padding-top: 1px;
  text-align: center;
  color: white;
  margin: 3px auto 0 auto;
}

.cm_match_status .win {
  background-color: #44ac45;
}

.cm_match_status .loss {
  background-color: #eb3f30;
}

.cm_match_status .draw {
  background-color: #ffb22f;
}

.sub_tab {
  text-transform: capitalize;
  letter-spacing: 0;
  min-width: 0;
  font-size: 12px;
  padding: 0 7px;
}

.filter_bar {
  padding-left: 10px;
  padding-right: 10px;
  min-height: 0;
}

.dis-center {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 650px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    background-color: #f3f6f7;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(80px, 100px) minmax(120px, 300px) 50px minmax(120px, 300px) 45px 45px 30px 30px 30px;
  }

  .c_match-for-web {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(80px, 100px) 36px minmax(86px, 300px) 50px minmax(86px, 300px) 36px 45px 45px 30px 30px 30px;
  }

  .cm_home_team {
    text-align: right;
  }

  .cm_home_team .cards {
    margin-left: auto;
  }

  .cm_half_time .only_mobile, .cm_corner .only_mobile {
    display: none;
  }

}

@media only screen and (max-width: 650px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px 30px;
    grid-template-areas: "date teams score halftime underover bts";
  }

  .c_match-for-web {
    display: none;
  }

  .c_match-for-mobile {
    display: grid;
    line-height: 23px;
    place-items: center;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(50px, 120px) 40px minmax(50px, 120px) auto 30px 30px;
    grid-template-areas: "date hometeam score awayteam halftime underover bts";
  }

  .ch_date {
    grid-area: date
  }

  .ch_score {
    grid-area: score
  }

  .ch_half_time {
    grid-area: halftime
  }

  .ch_under_over {
    grid-area: underover
  }

  .ch_bts {
    grid-area: bts
  }

  .ch_league {
    display: none
  }

  .ch_away_team {
    display: none
  }

  .ch_home_team {
    grid-area: teams
  }

  .ch_half_time, .ch_corner {
    display: none;
  }

  .cm_league {
    display: none;
  }

  .cm-date-for-web {
    padding-top: 12px;
    grid-area: date;
  }

  .cm_home_team {
    grid-area: hometeam;
  }

  .cm_away_team {
    grid-area: awayteam;
  }

  .cm_score {
    padding-top: 12px;
    grid-area: score;
  }

  .cm_half_time {
    line-height: 12px;
    font-size: 10px;
    grid-area: halftime;
  }

  .cm_corner {
    line-height: 12px;
    font-size: 10px;
    grid-area: corner;
  }

  .cm_under_over {
    padding-top: 12px;
    grid-area: underover;
  }

  .cm_bts {
    padding-top: 12px;
    grid-area: bts;
  }

  .cards {
    display: none;
  }

  .cm-date-for-web {
    display: none;
  }

  .cm-date-for-mobile {
    display: grid;
  }

  .cm_score {
    padding-top: 0;
    grid-area: score;
  }

  .cm_home_team {
    grid-area: hometeam;
    width: 100%;
    text-align: right;
  }

  .cm_away_team {
    grid-area: awayteam;
    width: 100%;
    text-align: left;
  }

  .cm_bts {
    padding-top: 0;
    grid-area: bts;
  }

  .cm_under_over {
    padding-top: 0;
    grid-area: underover;
  }

  .cm_half_time {
    margin: 3px 0;
  }

  .cm_corner {
    margin: 3px 0;
  }
}

@media only screen and (max-width: 426px) {
  .c_match-for-web {
    display: none;
  }

  .c_match-for-mobile {
    display: grid;
    line-height: 23px;
    place-items: center;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(50px, 100px) 40px minmax(50px, 100px) 40px 30px 30px;
    grid-template-areas: "date hometeam score awayteam halftime underover bts";
  }

  .cm-date-for-web {
    display: none;
  }

  .cm-date-for-mobile {
    display: grid;
  }

  .cm_score {
    padding-top: 0;
    grid-area: score;
  }

  .cm_home_team {
    grid-area: hometeam;
    width: 100%;
    text-align: right;
  }

  .cm_away_team {
    grid-area: awayteam;
    width: 100%;
    text-align: left;
  }

  .cm_bts {
    padding-top: 0;
    grid-area: bts;
  }

  .cm_under_over {
    padding-top: 0;
    grid-area: underover;
  }

  .cm_half_time {
    margin: 3px 0;
  }

  .cm_corner {
    margin: 3px 0;
  }
}

</style>
