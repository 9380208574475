<template>
  <div class="position-container">
    <div class="injury-time-container">
      <div class="injury-time">
        <span>{{i18n.injuryTime}}</span>
        <span>{{matchTeam.timeinfo.injurytime}}'</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InjuryTime",
  props: {
    matchTeam: Object,
    i18n: Object
  },
}
</script>

<style scoped>

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.injury-time-container {
  position: absolute;
  display: flex;
  justify-content: center;
  place-items: center;
  top: 30px;
  z-index: 2;
  width: 100%;
  height: auto;
  animation: cssAnimation 0s ease-in 5s forwards, 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.injury-time {
  display: flex;
  place-items: center;
  padding: 0 0.5em;
  justify-content: space-around;
  background-color: white;
  border-radius: 1px;
  font-size: 14px;
  min-width: 15%;
  margin-top: 1rem;
  margin-bottom: 10px;
}

@keyframes cssAnimation {
  to {
    width:0;
    height:0;
    top: 0;
    overflow:hidden;
    padding: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

</style>