<template>
  <div>
    <PopularLeagues/>
    <div class="const_width">
      <div class="live_scores_wrapper">
        <div class="live_scores_top_section">
          <div class="banner">
            <BannerArea/>
          </div>
          <div class="google-banner">
            <amp-ad width="100vw" height="320"
                    type="adsense"
                    data-ad-client="ca-pub-1444302528223709"
                    data-ad-slot="4281693386"
                    data-auto-format="rspv"
                    data-full-width="">
              <div overflow=""></div>
            </amp-ad>
          </div>
          <div class="mobile_apps_card">
            <mobile-apps-card/>
          </div>
        </div>
        <div class="content_container">
          <div class="filter_bar">
            <!-- LANGUAGE TR -->
            <div class="toggle_buttons" v-if="this.tr === getMenuItems()">
              <div class="toggle_button" :class="{'active': sortBy ===0}" @click="sortBy = 0">Lige Göre
              </div>
              <div class="toggle_button" :class="{'active': sortBy ===1}" @click="sortBy = 1">Tarihe Göre
              </div>
              <div></div>
            </div>
            <!-- LANGUAGE EN -->
            <div class="toggle_buttons" v-if="this.en === getMenuItems()">
              <div class="toggle_button" :class="{'active': sortBy ===0}" @click="sortBy = 0">By League
              </div>
              <div class="toggle_button" :class="{'active': sortBy ===1}" @click="sortBy = 1">By Date
              </div>
              <div></div>
            </div>
            <div class="checkboxes" v-if="this.tr === getMenuItems()">
              <div class="checkbox" @click="live = !live">
                <div>
                  <v-icon v-if="live" color="#3063b3">check_circle</v-icon>
                  <v-icon v-else color="#778aa4">radio_button_unchecked</v-icon>
                </div>
                <div>Canlı</div>
              </div>
              <div class="checkbox" @click="onlyFav = !onlyFav">
                <div>
                  <v-icon v-if="onlyFav" color="#3063b3">check_circle</v-icon>
                  <v-icon v-else color="#778aa4">radio_button_unchecked</v-icon>
                </div>
                <div>Favoriler</div>
              </div>
              <div class="checkbox" @click="iddaa = !iddaa">
                <div>
                  <v-icon v-if="iddaa" color="#3063b3">check_circle</v-icon>
                  <v-icon v-else color="#778aa4">radio_button_unchecked</v-icon>
                </div>
                <div>İddaa</div>
              </div>
              <div class="checkbox" @click="iddaaLive = !iddaaLive">
                <div>
                  <v-icon v-if="iddaaLive" color="#3063b3">check_circle</v-icon>
                  <v-icon v-else color="#778aa4">radio_button_unchecked</v-icon>
                </div>
                <div>Canlı İddaa</div>
              </div>
            </div>
            <div class="checkboxes" v-if="this.en === getMenuItems()">
              <div class="checkbox" @click="live = !live">
                <div>
                  <v-icon v-if="live" color="#3063b3">check_circle</v-icon>
                  <v-icon v-else color="#778aa4">radio_button_unchecked</v-icon>
                </div>
                <div>Live</div>
              </div>
              <div class="checkbox" @click="onlyFav = !onlyFav">
                <div>
                  <v-icon v-if="onlyFav" color="#3063b3">check_circle</v-icon>
                  <v-icon v-else color="#778aa4">radio_button_unchecked</v-icon>
                </div>
                <div>Favorites</div>
              </div>
              <div class="checkbox" @click="iddaa = !iddaa">
                <div>
                  <v-icon v-if="iddaa" color="#3063b3">check_circle</v-icon>
                  <v-icon v-else color="#778aa4">radio_button_unchecked</v-icon>
                </div>
                <div>Bet</div>
              </div>
              <div class="checkbox" @click="iddaaLive = !iddaaLive">
                <div>
                  <v-icon v-if="iddaaLive" color="#3063b3">check_circle</v-icon>
                  <v-icon v-else color="#778aa4">radio_button_unchecked</v-icon>
                </div>
                <div>Live Bet</div>
              </div>
            </div>
            <div class="live-match-for-mobile">
              <div class="live-container" @click="live = !live">
                <div v-if="live" class="live-match" >
                  <span class="live-text">Live</span>
                  <img class="live-icon" src="@/assets/icons/live-icon.png" alt="">
                </div>
                <div class="not-live" style="text-align: right" v-else>
                  <img class="live-icon" src="@/assets/icons/live-icon.png" alt="">
                  <span class="not-live-text">Live</span>
                </div>
              </div>
            </div>

            <div class="week-day-section-for-mobile">
              <div class="week-days-container" v-for="(item,i) in this.weeksForMobile" :key="i" @click="weekDate(item.day)">
            <span class="date" :class="currentDate === item.day ? 'selected-date' : ''" style="">
              <span>{{ format(item.day) }}</span>
              <span>{{ format2(item.day) }}</span>
            </span>
              </div>
            </div>

            <div class=" date-picker-buttons mobile-view">
              <div class="toggle_button_small" @click="previousDay()">&lt;</div>
              <v-menu
                  ref="menu"
                  v-model="datePickerModel"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="toggle_button date_picker_container"
                       v-text="formatPickersDate()"/>
                </template>
                <v-date-picker
                    v-model="currentDate"
                    no-title
                    scrollable
                    locale="tr"
                />
              </v-menu>
              <div class="date_picker"></div>
              <div class="toggle_button_small" @click="nextDay()">&gt;</div>
            </div>
          </div>
          <v-skeleton-loader
              v-if="loading"
              class="w-100"
              type="table-heading@30"
          ></v-skeleton-loader>
          <template v-if="ls_fav_games.length > 0">
            <div class="live_scores_header _header">
              <div>Favoriler</div>
              <div></div>
            </div>
            <LiveScoresMatch v-for="item in ls_fav_games" :key="`fav-${item.id}`" :item="item"/>
          </template>
          <template v-if="!onlyFav">

            <template v-if="matchList && sortBy === 0">
              <LiveScoresGrid v-for="league in matchList" :key="league.id" :league="league"/>
            </template>

            <template v-if="matchList && sortBy === 1">
              <div class="live_scores_header _header">
                <div>{{ formatPickersDate() }}</div>
                <div class="live_scores_header_stat">İY</div>
                <div class="live_scores_header_stat">K</div>
                <div></div>
                <div class="betting_odds">
                  <div class="betting_odds_part"></div>
                  <div class="betting_odds_part">1</div>
                  <div class="betting_odds_part">X</div>
                  <div class="betting_odds_part">2</div>
                  <div class="betting_odds_part"></div>
                </div>
              </div>
              <live-scores-match-by-time v-for="item in matchList" :key="item.id" :item="item"/>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LiveScoresGrid from "./LiveScoresGrid";
import { fetchByLeague, fetchByTime} from "../../api";
import dayjs from 'dayjs';
import LiveScoresMatch from "./LiveScoresMatch";
import {mapGetters, mapActions} from 'vuex';
import PopularLeagues from "./PopularLeagues";
import BannerArea from "@/components/live-scores/BannerArea";
import MobileAppsCard from "@/components/layout/MobileAppsCard";
import LiveScoresMatchByTime from "./LiveScoresMatchByTime";

export default {
  name: "LiveScores",
  components: {MobileAppsCard, BannerArea, PopularLeagues, LiveScoresMatch, LiveScoresGrid,LiveScoresMatchByTime},
  data: () => ({
    tr: false,
    en: false,
    sortBy: 0,
    leagues: [],
    dataForDate: [],
    loading: true,
    currentDate: new Date().toISOString().substr(0, 10),
    selectedDate: null,
    poll: null,
    datePickerModel: false,
    live: false,
    iddaa: false,
    iddaaLive: false,
    onlyFav: false,
    weeks:[
      {day: new Date().toISOString().substr(0, 10),},
      {day: dayjs(new Date()).add(1, 'days').format('YYYY-MM-DD')},
      {day: dayjs(new Date()).add(2, 'days').format('YYYY-MM-DD')},
      {day: dayjs(new Date()).add(3, 'days').format('YYYY-MM-DD')},
      {day: dayjs(new Date()).add(4, 'days').format('YYYY-MM-DD')},
      {day: dayjs(new Date()).add(5, 'days').format('YYYY-MM-DD')},
      {day: dayjs(new Date()).add(6, 'days').format('YYYY-MM-DD')},
    ],
    weeksForMobile:[
      {day: new Date().toISOString().substr(0, 10),},
      {day: dayjs(new Date()).add(1, 'days').format('YYYY-MM-DD')},
      {day: dayjs(new Date()).add(2, 'days').format('YYYY-MM-DD')},
      {day: dayjs(new Date()).add(3, 'days').format('YYYY-MM-DD')},
      {day: dayjs(new Date()).add(4, 'days').format('YYYY-MM-DD')},
    ]

  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    getMenuItems() {
      if (localStorage.getItem('language') === 'tr') {
        return this.tr = true;
      } else {
        return this.en = true;
      }
    },
    ...mapActions([
      'set_fav_games'
    ]),

    fetchData() {
      if (this.sortBy === 0) {
        this.fetchByLeague();
        this.poll = setInterval(() => {

          this.fetchByLeague();
        }, 5000);
      } else {
        this.fetchByDate();
        this.poll = setInterval(() => {
          this.fetchByDate();
        }, 5000);
      }
    },

    async fetchByLeague() {
      //const data = await api.get(`api/fvscore/matches/byLeague?date=${dayjs(this.currentDate).format("DD.MM.YYYY")}`);
      const data = await fetchByLeague(this.currentDate);
      this.leagues = data.data.data;
      this.loading = false;
      this.updateFavGames();
    },

    async fetchByDate() {
      //const data = await api.get("api/fvscore/matches/byTime?date=" + dayjs(this.currentDate).format("DD.MM.YYYY"));
      const data = await fetchByTime(this.currentDate);
      this.dataForDate = data.data.data;
      this.loading = false;
      this.updateFavGames();
    },

    updateFavGames() {
      let favs = this.$store.state.ls_fav_games;
      let newFavs = [];
      if (favs.length > 0) {
        if (this.sortBy === 0) {
          favs.forEach(fav => {
            this.leagues.map(league => {
              league.matches.forEach(match => {
                if (fav.id === match.id) newFavs.push(match);
              })
            })
          });
        } else {
          favs.forEach(fav => {
            this.dataForDate.map(match => {
              if (fav.id === match.id) newFavs.push(match);
            })
          })
        }
      }
      if (newFavs.length === favs.length) {
        this.set_fav_games(newFavs);
      }
    },

    nextDay() {
      this.currentDate = dayjs(this.currentDate).add(1, 'days').format('YYYY-MM-DD');
    },

    previousDay() {
      this.currentDate = dayjs(this.currentDate).subtract(1, 'days').format('YYYY-MM-DD');
    },

      formatPickersDate() {
      return dayjs(this.currentDate).format("YYYY-MM-DD")
    },
    weekDate(day) {
      return this.weeks.map(days => {
        if (days.day === day) {
          this.currentDate = day;
          return 'asd'
        }
      })
    },
    format(day) {
      return dayjs(day).format('ddd')
    },
    format2(day) {
      return dayjs(day).format('DD MMM')
    },

  },

  computed: {
    ...mapGetters([
      'ls_fav_games'
    ]),
    matchList() {
      let fData = [];
      if (this.sortBy === 0) {
        fData = this.leagues;
        if (this.live) {
          let leagues = [];
          fData.map(league => {
            let matches = league.matches.filter(item => !!item.live);
            if (matches.length > 0) {
              league.matches = matches;
              leagues.push(league);
            }
          });
          fData = leagues;
        }
        if (this.iddaa) {
          let leagues = [];
          fData.map(league => {
            let matches = league.matches.filter(item => !!item.iddaaId);
            if (matches.length > 0) {
              league.matches = matches;
              leagues.push(league);
            }
          });
          fData = leagues;
        }
        if (this.iddaaLive) {
          let leagues = [];
          fData.map(league => {
            let matches = league.matches.filter(item => !!item.iddaaLive);
            if (matches.length > 0) {
              league.matches = matches;
              leagues.push(league);
            }
          });
          fData = leagues;
        }
      } else {
        if (this.dataForDate) {
          let filteredMatches = this.dataForDate;
          if (this.live) {
            filteredMatches = filteredMatches.filter(item => item.live === true);
          }
          if (this.iddaa) {
            filteredMatches = filteredMatches.filter(item => item.iddaaId);
          }
          if (this.iddaaLive) {
            filteredMatches = filteredMatches.filter(item => item.iddaaLive);
          }
          fData = filteredMatches;
        }
      }
      return fData;
    },
  },

  watch: {
    currentDate() {
      this.loading = true;
      this.leagues = [];
      this.dataForDate = [];
      clearInterval(this.poll);
      this.fetchData();
    },

    sortBy() {
      this.loading = true;
      this.leagues = [];
      this.dataForDate = [];
      clearInterval(this.poll);
      this.fetchData();
    },

    live() {
      this.loading = true;
      this.leagues = [];
      this.dataForDate = [];
      clearInterval(this.poll);
      this.fetchData();
    },

    iddaa() {
      this.loading = true;
      this.leagues = [];
      this.dataForDate = [];
      clearInterval(this.poll);
      this.fetchData();
    },

    iddaaLive() {
      this.loading = true;
      this.leagues = [];
      this.dataForDate = [];
      clearInterval(this.poll);
      this.fetchData();
    },
  },

  beforeDestroy() {
    clearInterval(this.poll);
  },
}
</script>

<style>
.betting_odds {
  display: grid;
  grid-template-columns: 25px repeat(5, 45px) 50px;
}

.betting_odds_part {
  text-align: center;
}

.live_scores_wrapper {
  width: 100%;
}

.live-match-for-mobile {
  display: none;
}

.live_scores_top_section {
  display: flex;
  height: auto;
}

.live_scores_top_section .google-banner {
  display: none;
}

.v-skeleton-loader__table-heading {
  padding: 0 !important;
  height: 24px;
  border-bottom: 1px solid #e9e9e9;
}

.v-skeleton-loader__table-heading:first-child {
  padding: 0 !important;
  height: 26px;
  background-color: #f3f6f7 !important;
  border-bottom: 1px solid #edf2f4;
  border-radius: 0;
}

.v-skeleton-loader__table-heading:first-child .v-skeleton-loader__heading {
  margin-left: 10px !important;
  width: 200px;
}

.v-skeleton-loader__table-heading:not(:first-child) .v-skeleton-loader__heading {
  display: none !important;
}

.v-skeleton-loader__table-heading:first-child .v-skeleton-loader__text {
  height: 0 !important;
}

.v-skeleton-loader__text {
  margin: 6px 250px 0 200px;
  max-width: 100% !important;
}

.v-skeleton-loader__table-heading .v-skeleton-loader__heading {
  margin-left: 18px;
  width: 28px;
  height: 12px;
}

.live_scores_header_stat {
  text-align: center;
}

.bilyoner_container {
  display: inline-block;
  overflow: hidden;
  border-radius: 4px;
}

.week-day-section-for-mobile {
  display: none;
}

@media only screen and (min-width: 800px) {
  .live_scores_header {
    grid-template-columns: auto 40px 40px 96px 300px;
    line-height: 24px;
    display: grid;
    grid-template-rows: 23px;
    padding: 0 10px;
    color: #3063b3;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
  }

  .live_scores_top_section .banner {
    width: calc(100% - 310px);
    height: auto;
  }

  .live_scores_top_section .mobile_apps_card {
    width: 300px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .live_scores_header {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #3063b3;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
  }

  .live_scores_header_stat {
    display: none;
  }

  .live_scores_top_section .banner {
    width: 100%;
  }

  .live_scores_top_section .mobile_apps_card {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .live_scores_header {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #3063b3;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
  }

  .week-day-section-for-web {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .week-day-section-for-mobile {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .week-days-container {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .date {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    place-items: center;
    font-weight: 700
  }

  .selected-date {
    color: #3063b3;
    font-weight: 700;
  }

  .live_scores_top_section .google-banner {
    display: flex;
  }

  .live_scores_wrapper .live_scores_header_stat {
    display: none;
  }

  .live_scores_top_section .banner {
    width: 100%;
  }

  .live_scores_wrapper .live_scores_top_section .mobile_apps_card, .live_scores_header_stat, .betting_odds {
    display: none;
  }

  .live_scores_wrapper .toggle_buttons {
    display: none;
  }

  .live_scores_wrapper .checkboxes {
    display: none;
  }

  .mobile-view {
    display: flex;
  }

  .live_scores_wrapper .filter_bar {
    display: flex;
    justify-content: space-between;
  }

  .live_scores_wrapper  .filter_bar .toggle_buttons {
    margin: 0 10px 0 0;
  }

  .live-match-for-mobile {
    display: flex;
    place-items: center;
    margin-left: 10px;
  }

  .live-container {
    width: 50px;
    height: 20px;
    border-radius: 9px;
  }

  .live-icon {
    background-color: green;
    border-radius: 10px;
    width: 20px;
  }

  .not-live {
    display: flex;
    place-items: center;
    background-color: #bcc0c7;
    border-radius: 9px;
    justify-content: space-between;
  }

  .live-match {
    display: flex;
    place-items: center;
    background-color: red;
    border-radius: 15px;
    justify-content: space-between;
  }

  .live-text {
    color: white;
    margin-top: 2px;
    margin-left: 5px;
  }

  .not-live-text {
    color: white;
    margin-top: 2px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 426px) {
  .live_scores_header {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #3063b3;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
  }

  .live_scores_wrapper .live_scores_header_stat {
    display: none;
  }

  .live_scores_top_section .banner {
    display: none;
  }

  .live_scores_wrapper .live_scores_top_section .mobile_apps_card, .live_scores_header_stat, .betting_odds {
    display: none;
  }

  .live_scores_wrapper .toggle_buttons {
    display: none;
  }

  .live_scores_wrapper .checkboxes {
    display: none;
  }

  .mobile-view {
    display: none;
  }

  .live_scores_wrapper .filter_bar {
    display: flex;
    justify-content: space-between;
  }

  .live_scores_wrapper  .filter_bar .toggle_buttons {
    margin: 0 10px 0 0;
  }

  .live-match-for-mobile {
    display: flex;
    place-items: center;
    margin-left: 10px;
  }

  .live-container {
    width: 50px;
    height: 20px;
    border-radius: 9px;
  }

  .live-icon {
    background-color: green;
    border-radius: 10px;
    width: 20px;
  }

  .not-live {
    display: flex;
    place-items: center;
    background-color: #bcc0c7;
    border-radius: 9px;
    justify-content: space-between;
  }

  .live-match {
    display: flex;
    place-items: center;
    background-color: red;
    border-radius: 15px;
    justify-content: space-between;
  }

  .live-text {
    color: white;
    margin-top: 2px;
    margin-left: 5px;
  }

  .not-live-text {
    color: white;
    margin-top: 2px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 376px) {
  .live_scores_header {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #3063b3;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
  }

  .live_scores_top_section .banner {
    display: none;
  }

  .mobile-view {
    display: none;
  }

}
</style>
