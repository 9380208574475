<template>
  <div class="layout_container">
    <div class="header">
      <div class="header_first _c2">
        <div class="const_width">
          <div class="social_icons">
            <a href="https://www.instagram.com/futbolverileri/" rel="noopener" target="_blank">
              <img class="social_icon" src="@/assets/icons/instagram.png" alt="instagram"/>
            </a>
            <a href="https://www.facebook.com/futbolverileri/" rel="noopener" target="_blank">
              <img class="social_icon" src="@/assets/icons/facebook.png" alt="instagram"/>
            </a>
            <a href="https://twitter.com/futbolverileri" rel="noopener" target="_blank">
              <img class="social_icon" src="@/assets/icons/twitter.png" alt="instagram"/>
            </a>
          </div>
          <div class="languages">
            <img :class="{'active': this.tr === languageActive()}" @click="setLanguage('tr')" class="language_icon" src="@/assets/flags/TR.png" alt="instagram"/>
            <img :class="{'active': this.en === languageActive()}" @click="setLanguage('en')" class="language_icon" src="@/assets/flags/EN.png" alt="instagram"/>
            <img class="language_icon" src="@/assets/flags/DE.png" alt="instagram"/>
            <img class="language_icon" src="@/assets/flags/FR.png" alt="instagram"/>
            <img class="language_icon" src="@/assets/flags/ES.png" alt="instagram"/>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="header_second _c1 header-for-web">
        <div class="const_width">
          <v-spacer class="spacer-for-mobile"/>
          <div class="logo_container">
            <router-link :to="{name:'LiveScores'}">
              <img class="logo" src="@/assets/logo.png" alt="FutbolVerileri">
            </router-link>
          </div>
          <v-spacer/>
          <div v-if="!user" class="auth-buttons-container">
            <button class="auth_button" @click.stop="dialog = true">Giriş Yap</button>
            <button class="auth_button" @click="toPath('Register')">Kayıt Ol</button>
          </div>
          <div class="auth_buttons_container">
            <v-menu v-if="user" offset-y>
              <template v-slot:activator="{ on }">
                <button class="user_button" v-on="on">
                  {{ user.username }}
                  <v-icon dark>arrow_drop_down</v-icon>
                </button>
              </template>
              <v-list dense>
                <v-list-item :to="{name: 'AccountDetail'}">
                  <v-list-item-title>Profil</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-title class="error--text">Çıkış</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="header_second _c11 header-for-mobile">
        <div class="const_width">
          <v-btn icon dark class="mobile_menu_button" @click="drawer = true">
            <v-icon>menu</v-icon>
          </v-btn>
          <v-navigation-drawer
              v-model="drawer"
              absolute
              temporary
              color="_c11"
              dark
          >
            <v-list nav dense>
              <!--              <v-list-item v-for="(m, mi) in menu" :key="`mm-${mi}`">
                              <v-list-item-title>{{ m.text }}</v-list-item-title>
                            </v-list-item>-->
              <v-divider></v-divider>
              <template v-if="user">
                <v-list-item :to="{name: 'AccountDetail'}">
                  <v-list-item-title>PROFİL</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-title>ÇIKIŞ</v-list-item-title>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item @click="() => {this.drawer = false; this.dialog = true;}">
                  <v-list-item-title>GİRİŞ YAP</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toPath('Register')">
                  <v-list-item-title>KAYIT OL</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-navigation-drawer>
          <v-spacer class="spacer-for-mobile"/>
          <div class="logo_container">
            <router-link :to="{name:'LiveScores'}">
              <span class="mobile-logo">Futbol</span> <span class="mobile-logo logo-color">Verileri</span>
            </router-link>
          </div>
          <v-spacer/>
          <div @click="toTv()" class="tv-container-for-mobile">
            <img class="tv-icon" src="@/assets/icons/tv-icon.png" alt="">
          </div>
        </div>
      </div>

      <div class="header_third white">
        <div class="const_width">
          <v-tabs height="40" slider-color="#3063b3" slider-size="4" color="#0d1c2d"
                  active-class="active_menu">
            <v-tab
                class="text-capitalize"
                v-for="(m, mi) in this.getMenuItems()"
                :key="mi"
                v-text="m.text"
                :to="{name: m.to}"
                style="font-size: 0.85rem"
                exact
            />
          </v-tabs>
        </div>
      </div>

      <div class="menu-section-for-mobile white">
        <div class="tabs-layout">
          <v-tabs background-color="#e6eaeb" height="47" slider-color="#e6eaeb" slider-size="0" color="#0d1c2d" active-class="active_menu">
            <v-tab
                class="text-capitalize tab-and-icons-container"
                v-for="(m, mi) in this.getMenuItemsForMobile()"
                :key="mi"
                :to="{name: m.to}"
                exact
            >
            <img class="mobile-menu-icon" :src="getImgUrl(m.Url)" alt=""/>
            <span class="mobile-menu-titles">{{m.text}}</span>
            </v-tab>
          </v-tabs>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- CONTENT -->
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="const_width">
        <v-row class="footer-for-web">
          <v-col cols="12" sm="3">
            <p class="footer_title">Futbol Verileri</p>
            <p class="footer_flex_subtitle">
              <a href="https://www.futbolverileri.com/terms" target="_blank">Kullanım Şartları</a>
            </p>
            <p class="footer_flex_subtitle">
              <router-link :to="{name: 'Contact'}">
                İletişim
              </router-link>
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <p class="footer_title">Takip Et</p>
            <a href="https://www.instagram.com/futbolverileri/" rel="noopener" target="_blank">
              <p class="footer_flex_subtitle">
                <img src="@/assets/icons/instagram-dark.svg" alt=""/>
                Instagram
              </p>
            </a>
            <a href="https://www.facebook.com/futbolverileri/" rel="noopener" target="_blank">
              <p class="footer_flex_subtitle">
                <img src="@/assets/icons/facebook-dark.svg" alt=""/>
                Facebook
              </p>
            </a>
            <a href="https://twitter.com/futbolverileri" rel="noopener" target="_blank">
              <p class="footer_flex_subtitle">
                <img src="@/assets/icons/twitter-dark.svg" alt=""/>
                Twitter
              </p>
            </a>
          </v-col>
          <v-col cols="12" sm="3">
            <p class="footer_title">Mobil Uygulamalar</p>
            <a href="https://bit.ly/FV-iOS-W" target="_blank" rel="noopener">
              <div class="footer_app_store"></div>
            </a>
            <a href="https://bit.ly/FV-Android-W" target="_blank" rel="noopener">
              <div class="footer_play_store"></div>
            </a>
          </v-col>
          <v-col cols="12" sm="3">
            <p class="footer_title">Diller</p>
            <p class="footer_flex_subtitle">
              <img src="@/assets/flags/TR.png" alt=""/>
              Türkçe
            </p>
            <p class="footer_flex_subtitle">
              <img src="@/assets/flags/EN.png" alt=""/>
              İngilize
            </p>
            <p class="footer_flex_subtitle">
              <img src="@/assets/flags/DE.png" alt=""/>
              Almanca
            </p>
            <p class="footer_flex_subtitle">
              <img src="@/assets/flags/FR.png" alt=""/>
              Fransızca
            </p>
            <p class="footer_flex_subtitle">
              <img src="@/assets/flags/ES.png" alt=""/>
              İspanyolca
            </p>
          </v-col>
          <v-col cols="12" sm="12">
            <img class="footer_logo" src="@/assets/logo.png" alt="FutbolVerileri"/>
            <p class="footer_text">Copyright © 2020 Futbol Verileri</p>
            <p class="footer_text">
              <a href="https://www.begambleaware.org/" rel="noopener" target="_blank">
                <b>BEGAMBLEAWARE.ORG</b> Gamble Responsibly. Gambling Therapy.
              </a>
            </p>
            <p class="footer_text red_text">Followers must be 18+</p>
            <p class="footer_text">
              FutbolVerileri.com'da yer alan tüm sayısal veriler, 1000'den fazla futbol liginden canlı
              skorlar, puan durumu, gol atan oyuncular, futbol ilk yarı sonuçları, kırmızı kartlar, sarı
              kartlar, korner ve şut bilgileri, iddaa oranları, iddaa programı, maç sonuçları, istatistik
              ve tahmin bilgileri sadece bilgilendirme amaçlıdır. Bilgilerin doğruluğunu sağlamak için
              gereken gayret gösterilmektedir ancak bilgilerin doğruluğunun kontrolü kullanıcıların kendi
              sorumluluğundadır. Oluşabilecek hatalardan, maddi/manevi zararlardan FutbolVerileri.com
              sorumlu tutulamaz.
            </p>
          </v-col>
        </v-row>

        <v-row class="footer-for-mobile">
          <div class="footer-containers">
            <div>
              <p class="footer_title">Futbol Verileri</p>
              <p class="footer_flex_subtitle">
                <a href="https://www.futbolverileri.com/terms" target="_blank">Kullanım Şartları</a>
              </p>
              <p class="footer_flex_subtitle">
                <router-link :to="{name: 'Contact'}">
                  İletişim
                </router-link>
              </p>
            </div>
            <div>
              <p class="footer_title">Takip Et</p>
              <a href="https://www.instagram.com/futbolverileri/" rel="noopener" target="_blank">
                <p class="footer_flex_subtitle">
                  <img src="@/assets/icons/instagram-dark.svg" alt=""/>
                  Instagram
                </p>
              </a>
              <a href="https://www.facebook.com/futbolverileri/" rel="noopener" target="_blank">
                <p class="footer_flex_subtitle">
                  <img src="@/assets/icons/facebook-dark.svg" alt=""/>
                  Facebook
                </p>
              </a>
              <a href="https://twitter.com/futbolverileri" rel="noopener" target="_blank">
                <p class="footer_flex_subtitle">
                  <img src="@/assets/icons/twitter-dark.svg" alt=""/>
                  Twitter
                </p>
              </a>
            </div>
          </div>
          <div class="footer-containers">
            <div>
              <p class="footer_title">Mobil Uygulamalar</p>
              <a href="https://bit.ly/FV-iOS-W" target="_blank" rel="noopener">
                <div class="footer_app_store"></div>
              </a>
              <a href="https://bit.ly/FV-Android-W" target="_blank" rel="noopener">
                <div class="footer_play_store"></div>
              </a>
            </div>
            <div>
              <p class="footer_title">Diller</p>
              <p class="footer_flex_subtitle">
                <img src="@/assets/flags/TR.png" alt=""/>
                Türkçe
              </p>
              <p class="footer_flex_subtitle">
                <img src="@/assets/flags/EN.png" alt=""/>
                İngilize
              </p>
              <p class="footer_flex_subtitle">
                <img src="@/assets/flags/DE.png" alt=""/>
                Almanca
              </p>
              <p class="footer_flex_subtitle">
                <img src="@/assets/flags/FR.png" alt=""/>
                Fransızca
              </p>
              <p class="footer_flex_subtitle">
                <img src="@/assets/flags/ES.png" alt=""/>
                İspanyolca
              </p>
            </div>
          </div>
          <div>
            <div>
              <img class="footer_logo" src="@/assets/logo.png" alt="FutbolVerileri"/>
              <p class="footer_text">Copyright © 2020 Futbol Verileri</p>
              <p class="footer_text">
                <a href="https://www.begambleaware.org/" rel="noopener" target="_blank">
                  <b>BEGAMBLEAWARE.ORG</b> Gamble Responsibly. Gambling Therapy.
                </a>
              </p>
              <p class="footer_text red_text">Followers must be 18+</p>
              <p class="footer_text">
                FutbolVerileri.com'da yer alan tüm sayısal veriler, 1000'den fazla futbol liginden canlı
                skorlar, puan durumu, gol atan oyuncular, futbol ilk yarı sonuçları, kırmızı kartlar, sarı
                kartlar, korner ve şut bilgileri, iddaa oranları, iddaa programı, maç sonuçları, istatistik
                ve tahmin bilgileri sadece bilgilendirme amaçlıdır. Bilgilerin doğruluğunu sağlamak için
                gereken gayret gösterilmektedir ancak bilgilerin doğruluğunun kontrolü kullanıcıların kendi
                sorumluluğundadır. Oluşabilecek hatalardan, maddi/manevi zararlardan FutbolVerileri.com
                sorumlu tutulamaz.
              </p>
            </div>
          </div>
        </v-row>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        max-width="400"
        :transition="false"
    >
      <v-card>
        <v-card-title class="_c1">
          <img src="@/assets/logo.png" class="mx-auto my-2" width="220px">
        </v-card-title>
        <v-card-text class="pb-0 pt-4">
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="validate()"
          >
            <v-text-field
                v-model="login.username"
                type="text"
                color="c2"
                label="Kullanıcı Adı / E-posta"
                :rules="requiredRule"
                required
            />
            <v-text-field
                v-model="login.password"
                type="password"
                color="c2"
                label="Şifre"
                :rules="requiredRule"
                required
            />
            <v-container class="py-0">
              <v-col cols="12" sm="12" class="pb-0">
                <v-btn
                    block
                    color="_accent"
                    dark
                    type="submit"
                >
                  GİRİŞ
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" class="pb-0 text-center">
                <v-btn
                    small
                    text
                    color="blue"
                    class="text-capitalize font-weight-regular"
                    @click="toPath('Register')"
                >
                  Kayıt Ol
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" class="text-center pt-0">
                <v-btn
                    small
                    text
                    color="blue"
                    class="text-capitalize font-weight-regular"
                    @click="toPath('ForgotPassword')"
                >
                  Şifremi Unuttum
                </v-btn>
              </v-col>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
        top
        color="error"
        timeout="2000"
        v-model="snackbar"
    >
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BaseLayout",
  data: () => ({
    tr: false,
    en: false,
    drawer: false,
    tab: null,
    dialog: false,
    snackbar: false,
    errorMessage: '',
    menuTR: [
      {text: "Canlı Skor", to: "LiveScores"},
      {text: "Bahis Modu", to: "BetMode"},
      {text: "Bahis Analiz", to: "BetAnalyze"},
      //{text: "İddaa Radar", to: "BetRadar"},
      {text: "Çoklu Maç Merkezi", to: "MultiMatchTracking"},
      {text: "Ligler", to: "LeagueDetail"},
      {text: "TipsterWho", to: "TipsterWho"},
      //{text: "Tahminler", to: "Predictions"},
      //{text: "Premium", to: "Premium", premium: true},
      {text: "Futbol Ekranı", to: "Tv", premium: true},
      {text: "Haberler", to: "NewsList", premium: true},
    ],
    menuEN: [
      {text: "Live Scores", to: "LiveScores"},
      {text: "Bet Mode", to: "BetMode"},
      {text: "Bet Analyze", to: "BetAnalyze"},
      //{text: "Bet Radar", to: "BetRadar"},
      {text: "Multi Match Tracking", to: "MultiMatchTracking"},
      {text: "Leagues", to: "LeagueDetail"},
      {text: "TipsterWho", to: "TipsterWho"},
      //{text: "Predictions", to: "Predictions"},
      //{text: "Premium", to: "Premium", premium: true},
      {text: "TV Guide", to: "Tv", premium: true},
      {text: "News", to: "NewsList", premium: true},
    ],
    menuEnForMobile: [
      {text: "Matches", to: "LiveScores", Url: "matches"},
      {text: "League", to: "LeagueDetail", Url: "league"},
      {text: "Stats", to: "BetAnalyze", Url: "stats"},
      {text: "Premium", to: "Premium", premium: true, Url: "premium"},
    ],
    menuTrForMobile: [
      {text: "Maçlar", to: "LiveScores", Url: "matches"},
      {text: "Lig", to: "LeagueDetail", Url: "league"},
      {text: "İstatistikler", to: "BetAnalyze", Url: "stats"},
      {text: "Premium", to: "Premium", premium: true, Url: "premium"},
    ],
    login: {
      username: '',
      password: '',
    },
    valid: false,
    requiredRule: [
      v => !!v || 'Bu alan boş bırakılamaz!',
    ]
  }),

  created() {
    this.fetchUser();
  },

  methods: {
    languageActive() {
      if (localStorage.getItem('language') === 'tr') {
        return this.tr = true;
      } else {
        return this.en = true;
      }
    },
    getMenuItems() {
      if (localStorage.getItem('language') === 'tr') {
        return this.menuTR
      } else {
        return this.menuEN
      }
    },
    getMenuItemsForMobile() {
      if (localStorage.getItem('language') === 'tr') {
        return this.menuTrForMobile
      } else {
        return this.menuEnForMobile
      }
    },
    getImgUrl(pet) {
      let images = require.context('@/assets/icons', false, /\.png$/)
      return images('./' + pet + ".png")
    },
    setLanguage(lang) {
      localStorage.setItem('language', lang)
      location.reload();
    },
    ...mapActions({
      loginRequest: 'auth_request',
      logout: 'logout',
      fetchUser: 'fetch_user_info',
    }),
    toPath(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({name: routeName});
      }
      if (this.dialog) {
        this.dialog = false;
      }
    },
    toTv() {
      this.$router.push({name: 'Tv'});
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.loginRequest(this.login)
            .then((data) => {
              if (data.data.success) {
                this.dialog = false;
              } else {
                this.errorMessage = data.data.apiError.errorMessage;
                this.snackbar = true;
              }
            });
      }
    },

    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },

  computed: {
    ...mapGetters({
      user: 'user',
    })
  },

  watch: {
    valid(value) {
      if (!value) {
        setTimeout(this.resetValidation, 2000);
      }
    },

    dialog(value) {
      if (!value) {
        this.$refs.form.reset();
        this.resetValidation();
      }
    }
  }
}
</script>

<style scoped>
.layout_container {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: grid !important;
  grid-template-columns: 100%;
  grid-template-rows: 132px auto minmax(200px, auto);
  background-color: #f5f6f7;
}

.menu-section-for-mobile {
  display: none;
}

.active_menu {
  color: #3063b3 !important;
}

.auth-buttons-container {
  display: flex;
  padding: 14px 0;
}

.languages {
  width: 145px;
  display: flex;
  justify-content: space-between;
}

.language_icon {
  width: 20px;
  height: 20px;
}

.language_icon:hover {
  opacity: 0.7;
}

.active {
  width: 23px;
  height: 23px;
}

.language_icon:not(:last-child) {
  margin-right: 10px;
}

.tv-container-for-mobile {
  display: none;
}

.spacer-for-mobile {
  display: none;
}

.header-for-mobile {
  display: none;
}

.footer-for-mobile {
  display: none;
}

@media only screen and (max-width: 650px) {

  .header_third {
    display: none;
  }

  .auth-buttons-container {
    display: none;
  }

  .for-mobile {
    display: none;
  }

  .header_first {
    display: none;
  }

  .header-for-mobile {
    display: flex;
    place-items: center;
  }

  .header-for-web {
    display: none;
  }

  .layout_container {
    grid-template-rows: 64px auto minmax(200px, auto);
  }

  .header {
     grid-template-rows: none;
  }

  .menu-section-for-mobile {
    display: inline;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  .tabs-layout {
    background-color: #e6eaeb;
  }

  .tab-and-icons-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;
    padding: 0;
  }

  .mobile-menu-titles {
    font-size: 0.80rem;
  }

  .mobile-menu-icon {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }

  .active_menu {
    color: #3063b3 !important;
    background-color: #ced2d9;
  }

  .spacer-for-mobile {
    display: flex;
  }

  .logo_container {
    display: flex;
    place-items: center;
  }

  .mobile-logo {
    font-size: 18px;
    font-weight: 900;
    background-color: white;
    padding: 5px;
  }

  .logo-color {
    color: white;
    background-color: black;
  }
}

@media only screen and (max-width: 576px) {

  .layout_container {
    grid-template-rows: 64px auto minmax(200px, auto);
  }

  .mobile_menu_button {
    margin-top: 0;
  }

  .for-mobile {
    display: none;
  }

  .header_third {
    display: none;
  }

  .header_first {
    display: none;
  }

  /*.auth_buttons_container {
    display: none;
  }*/

  .menu-section-for-mobile {
    display: inline;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  .tabs-layout {
    background-color: #e6eaeb;
  }

  .tab-and-icons-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;
    padding: 0;
  }

  .mobile-menu-titles {
    font-size: 0.80rem;
  }

  .mobile-menu-icon {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }

  .active_menu {
    color: #3063b3 !important;
    background-color: #ced2d9;
  }

  .tv-container-for-mobile {
    display: flex;
    place-items: center;
    margin-bottom: 5px;
    margin-left: 10px;
  }

  .tv-icon {
    width: 25px;
    height: 25px;
  }

  .footer-for-web {
    display: none;
  }

  .footer-for-mobile {
    display: flex;
  }

  .footer-containers {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

</style>
