<template>
  <div class="general-section">

    <!-- Counter -->

    <counter v-if="matchTeam.status._id === 0" :matchTeam="matchTeam"></counter>


    <!-- Match Ended -->

    <div class="match-result-section" v-if="matchTeam.status._id === 100 || matchTeam.status._id === 31">
      <div class="match-ended" v-if="matchTeam.status._id === 100">
        MAÇ BİTTİ
      </div>
      <div class="match-ended" v-if="matchTeam.status._id === 31">
        İLK YARI
      </div>

      <div class="match-result-container">
        <div class="match-result">
          {{ matchTeam.result.home }}
        </div>
        <span style="font-size: 20px; margin: 0 5px 0 5px">:</span>
        <div class="match-result">
          {{ matchTeam.result.away }}
        </div>
      </div>
    </div>

    <span><hr class="hr" v-if="referee"></span>

    <div class="referee-container" v-if="referee">
      <span class="referee" style="font-weight: bold">{{referee.name}}</span>
      <span> <svg class="icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-size="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#cccccc"><path d="M48.44499,6.08887l-10.87598,9.32227l21.5,25.08333l10.87598,-9.32227zM143.61328,14.36133c-1.93499,-0.08062 -3.90583,0.6226 -5.375,2.1276l-11.46386,11.60384c-15.98167,-3.36833 -33.03609,0.645 -46.07942,10.75l26.80501,31.03223l-14.33333,8.95833l-25.15332,-25.93717l-60.84668,83.27051l27.72884,30.45833l58.48112,-36.47722c7.74,3.87 16.48445,6.01888 24.43945,6.01888c29.38333,0 54.18392,-23.72278 54.18392,-53.10612c0,-12.255 -5.01499,-24.14943 -12.61166,-33.53776l4.36719,-7.30664c1.64833,-2.58 1.36111,-5.94945 -0.57389,-8.31445l-14.40332,-16.99284c-1.32583,-1.6125 -3.23005,-2.4669 -5.16505,-2.54753zM21.5,43v14.33333h21.80794v-14.33333z"></path></g></g></svg> <span class="referee-text" style="font-weight: normal">HAKEM</span></span>
    </div>

    <span><hr class="hr" v-if="manager"></span>


    <div class="manager-and-team-section" v-if="manager">
      <div>
        <div>
          <span class="font-size" style="font-weight: bolder">{{ manager.home.name }}</span> <br>
          <span class="font-size2" style="font-weight: lighter"> TEKNİK DİREKTÖR </span>
        </div>
      </div>
      <div>
        <div>
          <span class="font-size" style="font-weight: bolder">{{ manager.away.name }}</span><br>
          <span class="font-size2" style="font-weight: lighter"> TEKNİK DİREKTÖR </span>
        </div>
      </div>
    </div>

    <span><hr class="hr"></span>

    <div class="futbol-verileri-container">
      <v-img src="@/assets/lmt/img-label-fv@2x.png" class="fv-img" contain/>
    </div>

  </div>
</template>

<script>
import Counter from "../Counter";

export default {
  name: "MatchResult",
  components: {
    Counter
  },
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    referee: Object,
    manager: Object
  }
}
</script>

<style scoped>

.general-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.match-result-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  place-items: center;
}

.match-ended {
  font-size: 10px;
}

.font-size {
  font-size: 10px;
}

.font-size2 {
  font-size: 9px;
}

.referee {
  font-size: 9px;
}

.referee-text {
  font-size: 8px;
}

.manager-and-team-section {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  place-items: center;
  width: 100%;
}

.hr {
  height: 1px;
  border-width: 0;
  background-color: #c9c9c9;
}

.match-result-container {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100px;
}

.match-result {
  display: flex;
  justify-content: center;
  place-items: center;
  background-color: red;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 10px;
  color: white;
  border-radius: 2px;
  font-weight: bold;
}

.icon {
  width: 0.6rem;
  height: 0.6rem;
}

.referee-container {
  display: grid;
  place-items: center;
}

.futbol-verileri-container {
  display: grid;
  justify-content: center;
  place-items: center;
}

.fv-img {
  width: 60%;
  height: 75%;
}

@media only screen and (max-width: 1264px) {

  .match-ended {
    font-size: 15px;
  }

  .font-size {
    font-size: 15px;
  }

  .font-size2 {
    font-size: 14px;
  }

  .match-result {
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .match-ended {
    font-size: 12px;
  }

  .font-size {
    font-size: 12px;
  }

  .font-size2 {
    font-size: 11px;
  }

  .match-result {
    font-size: 1rem;
    width: 1.8rem;
    height: 1.8rem;
  }
}

@media only screen and (max-width: 540px) {
  .match-ended {
    font-size: 10px;
  }

  .font-size {
    font-size: 10px;
  }

  .font-size2 {
    font-size: 9px;
  }

  .icon {
   width: 0.5rem;
    height: 0.5rem;
  }

  .match-result {
    font-size: 0.9rem;
    width: 1.3rem;
    height: 1.3rem;
  }
}

@media only screen and (max-width: 420px) {

  .manager-and-team-section {
    line-height: 0.8rem;
  }

  .match-ended {
    font-size: 9px;
  }

  .font-size {
    font-size: 10px;
  }

  .font-size2 {
    font-size: 7px;
  }

  .icon {
    width: 0.5rem;
    height: 0.5rem;
  }
}

</style>