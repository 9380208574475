var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popular_leagues_container"},[(!_vm.popularLeagues)?_c('v-skeleton-loader',{staticClass:"popular_leagues_skeleton",attrs:{"type":"image","height":"50"}}):_vm._e(),(_vm.popularLeagues)?_c('v-slide-group',{attrs:{"show-arrows":""}},_vm._l((_vm.popularLeagues),function(league){return _c('v-slide-item',{key:league.seasonId,staticClass:"slide-item"},[_c('router-link',{staticClass:"league-button-a",attrs:{"to":{
                                name: 'LeagueDetail',
                                params:
                                {
                                    categoryId: league.areaId,
                                    seasonId: league.seasonId,
                                    leagueId: league.leagueId,
                                }}}},[_c('div',{staticClass:"league-button"},[_c('v-img',{staticClass:"float-left league-button-img",attrs:{"src":_vm.getImgUrl(league.areaId),"lazy-src":_vm.getImgUrl(league.areaId)}}),_c('span',{staticClass:"float-left"},[_vm._v(_vm._s(league.league))])],1)])],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }