import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify';
import VTiptap from "@peepi/vuetify-tiptap";

//import VueAnalytics from 'vue-analytics';
//import api from "@/api";


// VueAnalytics
//Vue.use(VueAnalytics, {
//    id: 'G-VX4299C7F7',
//    router
//});

//if (!store.getters.location) {
//    store.dispatch('get_location').then((callback) => {
//        if (callback) {
//            if (store.getters.location.country_code === 'TR') {
//                api.defaults.headers.common['X-Language'] = 'tr';
//            } else {
//                api.defaults.headers.common['X-Language'] = 'en';
//            }
//        }
//    });
//} else {
//    if (store.getters.location.country_code === 'TR') {
//        api.defaults.headers.common['X-Language'] = 'tr';
//    } else {
//        api.defaults.headers.common['X-Language'] = 'en';
//    }
//}

Vue.use(VTiptap);

Vue.config.ignoredElements = [
    'amp-ad'
]

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
