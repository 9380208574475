<template>
  <div class="loading-container">
    <v-progress-circular
        class="loading-bar"
        :size="60"
        :width="6"
        color="primary"
        indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "LoadingBar"
}
</script>

<style scoped>
.loading-container {
  height: 400px;
  width: 100%;
  display: flex;
}
.loading-bar{
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}
</style>
