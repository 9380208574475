<template>
    <v-dialog
        v-model="dialog"
        width="800"
    >
        <v-card>
            <div class="teams-container _header _accent--text">
                <span class="mbs-container" :class="mbsStyle(event.mbs)">{{ event.mbs }}</span>
                <div class="wrap-overflow text-right">{{ event.homeTeam }}</div>
                <div class="text-center">-</div>
                <div class="wrap-overflow">{{ event.awayTeam }}</div>
                <div>
                    <v-btn icon @click="closeSelf">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </div>
            </div>
            <template v-if="loading">
                <div class="odds-wrapper">
                    <v-progress-circular size="72" color="_accent" indeterminate/>
                </div>
            </template>
            <template v-else>
                <div class="odds-container">
                    <v-card-text v-if="markets && markets.length > 0">
                        <v-row v-if="markets">
                            <div
                                class="odd-container"
                                v-for="{id, name, odds} in markets"
                                :key="id"
                            >
                                <table>
                                    <thead>
                                    <tr class="_accent white--text">
                                        <th :colspan="odds.length">{{ name }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th v-for="odd in odds" :key="'header-' + odd.id">
                                            {{ odd.name }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td
                                            v-for="odd in odds"
                                            :key="'value-' + odd.id"
                                            @click="goToBilyoner"
                                            class="c-pointer"
                                        >
                                            {{ odd.sodd }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-row>
                    </v-card-text>
                    <v-card-text v-if="markets && markets.length === 0">
                        <div class="odds-wrapper">
                            <span class="grey--text">Bu maç için sonuç bulunamadı!</span>
                        </div>
                    </v-card-text>
                </div>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
//import api from "@/api";

export default {
    name: "EventOddsDialog",
    props: {
        event: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            dialog: true,
            markets: null,
            loading: false
        }
    },
    //mounted() {
    //    this.fetchOdds();
    //},
    methods: {
        closeSelf() {
            this.$emit("event-odds-dialog-closed", false);
        },
        mbsStyle(mbs) {
            switch (mbs) {
                case 1:
                    return 'red-bg';
                case 2:
                    return 'blue-bg';
                case 3:
                    return 'green-bg';
                default:
                    return '';
            }
        },
        //fetchOdds() {
        //    this.loading = true;
        //    api.get(`api/fvscore/match/${this.event.id}/fv-iddaa`).then((res) => {
        //        if (res.data.success) {
        //            this.markets = res.data.data?.fvIddaaMarkets || null;
        //        } else {
        //            this.markets = [];
        //        }
        //    }).then(() => {
        //        this.loading = false;
        //    });
        //},
        goToBilyoner() {
            let path = `https://www.bilyoner.com/mac-karti/futbol/${this.event.iddaaId}?utm_source=futbolverileri&utm_medium=desktop`
            window.open(path, "_blank");
        }
    },
    watch: {
        dialog(value) {
            if (!value)
                this.closeSelf();
        }
    }
}
</script>

<style scoped>
.teams-container {
    display: grid;
    height: 40px;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    grid-template-columns: 36px minmax(40%, 48%) auto minmax(40%, 48%) 36px;
}

.wrap-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
}

.mbs-container {
    display: block;
    width: 18px;
    height: 18px;
    line-height: 20px;
    margin: 2px;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-size: 12px;
}

.mbs-container.green-bg {
    background-color: #18bb18;
}

.mbs-container.blue-bg {
    background-color: #00a7ff;
}

.mbs-container.red-bg {
    background-color: #f23232;
}

.odd-container {
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 4px;
}

th, td {
    padding: 0 8px;
    line-height: 1.6rem;
    text-align: center;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-right: thin solid rgba(0, 0, 0, 0.12);
}

th:not(:last-child), td:not(:last-child) {
    border-right: thin solid rgba(0, 0, 0, 0.12);

}

tr td:first-child, tr th:first-child {
    border-left: thin solid rgba(0, 0, 0, 0.12);
}

.odds-wrapper {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.odds-container {
    width: 100%;
    min-height: 200px;
    padding: 0 12px;
}

.c-pointer {
    cursor: pointer;
}
</style>
