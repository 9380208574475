<template>
  <div>
    <div class="filter-bar">
      <div class="filter-bar-container text-right"></div>
    </div>
    <v-container>
      <div class="content-container">
        <template v-if="user">
          <div class="w-100 text-center mb-1">
            <span class="th-header">Yeni Tahmin Oluştur</span>
          </div>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="general-form-section"
          >
            <v-row>
<!--              <v-col cols="12" md="3" class="py-1">
                <v-dialog
                    v-model="dateModal"
                    width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="date"
                        label="Karşılaşma Tarihi *"
                        prepend-inner-icon="event"
                        readonly
                        filled
                        dense
                        placeholder="Karşılaşma Tarihi"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      @input="dateModal = false"
                      scrollable
                      no-title
                      locale="tr"
                      first-day-of-week="1"
                  ></v-date-picker>
                </v-dialog>
              </v-col>-->
              <v-col v-if="matchList" cols="12" md="6" class="py-1">
                <v-autocomplete
                    v-model="eventObj"
                    :items="matchList"
                    :loading="matchListLoading"
                    hide-no-data
                    hide-selected
                    item-text="matchName"
                    :disabled="!matchList"
                    label="Karşılaşma"
                    prepend-inner-icon="search"
                    persistent-hint
                    return-object
                    placeholder="Karşılaşma Arayınız"
                    filled
                    dense
                    :rules="requiredRule"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="py-1">
                <v-text-field
                    v-model="selectionText"
                    :items="selectItems"
                    label="Seçim *"
                    placeholder="Ör: MS X"
                    readonly
                    filled
                    :rules="requiredRule"
                    @click="selectDialog = true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-1">
                <v-text-field
                    v-model="odds"
                    label="Oran *"
                    placeholder="min: 1.40, max: 50"
                    required
                    filled
                    dense
                    :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-1">
                <v-text-field
                    v-model="stake"
                    label="Stake *"
                    filled
                    type="number"
                    dense
                    height="10"
                    persistent-hint
                    :rules="requiredRule"
                ></v-text-field>
              </v-col>
<!--              <v-col cols="12" md="3" class="py-1">
                <v-select
                    v-model="sportId"
                    :items="SPORTS"
                    value="value"
                    item-text="text"
                    label="Spor *"
                    placeholder="Ör: Futbol"
                    filled
                    dense
                    :rules="requiredRule"
                ></v-select>
              </v-col>-->
<!--              <v-col cols="12" md="3" class="py-1">
                <v-text-field
                    v-model="bookie"
                    label="Bahis Firması"
                    placeholder="Ör: Bet365"
                    hint="Opsiyonel"
                    persistent-hint
                    filled
                    dense
                ></v-text-field>
              </v-col>-->
              <!--                            <v-col cols="12" md="6" class="py-1">
                                              <v-textarea
                                                      v-model="comment"
                                                      name="input-7-1"
                                                      label="Açıklama"
                                                      hint="Opsiyonel"
                                                      persistent-hint
                                                      filled
                                                      dense
                                              ></v-textarea>
                                          </v-col>-->
              <v-col cols="12" md="3" class="py-1">
                <!--                                <v-checkbox
                                                        v-model="vip"
                                                        label="VIP"
                                                ></v-checkbox>-->
                <v-checkbox
                    v-model="live"
                    label="CANLI"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" class="mx-auto">
                <v-btn color="primary" block large @click="validate">TAHMİNİ KAYDET</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" class="mx-auto text-center py-0">
                <v-btn text color="error" @click="reset">FORMU TEMİZLE</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" class="mx-auto">
                <v-btn color="primary" block large @click="toPredictions()">TAHMİNLERE GİT</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-else>
          <v-row>
            <span class="th-header error--text mx-auto member-login">Tahmin girebilmek için üye girişi yapınız!</span>
          </v-row>
        </template>
      </div>
    </v-container>
    <v-dialog
        v-model="selectDialog"
        max-width="500"
    >
      <div class="selection-dialog">
        <div class="dialog-absolute-header">
          <v-tabs height="40"
                  show-arrows
                  v-model="tab"
                  background-color="primary"
                  dark
          >
            <v-tab
                v-for="(item,ii) in selectItems"
                :key="ii"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </div>
        <div class="selection-dialog-content">
          <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="item in selectItems"
                :key="item.order"
            >
              <v-card flat>
                <v-list dense>
                  <template v-for="m in item.groups">
                    <v-subheader :key="`111${m.id}`">
                      <span class="mx-auto dialog-text">{{ m.name }}</span>
                    </v-subheader>
                    <v-list-item @click="setSelectObj(o)" v-for="o in m.markets"
                                 :key="`222${o.id}`">
                      <span class="mx-auto dialog-text">{{ o.selectionName }}</span>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import api3 from "../../api";
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

export default {
  name: "CreateNewTip",

  data: () => ({
    valid: false,
    currentDate: new Date().toISOString().substr(0, 10),
    date: null,
    selectDialog: false,
    dateModal: false,
    matchList: null,
    matchListLoading: false,
    tab: 0,
    SPORTS,
    requiredRule: [value => !!value || 'Bu alan zorunludur!'],
    eventObj: null,
    selectionObj: null,
    selectionText: '',
    stake: 0,
    sportId: '',
    odds: '',
    bookie: '',
    live: false,
    vip: false,
    comment: '',
  }),

  mounted() {
    this.fetchMatches();
    if (!this.selectItems) {
      this.fetch_select_items();
    }
  },

  methods: {
    ...mapActions([
      'fetch_select_items'
    ]),
    async fetchMatches() {
      const data = await api3.get(`api/v5/fvtips/matches?date=${dayjs(this.currentDate).format("YYYY-MM-DD")}`);
      this.matchList = data.data.data;
      this.getMatchName();
      this.matchListLoading = false;
    },
    getMatchName() {
      if (this.matchList) {
        const matchList =[];
        this.matchList.forEach(area => {
          matchList.push(...(area.matches || []))
        })

        this.matchList = matchList.map(match => {
          return {
            ...match,
            matchName: `${match.homeTeam} v ${match.awayTeam}`
          }
        })
        console.log("matclist",this.matchList)
        return this.matchList
      }
    },
    async createTip() {
      let data = {
        //eventId: this.eventObj.id,
        //selection: this.selectionObj.name,
        //sportId: this.sportId,
        //bookie: this.bookie,
        odds: this.odds,
        stake: this.stake,
        live: this.live,
        bets:[
          {
            event: this.eventObj.matchName,
            eventId: this.eventObj.id,
            odds: this.odds,
            selection: this.selectionObj.selectionName,
            live: this.live,
          }
        ],
        //vip: this.vip,
        //comment: this.comment,
        //marketId: this.selectionObj.betMarketId,
        //marketOutcomeId: this.selectionObj.id,
      };
      await api3.post('api/v5/auth-whotips/tips', data, {
        headers:
            {
              Authorization: `Bearer ${this.token}`,
              Platform: 'TipsterWho'
            }
      }).then((data) => {
        if (data.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Tahmin kaydedildi!',
            showConfirmButton: false,
            timer: 2000
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Hata!',
            text: 'Lütfen daha sonra tekrar deneyin.',
            showConfirmButton: false,
            timer: 2000
          })
        }
      });
    },
    validate() {
      if (this.$refs.form.validate && this.valid) {
        this.createTip();
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    setSelectObj(obj) {
      this.selectionObj = obj;
      this.selectDialog = false;
      this.selectionText = obj.selectionName;
    },
    toPredictions() {
      return this.$router.push('tipsterWho')
    }
  },

  computed: {
    ...mapGetters([
      'selectItems',
      'token',
      'user'
    ]),
  },

  watch: {
    date(value) {
      if (value) {
        this.matchListLoading = true;
        this.fetchMatches();
      }
    },
  }
}

const SPORTS = [
  {text: 'Futbol', value: 1}
]
</script>

<style>

.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #ffffff;
}

.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  padding-top: 8px;
}

.general-form-section {
  margin-top: 20px;
}

.th-header {
  font-size: 16px;
}

.selection-dialog {
  position: relative;
  max-height: 540px;
  min-height: 540px;
}

.dialog-absolute-header {
  position: absolute;
  width: 100%;
  height: 40px;
  top: 0;
}

.dialog-text {
  font-size: 15px;
}

.member-login {
  margin-top: 20px;
}

.selection-dialog-content {
  width: 100%;
  position: absolute;
  top: 40px;
  max-height: 500px;
  min-height: 500px;
  background-color: #ffffff;
  overflow: scroll;
}
</style>
