<template>
  <div class="const_width">
    <div class="content_container">
      <div class="add-news-section">
        <template>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-text-field
                v-model="title"
                label="Başlık"
                required
            ></v-text-field>

            <v-text-field
                v-model="subTitle"
                label="Alt Başlık"
                required
            ></v-text-field>

            <v-tiptap v-model="body"/>

            <v-container class="general-content">
              <v-layout row>
                <v-flex md6 offset-sm3>
                  <div>
                    <span class="add-image-text">Resim Ekle</span>
                    <div class="select-image">
                      <input type="file" @change="onFileChanged">
                    </div>
                    <div v-if="image!=null">
                      <img alt="" class="preview" height="268" width="100%" :src="image.name">
                      <br>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>

<!--            <v-select
                v-model="select"
                :items="items"
                :rules="[v => !!v || 'Item is required']"
                label="Kategori"
                required
            ></v-select>-->

            <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="onUpload()"
                style="margin-top: 10px"
            >
              Gönder
            </v-btn>

            <div class="clear-form-button" @click="reset">
              Formu Temizle
            </div>
          </v-form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api3 from "../../api";

export default {
  name: "AddNews",
  data: () => ({
    valid: true,
    title: '',
    subTitle: '',
    body: null,
    image: null,
    select: null,
    items: [
      'Gol',
      'Penaltı',
      'Ofsayt',
      'Maç Sonu',
    ]
  }),

  methods: {
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset();
      this.body = '';
      this.image = null;
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    onUpload() {
      const formData = new FormData()
      formData.append('image', this.image, this.image.name)
      formData.append('title', this.title)
      formData.append('subTitle', this.subTitle)
      formData.append('body', this.body)
      api3.post('api/v5/fvnews/add', formData).then(res => {
        console.log("Response", res)
      })
    },
    onFileChanged(event) {
      this.image = event.target.files[0]
      console.log("selected", this.image)
    },
    /*async addNews() {
      let news = {
        title: this.title,
        subTitle: this.subTitle,
        body: this.body,
        image: this.selectedFile
      };
      await api3.post('api/v5/fvnews/add', news).then((data) => {
        if (data.data.success) {
          console.log('Upload')
        } else {
          console.log('Error')
        }
      })
    },*/

  },
}
</script>

<style scoped>

.add-news-section {
  display: flex;
  justify-content: center;
}

.add-news-section .v-form {
  width: 60%;
}

.general-content {
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.add-image-text {
  font-size: 15px;
  font-weight: 700;
  color: #292522;
}

.select-image {
  padding: 20px 0;
}

.clear-form-button {
  color: red;
  font-size: 13px;
  margin-top: 5px;
  cursor: pointer;
  text-decoration: underline;
}

</style>