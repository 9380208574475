<template>
  <div class="position-container">
    <div class="substitution-container blink">

        <div class="player-out-section">
          <div class="player-out-container">
            <div class="player-name-and-number">
              <div class="player-name">
                {{selectedEvent.playerout.name}}
              </div>
              <div class="number">
                {{selectedEvent.shirtnumbers.out}} | {{selectedEvent.playerout.position.name.toUpperCase()}}
              </div>
            </div>

            <div class="arrow-container">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-size="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#e74c3c"><path d="M163.32161,83.07318l-86,-60.91667c-1.0848,-0.76986 -2.51961,-0.86784 -3.71631,-0.25895c-1.18978,0.61589 -1.93864,1.84766 -1.93864,3.18441v32.25h-57.33333c-3.95426,0 -7.16667,3.2124 -7.16667,7.16667v43c0,3.95426 3.2124,7.16667 7.16667,7.16667h57.33333v32.25001c0,1.33675 0.74886,2.56852 1.93864,3.18441c0.5179,0.26595 1.0848,0.39893 1.64469,0.39893c0.72786,0 1.45573,-0.22396 2.07161,-0.65788l86,-60.91667c0.94482,-0.67187 1.51172,-1.76367 1.51172,-2.92546c0,-1.16178 -0.56689,-2.25358 -1.51172,-2.92546z"></path></g></g></svg>
            </span>
              <span class="arrow-below-text">
                {{i18n.substitionOut}}
              </span>
            </div>
          </div>
        </div>

        <div class="substitution-text-section">
          <div class="substitution-container-text">
            {{selectedEvent.type.toUpperCase()}}
          </div>
        </div>

        <div class="player-in-section">
          <div class="player-in-container">
            <div class="arrow-container">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-size="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#2ecc71"><path d="M157.66667,57.33333h-57.33333v-32.25c0,-1.33675 -0.74886,-2.56852 -1.93864,-3.18441c-0.51516,-0.26215 -1.07943,-0.3985 -1.64253,-0.39893c-0.72975,-0.00056 -1.45751,0.22333 -2.07378,0.65788l-86,60.91667c-0.94482,0.67188 -1.51172,1.76367 -1.51172,2.92546c0,1.16178 0.56689,2.25358 1.51172,2.92546l86,60.91667c0.61589,0.43392 1.34375,0.65788 2.07161,0.65788c0.5599,0 1.12679,-0.13298 1.64469,-0.39893c1.18978,-0.61589 1.93864,-1.84766 1.93864,-3.18441v-32.25h57.33333c3.95426,0 7.16667,-3.2124 7.16667,-7.16667v-43c0,-3.95426 -3.2124,-7.16667 -7.16667,-7.16667z"></path></g></g></svg>
              </span>
              <span class="arrow-below-text">
                {{i18n.substitionIn}}
              </span>
            </div>

            <div class="player-name-and-number">
              <div class="player-name">
                {{selectedEvent.playerin.name}}
              </div>
              <div class="number">
                {{selectedEvent.playerin.position.name.toUpperCase()}} | {{selectedEvent.shirtnumbers.in}}
              </div>
            </div>

          </div>
        </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "Throwin",
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    i18n: Object
  },
}
</script>

<style scoped>

.blink {
  animation: reveal .5s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(100% 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.substitution-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.player-out-section {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.arrow-container {
  display: flex;
  flex-direction: column;
  place-items: center;
}

.player-name {
  color: #00a7ff;
  font-size: 12px;
  font-weight: 500;
}

.number {
  font-weight: 300;
}

.substitution-text-section {
  display: flex;
  justify-content: center;
  width: 100%;
}

.player-in-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.player-out-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  place-items: center;
  padding: 0 10px;
  border-radius: 0 0 10px 0;
  width: 220px;
  height: 40px;
  background-color: white;
}

.player-name-and-number {
  display: flex;
  flex-direction: column;
}

.substitution-container-text {
  display: flex;
  place-items: center;
  justify-content: center;
  width: 40%;
  height: 50px;
  font-size: 15px;
  font-weight: 300;
  color: #909090;
  background-color: white;
}

.player-in-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  place-items: center;
  padding: 0 10px;
  border-radius: 0 0 0 10px;
  width: 220px;
  height: 40px;
  background-color: white;
}

.arrow-below-text {
  color: #afafaf;
  font-weight: 500;
  font-size: 11px;
}

.substitution {
  display: flex;
  place-items: center;
  padding: 0 8px;
  justify-content: space-between;
  background-color: white;
  border-radius: 1px;
  width: 170px;
  height: 40px;
}


.font-size {
  font-size: 13px;
  font-weight: normal;
  text-align: right;
  min-width: 100px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.team-name {
  font-size: 10px;
}

.match-time {
  display: grid;
  place-items: center;
  background-color: #3eaa77;
  text-align: center;
  font-size: 12px;
  min-width: 24px;
  padding: 0 4px;
  height: 24px;
  border-radius: 2px;
  color: white;
}

</style>