<template>
      <div>
        <div class="tv-for-web">
          <div class="content-container">
            <div class="league-container">
              <div class="league-and-area">
                <img class="country-flag"
                     :aspect-ratio="1"
                     :lazy-src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/categories/${item.match.areaId}.png`"
                     :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/categories/${item.match.areaId}.png`"
                     alt=""
                />
                <span class="area-name"><span class="area">{{ item.match.areaName }}</span> - <span
                    class="competition-name">{{ item.match.competitionName }}</span> </span>
              </div>
              <!--            <div v-if="!item.match.ended || isFav(item.match)">
                            <img v-if="isFav(item.match)" @click="setFav(item.match)" src="@/assets/icons/fav-on.svg" alt="fav"/>
                            <img v-else @click="setFav(item.match)" src="@/assets/icons/fav-off.svg" alt="fav"/>
                          </div>-->
            </div>
            <div class="league-and-match-container">
              <div class="teams-container">
                <div class="teams-and-names-home">
                  <span class="team-name-home">{{ item.match.homeTeam }}</span>
                  <img class="md_team_logo" @click="toTeamDetail(item.match.homeTeamId)"
                       :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${item.match.homeTeamId}.png`" alt=""/>
                </div>

                <div class="time-and-result-container">
                  <span class="live" v-if="item.match.live && item.match.halfTime">İY</span>
                  <span class="live" v-if="item.match.live && !item.match.halfTime">{{ item.match.st }}'</span>
                  <span class="start-time"
                        v-if="item.match.status === 'Fixture' && item.match.status !== 'Postponed'">{{ startTime(item.match.dateTimeUtc) }}</span>
                  <span class="versus" v-if="item.match.status === 'Fixture' && item.match.status !== 'Postponed'">V</span>
                  <span v-if="item.match.status !== 'Fixture' && item.match.status === 'Postponed'">Ert.</span>
                  <span class="score" v-if="item.match.status !== 'Fixture' && item.match.score !== 'null - null'">{{
                      item.match.hscoreFT
                    }} - {{ item.match.ascoreFT }}</span>
                </div>

                <div class="teams-and-names-away">
                  <img class="md_team_logo" @click="toTeamDetail(item.match.awayTeamId)"
                       :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${item.match.awayTeamId}.png`" alt=""/>
                  <span class="team-name-away">{{ item.match.awayTeam }}</span>
                </div>
              </div>

              <div class="channels-section">
                <div v-for="(channel,ii) in item.channels" :key="ii" class="channels-container">
                  <div class="channels">
                    <div>
                      <img class="country-flag-for-channel"
                           :aspect-ratio="1"
                           :lazy-src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/tv-country/${channel.countryName}.png`"
                           :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/tv-country/${channel.countryName}.png`"
                           alt=""
                      />
                    </div>
                    <div class="country-name-and-ch-name">
                      {{ channel.countryName }} - {{ channel.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div class="tv-for-mobile">
          <div class="content-container">
            <div class="league-and-match-container">
              <div class="league-container">
                <div class="league-and-area">
                  <img class="country-flag"
                       :aspect-ratio="1"
                       :lazy-src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/categories/${item.match.areaId}.png`"
                       :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/categories/${item.match.areaId}.png`"
                       alt=""
                  />
                  <span class="area-name"><span class="area">{{ item.match.areaName }}</span> - <span
                      class="competition-name">{{ item.match.competitionName }}</span> </span>
                </div>
                <!--            <div v-if="!item.match.ended || isFav(item.match)">
                              <img v-if="isFav(item.match)" @click="setFav(item.match)" src="@/assets/icons/fav-on.svg" alt="fav"/>
                              <img v-else @click="setFav(item.match)" src="@/assets/icons/fav-off.svg" alt="fav"/>
                            </div>-->
              </div>

              <div class="teams-container">
                <div class="teams-and-names">
                  <img class="md_team_logo" @click="toTeamDetail(item.match.homeTeamId)"
                       :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${item.match.homeTeamId}.png`" alt=""/>
                  <span class="team-name">{{ item.match.homeTeam }}</span>
                </div>

                <div class="time-and-result-container">
                  <span class="live" v-if="item.match.live && item.match.halfTime">İY</span>
                  <span class="live" v-if="item.match.live && !item.match.halfTime">{{ item.match.st }}'</span>
                  <span class="start-time"
                        v-if="item.match.status === 'Fixture' && item.match.status !== 'Postponed'">{{ startTime(item.match.dateTimeUtc) }}</span>
                  <span class="versus" v-if="item.match.status === 'Fixture' && item.match.status !== 'Postponed'">V</span>
                  <span v-if="item.match.status !== 'Fixture' && item.match.status === 'Postponed'">Ert.</span>
                  <span class="score" v-if="item.match.status !== 'Fixture' && item.match.score !== 'null - null'">{{
                      item.match.hscoreFT
                    }} - {{ item.match.ascoreFT }}</span>
                </div>

                <div class="teams-and-names">
                  <img class="md_team_logo" @click="toTeamDetail(item.match.awayTeamId)"
                       :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${item.match.awayTeamId}.png`" alt=""/>
                  <span class="team-name">{{ item.match.awayTeam }}</span>
                </div>
              </div>
            </div>

            <div v-for="(channel,ii) in item.channels" :key="ii" class="channels-container">
              <div class="channels">
                <div>
                  <img class="country-flag-for-channel"
                       :aspect-ratio="1"
                       :lazy-src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/tv-country/${channel.countryName}.png`"
                       :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/tv-country/${channel.countryName}.png`"
                       alt=""
                  />
                </div>
                <div class="country-name-and-ch-name">
                  {{ channel.countryName }} - {{ channel.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>


import {mapActions} from "vuex";
import dayjs from "dayjs";

export default {
  name: "TvContent",
  props: ['item'],
  methods: {
    ...mapActions([
      'set_Tv_fav_status'
    ]),
    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    startTime(value) {
      return dayjs(value).format("HH:mm")
    },
    setFav(item) {
      this.set_Tv_fav_status(item);
    },
    isFav(item) {
      return this.$store.state.tv_fav_games.find(f => f.id === item.id);
    },
  }
}
</script>

<style scoped>

.tv-for-mobile {
  display: none;
}

.content-container {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  margin-bottom: 15px;
}

.league-container {
  display: flex;
  justify-content: space-between;
}

.country-flag {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.league-and-match-container {
  display: flex;
}

.league-and-area {
  display: flex;
  place-items: center;
}

.area-name {
  margin-left: 5px;
}

.area {
  font-size: 13px;
  color: #6a6a6a;
  font-weight: 700;
}

.competition-name {
  font-size: 12px;
  font-weight: 500;
  color: grey;
}

.teams-container {
  display: grid;
  place-items: center;
  grid-template-columns: minmax(120px, 220px) auto minmax(120px, 220px);
  width: 70%;
}

.md_team_logo {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.team-name-home {
  font-size: 13px;
  color: #21447f;
  width: 100%;
  text-align: right;
}

.team-name-away {
  font-size: 13px;
  color: #21447f;
  width: 100%;
  text-align: left;
}

.teams-and-names-home {
  display: grid;
  grid-template-columns: minmax(100px, 200px) 70px;
  place-items: center;
}

.teams-and-names-away {
  display: grid;
  grid-template-columns: 70px minmax(100px, 200px);
  place-items: center;
}

.time-and-result-container {
  display: flex;
  flex-direction: column;
  place-items: center;
}

.start-time {
  font-size: 13px;
  font-weight: 500;
  color: #8d8d8d;
}

.versus {
  font-size: 14px;
  font-weight: 700;
  color: #8d8d8d;
}

.score {
  font-size: 13px;
  color: black;
  font-weight: 700;
}

.live {
  color: red;
  font-weight: 500;
}

.channels-section {
  display: grid;
  grid-template-columns: repeat(3,minmax(200px,210px));
  width: 100%;
}

.channels {
  display: flex;
  place-items: center;
  background-color: #e5e5e5;
  border-radius: 5px;
  padding: 7px;
  margin: 10px 3px 5px 0;
}

.country-flag-for-channel {
  width: 20px;
  height: 13px;
}

.country-name-and-ch-name {
  margin-left: 5px;
  color: #570057;
  font-weight: 700;
  padding-bottom: 2px;
}

@media only screen and (max-width: 426px) {

  .tv-for-web {
    display: none;
  }

  .tv-for-mobile {
    display: block;
  }

  .content-container {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    margin-bottom: 15px;
  }

  .league-container {
    display: flex;
    justify-content: space-between;
  }

  .country-flag {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }

  .league-and-match-container {
    display: flex;
    flex-direction: column;
  }

  .league-and-area {
    display: flex;
    place-items: center;
  }

  .area-name {
    margin-left: 5px;
  }

  .area {
    font-size: 13px;
    color: #6a6a6a;
    font-weight: 700;
  }

  .competition-name {
    font-size: 12px;
    font-weight: 500;
    color: grey;
  }

  .teams-container {
    display: grid;
    place-items: center;
    margin-top: 15px;
    grid-template-columns: minmax(120px, 180px) auto minmax(120px, 180px);
    width: 100%;
  }

  .team-name {
    font-size: 13px;
    color: #21447f;
  }

  .teams-and-names {
    display: grid;
    place-items: center;
  }

  .time-and-result-container {
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  .start-time {
    font-size: 13px;
    font-weight: 500;
    color: #8d8d8d;
  }

  .versus {
    font-size: 14px;
    font-weight: 700;
    color: #8d8d8d;
  }

  .score {
    font-size: 13px;
    color: black;
    font-weight: 700;
  }

  .live {
    color: red;
    font-weight: 500;
  }

  .channels {
    display: flex;
    place-items: center;
    background-color: #e5e5e5;
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 7px;
    margin-top: 10px;
  }

  .country-flag-for-channel {
    width: 20px;
    height: 13px;
  }

  .country-name-and-ch-name {
    margin-left: 5px;
    color: #570057;
    font-weight: 700;
    padding-bottom: 2px;
  }
}

</style>