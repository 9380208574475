<template>

  <div class="sr-lmt-state blink">
    <div class="sr-lmt-event-corner__wrapper">
      <div class="sr-lmt-corner-bottom__container srm-is-left">
        <div class="sr-lmt-corner-bottom__overlay" style="opacity: 1;"></div>
        <div class="sr-lmt-corner-bottom__grass-container" style="opacity: 0.08;">
          <svg preserveAspectRatio="xMidYMin slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 178"
               class="sr-lmt-corner-bottom__grass" style="fill: rgb(255, 255, 255);">
            <path
                d="M288 99V68.4L0 176.8v1.2h104.6M288 45V26L0 112.6V141M176.2 0H129L0 25.3V37M81.3 0H34L0 6v9M271 0h-47.3L0 50.6v15.8m288 73.4L212.4 178H288"></path>
          </svg>
        </div>
        <div class="sr-lmt-corner-bottom__overlay-shadow"></div>
        <div class="sr-lmt-corner-bottom__banner-container" style="opacity: 1;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 178" class="sr-lmt-corner-bottom__banner-svg"
               fill="#FFFFFF">
            <path d="M288 17.5L0 98.7V74.2l288-73"></path>
          </svg>
          <div class="sr-lmt-corner-bottom__banners-wrapper-left"><div class="sr-lmt-corner-bottom__banners sr-lmt-corner-bottom__banners-left srm-is-left"
            style="background-image: url(https://www.futbolverileri.com/img/img-label-fv@2x.6854ef3f.png);"></div></div>
        </div>
        <svg class="sr-lmt-corner-bottom__animation" viewBox="0 0 288 178">
          <path stroke-dashoffset="0" d="M260.6 28.1l27.7-8" class="sr-lmt-corner-bottom__animation-8"
                style="stroke: rgb(255, 255, 255);"></path>
          <g opacity="0.1">
            <path d="M270.7 64.6L260.6 60V28l10.1 4.3z" class="sr-lmt-corner-bottom__animation-5"
                  style="fill: rgb(255, 255, 255);"></path>
            <path d="M288 50.6L260.6 60V27.9l27.4-7.7z" class="sr-lmt-corner-bottom__animation-5"
                  style="fill: rgb(255, 255, 255);"></path>
            <path d="M288 27.2l-17.3 5.1-10.1-4.2 27.4-7.9z" class="sr-lmt-corner-bottom__animation-5"
                  style="fill: rgb(255, 255, 255);"></path>
          </g>
          <g class="sr-lmt-corner-bottom__opacity">
            <path stroke-dashoffset="0" d="M43.6 178.6l-16.4-26.9 261.4-93.5" class="sr-lmt-corner-bottom__animation-0"
                  style="stroke: rgb(255, 255, 255);"></path>
            <path stroke-dashoffset="0" d="M241.4 75.1l38.1 20.7 9.1-3.9" class="sr-lmt-corner-bottom__animation-1"
                  style="stroke: rgb(255, 255, 255);"></path>
            <path stroke-dashoffset="0" d="M161.9 103.5l86.8 66.8 39.9-20.8" class="sr-lmt-corner-bottom__animation-2"
                  style="stroke: rgb(255, 255, 255);"></path>
            <path stroke-dashoffset="0" d="M27.0 140.7l-9.3-3.4 9.3-4.2v18.5" class="sr-lmt-corner-bottom__animation-3"
                  style="stroke: rgb(255, 255, 255);"></path>
            <path stroke-dashoffset="0" d="M30.7 157.5h.6c3-.1 5.4-2.2 5.3-4.7 0-1.5-1-2.8-2.4-3.6"
                  class="sr-lmt-corner-bottom__animation-4" style="stroke: rgb(255, 255, 255);"></path>
          </g>
          <path stroke-dashoffset="0" class="sr-lmt-corner-bottom__animation-6" d="M270.7 32.3l-10.1-4.2V60l10.1 4.6"
                style="stroke: rgb(255, 255, 255);"></path>
          <path stroke-dashoffset="0" class="sr-lmt-corner-bottom__animation-7" d="M270.7 64.8V32.3l17.9-5.2"
                style="stroke: rgb(255, 255, 255);"></path>
        </svg>
      </div>

      <div class="sr-lmt-event-corner__ball-path srm-is-left">
        <div class="sr-lmt-ballpath__wrapper">
          <svg class="sr-lmt-svg" viewBox="0 0 568 352">
            <path class="sr-lmt-ballpath__ball-shade" opacity="0.2222222222222222"
                  d="M 49, 200 m -5, 0a 5, 2.5 0 1,0 10 , 0a 5 , 2.5 0 1,0 -10 , 0"></path>
            <line class="sr-lmt-ballpath__path-shade" x1="500" y1="307" x2="51" y2="201"
                  stroke-dashoffset="2.4556642641655344"
                  stroke-dasharray="466.244571013969,468.244571013969" mask=""></line>
            <path class="sr-lmt-ballpath__path" d="M 500 302 Q 270 15 50 180" stroke-dashoffset="2.78133046037226"
                  stroke-dasharray="0,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,0.2097852172585135,0,544.2097852172585"
                  fill="none" mask="" style="stroke: rgb(255, 255, 255);"></path>
            <circle class="sr-lmt-ballpath__ball" opacity="1" cx="51.42772674560547" cy="178.9344482421875" r="5"
                    style="fill: rgb(255, 255, 255);"></circle>
          </svg>
        </div>
      </div>

      <div class="corner-container blink">
        <div class="corner">
          <div class="corner-info">
            <div class="match-time">
              {{ selectedEvent.time }}'
            </div>
            <div class="font-size">
              <span style="font-weight: 500">{{i18n.corner}}</span> <br> <span
                class="team-name">{{ matchTeam.teams[selectedEvent.team].name.toUpperCase() }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
export default {
  name: "CornerAnimation",
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    i18n: Object
  },
}
</script>

<style scoped>

.sr-lmt-corner-bottom__banners {
  background-repeat: repeat-x;
  background-size: auto 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.sr-lmt-corner-bottom__banners-left {
  background-position: 100% 50%;
}

.sr-lmt-corner-bottom__banners-wrapper-left {
  height: 5%;
  position: absolute;
  right: 0;
  top: 5.1%;
  -webkit-transform: skewY(
      -15deg) translateY(-50%) perspective(387px) rotateY(
      14deg);
  transform: skewY(
      -15deg) translateY(-50%) perspective(387px) rotateY(
      14deg);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  width: 100%;
}

.aa {
  width: 100%;
  height: 100%;
}

.blink {
  animation: reveal .5s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(100% 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.match-situation-icon {
  width: 3.8rem;
  height: 3.8rem;
}

.corner-info {
  display: flex;
  place-items: center;
  padding: 0 8px;
  justify-content: space-between;
  background-color: white;
  border-radius: 1px;
  width: 170px;
  height: 50px;
}

.icon {
  display: flex;
  justify-content: center;
}

.match-time {
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 12px;
  min-width: 24px;
  padding: 0 4px;
  height: 24px;
  border-radius: 2px;
  color: white;
  background-color: #43c5cb;
}

.corner-container {
  bottom: 16px;
  left: 0;
  position: absolute;
  right: 0;
}

.corner {
  display: flex;
  justify-content: flex-end;
}

.font-size {
  font-size: 13px;
  font-weight: normal;
  text-align: right;
  min-width: 100px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-name {
  font-size: 10px;
}


.sr-lmt-corner-bottom__banner-container {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: opacity;
}

.sr-lmt-corner-bottom__animation {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.sr-bb * {
  box-sizing: border-box;
  min-height: auto;
  position: static;
  z-index: auto;
}

.sr-lmt-corner-bottom__animation-4 {
  stroke-dasharray: 13 15;
  stroke-linejoin: round;
  fill: none;
}

.sr-lmt-corner-bottom__animation-3 {
  stroke-dasharray: 39 41;
  stroke-linejoin: round;
  fill: none;
}

.sr-lmt-corner-bottom__animation-2 {
  stroke-dasharray: 208 210;
  stroke-miterlimit: 10;
  fill: none;
}

.sr-lmt-corner-bottom__animation-1 {
  stroke-dasharray: 105 107;
  stroke-miterlimit: 10;
  fill: none;
}

.sr-lmt-corner-bottom__animation-0 {
  stroke-dasharray: 310 312;
  stroke-miterlimit: 10;
  fill: none;
}

.sr-lmt-corner-bottom__opacity {
  opacity: .6;
}

.sr-lmt-corner-bottom__animation-7 {
  stroke-dasharray: 53 55;
  stroke-linejoin: round;
  stroke-width: 3;
  fill: none;
}

.sr-lmt-corner-bottom__animation-6 {
  stroke-dasharray: 56 58;
  stroke-linejoin: round;
  fill: none;
}

.sr-lmt-corner-bottom__overlay-shadow {
  background: radial-gradient(ellipse closest-corner at 50% 50%, transparent 0, rgba(0, 0, 0, .15) 100%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sr-lmt-corner-bottom__grass {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.sr-lmt-event-corner__wrapper {
  contain: content;
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}

.sr-lmt-corner-bottom__container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

sr-lmt-event-corner__ball-path srm-is-left .sr-lmt-corner-bottom__overlay {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: opacity;
}

.sr-bb svg:not(:root) {
  overflow: hidden;
}

.sr-bb * {
  -webkit-text-stroke: 1px transparent;
}

.sr-lmt-event-corner__ball-path.srm-is-left {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.sr-lmt-event-corner__ball-path {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.sr-lmt-ballpath__wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.sr-lmt-svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.sr-lmt-ballpath__ball-shade {
  fill: #1b1b1b;
  stroke: #1b1b1b;
}

.sr-lmt-ballpath__path-shade {
  stroke-width: 2;
  stroke-opacity: .15;
  stroke: #1b1b1b;
}

.sr-lmt-ballpath__path {
  stroke-width: 2;
}

.sr-lmt-corner-bottom__overlay {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: opacity;
}

.sr-lmt-state {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sr-lmt-corner-bottom__grass-container {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: opacity;
}

.sr-lmt-corner-bottom__overlay {
  /*background-color: #5c8301;*/
  background-color: #459153;
}


</style>