import axios from 'axios';
import * as dayjs from "dayjs";
//import {MatchesByLeagueResponse} from "./components/dto/MatchesByLeagueResponse";

//const api = axios.create(({
//    baseURL: 'https://fvscore-api-dot-fvscore-stage.appspot.com/'
//}));

const formatDate = (date) => dayjs(date).format("YYYY-MM-DD")

const api2 = axios.create(({
    baseURL: 'https://santra-api-dot-arv-sports-santra-00.ew.r.appspot.com/',
    headers: {
        'x-language': localStorage.getItem('language') || 'tr',
    }
}));

const api3 = axios.create(({
    baseURL: 'https://score.santra5.com/',
    headers: {
        'x-language': localStorage.getItem('language') || 'tr',
        'x-platform': 'FutbolVerileri'
    }
}));

const api4 = axios.create(({
    baseURL: 'https://live.santra5.com/',
    headers: {
        'x-language': localStorage.getItem('language') || 'tr',
        'x-platform': 'FutbolVerileri'
    }
}));

export const postTeamDetail = (teamData) => api3.post(`api/v5/football/team/save`,teamData);
export const postTeamDetailForLiveSantra = (teamData) => api4.post(`api/v5/football/team/save`,teamData);

//HOMEPAGE
export const fetchByLeague = (date) => api3.get('api/v5/sport/football/matches/byLeague', {
    params: {
        date: formatDate(date)
    }
});

export const fetchByTime = (date) => api3.get(`api/v5/sport/football/matches/byTime`, {
    params: {
        date: formatDate(date)
    }
});

//BET MODE
export const fetchBetModeByLeague = () => api3.get(`api/v5/bvscores/bet-mode/matches/byLeague`);
export const fetchBetModeByTime = () => api3.get(`api/v5/bvscores/bet-mode/matches/byTime`);

//BET ANALYZE
export const fetchBetByLeague = (date) => api3.get('api/v5/fvstats/stats365/leagues', {
    params: {
        date: formatDate(date),
    },
});

export const fetchBetByTime = (date) => api3.get('api/v5/fvstats/bet-matches/byTime', {
    params: {
        date: formatDate(date),
    },
});

//BET RADAR
export const fetchBetRadar = (per) => api3.get(`api/v5/betting/radar/matches?percent=${per}`);


//INSTANT MATCH DETAIL
export const fetchInstant = () => api3.get(`api/v5/sport/football/matches/byTime/instant`);

export const fetchBanner = () => api3.get(`api/v5/ads/single`, {
    params: {
        platform: 'FutbolVerileri',
        clientType: 'DESKTOP',
        country: 'Turkey',
        screen: 'FVHomeBanner'
    }
});

//LEAGUE DETAILS
export const fetchPopularLeagues = () => api3.get('api/v5/football/competition/popular');
export const fetchStandings = (id) => api3.get(`api/v5/football/competition/${id}/standings`);
export const fetchLeagueInfo = (id) => api3.get(`api/v5/football/competition/${id}/details`);
export const fetchCompetition = () => api3.get(`api/v5/football/competition`);
export const fetchMatches = (id) => api3.get(`api/v5/football/competition/${id}/results`);
export const fetchMatchesFixture = (id) => api3.get(`api/v5/football/competition/${id}/fixtures`);
export const fetchLeagueStats = (id,type) => api3.get(`api/v5/fvstats/stats365/season/${id}`, {params: {type}});

//MATCH DETAIL
export const fetchMatch = (id) => api3.get(`api/v5/sport/football/match/${id}/events`);
export const fetchMatchDetail = (id) => api3.get(`api/v5/sport/football/match/${id}`);
export const fetchMatchesHead = (id) => api3.get(`api/v5/sport/football/match/list?ids=${id}`);
export const fetchStats = (id) => api3.get(`api/v5/sport/football/match/${id}/stats`);
export const fetchH2h = (id) => api3.get(`api/v5/sport/football/match/${id}/h2h`);

//TEAM DETAIL
export const fetchTeam= (id) => api3.get(`api/v5/football/team/${id}`);
export const fetchTeamSeasonId = (id) => api3.get(`api/v5/football/team/${id}/seasons`);
export const fetchTeamResults = (paramsId) =>api3.get(`api/v5/football/team/${paramsId}/results`);
export const fetchTeamSeasons = (id) => api3.get(`api/v5/football/team/${id}/seasons`);
export const fetchTeamStandingsSeason = (id,seasonId) => api3.get(`api/v5/football/team/${id}/standings?ids=${seasonId}`);
export const fetchTeamStandings = (id) => api3.get(`api/v5/football/team/${id}/standings`);

//TEAM FIXTURES
export const fetchTeamFixtures = (id) => api3.get(`api/v5/football/team/${id}/fixtures`);

//MUlTI MATCH TRACKING
export const fetchMultiMatch = (date) => api3.get('api/v5/sport/football/matches/byTime', {
    params: {
        date: formatDate(date),
    },
});

//TV
export const fetchMatchesTv = () => api3.get(`api/v5/tv/matches`);

//NEWS
export const fetchNewsLists = () => api3.get(`api/v5/fvnews/recent`)

//UPDATE TEAM DETAIL
export const fetchTeamSearch = (ds) => api3.get(`api/v5/football/team/search/${ds}`);
export const teamSaveDetail = () => api3.post(`api/v5/football/team/save`);

//FORUM
export const fetchForum = (id,pagMod,size) => api2.get(`api/v5/forum/match/${id}/index/${pagMod - 1}/size/${size}`);

export default api3;
