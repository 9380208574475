<template>
  <div style="padding: 10px 20px">
    <div>

      <h1>
        General Terms of Use
      </h1>
      <h2>
        1&nbsp;Terms of Use
      </h2>
      <p>
        These Terms of Use (hereafter referred to as the ‘<strong>ToU</strong>’)
        constitute and govern the relationship between ‘<strong>Provider</strong>’
        (defined below) and all parties who access the Website and/or App (defined
        hereunder) and make use of its features and contents in any manner
        (hereafter referred to as ‘<strong>Visitors’</strong>).
      </p>
      <p>
        Collectively the Provider and the Visitors shall be referred to as the ‘<strong>Parties</strong>’.
      </p>
      <h2>
        2&nbsp;General
      </h2>
      <p>
        The use of any or all of the features and services offered by the Provider
        on FutbolVerileri .com website and its mobile applications (hereafter referred
        to as the ‘<strong>App</strong>’) and the information, materials and links
        contained therein, is subject to the ToU as set out below. Unless otherwise
        agreed by the Provider in writing, the ToU constitute the entire
        relationship between the Provider and the Visitor in its use of the App
        including any or all of its functions on offer by the App.
      </p>
      <p>
        The Visitor has the duty to read carefully and understand the ToU before
        using the App. A Visitor who has viewed the App is considered to have read,
        understood and agreed to be bound by the ToU, without the need for any
        further act.
      </p>
      <p>
        The Provider hereby reserves the right to suspend, add, end, amend and/or
        supplement these ToU from time to time as it may deem appropriate.
      </p>
      <p>
        The Provider recommends that the Visitor reads carefully the contents of
        these pages regularly. By using the App the Visitor agrees to be bound by
        the ToU, as well as by the latest modifications to them, regardless of
        whether in fact the Visitor is aware of such modifications.
      </p>
      <p>
        The Provider is under no obligation to verify that all Visitors use the App
        according to the last updated ToU. The effective version of ToU is that
        which is posted on the App.
      </p>
      <p>
        The App may only be used for lawful purposes. Use of the App for
        transmission, distribution, publication or storage of any material on or
        via the App which is in violation of any applicable law or regulation or
        any third party's rights is strictly prohibited. This includes (without
        limitation) the use of the App or the transmission, distribution,
        publication or storage of any material on or via the App in a matter or for
        a purpose which infringes copyright, trademark, trade secret or other
        intellectual property rights, is obscene or harmful to minors or
        constitutes an illegal act or harassment, is libellous or defamatory,
        violates any privacy or data protection laws, is fraudulent or breaches any
        exchange control or gambling law.
      </p>
      <p>
        In the event of misuse and/or the abuse of the App, the Provider reserves
        the right to close or block the Visitor from the App and close any account
        registered in the Visitor’s name. The Provider retains the right to bring a
        lawsuit against the Visitor and at its sole discretion.
      </p>
      <h2>
        3&nbsp;Services
      </h2>
      <p>
        The App provides an interactive web and mobile application/features
        containing live sports information in sporting events, sports scores in
        real time, final results, fixtures, line ups and sports statistics. The
        results, and other statistics information contained on the App, reflect
        information provided by other independent sources (from third parties) or
        by in-house effort or by various other official Apps. While every effort is
        made by the Provider to update the content and match results or other
        information displayed on the App regularly, we advise to double check
        information gathered on App also from other sources. The Provider is not
        responsible for the Visitor’s use of the results and other information
        contained on the App.
      </p>
      <h2>
        4&nbsp;Third party Websites and Apps
      </h2>
      <p>
        The Visitor acknowledges that any contact whatsoever made with third
        parties after viewing the App, whether intended or unintended, and any
        outcome which ensues, is absolutely independent of the Provider and the
        Provider is not in any way responsible for any agreement or expectation and
        other consequence which ensues as a direct or indirect cause of this
        contact.
      </p>
      <p>
        Any claim or dispute which may arise between the Visitor and such a third
        party shall in no way involve the Provider.
      </p>
      <p>
        Third parties, including any third parties advertising on the App do not
        have access to the Visitors’ Personal Data and any other data that the
        Visitor may have given to the Provider.
      </p>
      <h2>
        5&nbsp;A/V Content
      </h2>
      <p>
        The Provider is not responsible for the content of external Apps which may
        be viewed from the App. All video content found on the App is not hosted on
        the Provider’s servers nor is it created or uploaded to the host server by
        the Provider.
      </p>
      <h2>
        6&nbsp;Inactive Accounts
      </h2>
      <p>
        If a Visitor has set up an account on the App but fails to access it for 60
        days, the Provider reserves the right to close the account with immediate
        effect and without prior notice.
      </p>
      <h2>
        7&nbsp;Intellectual Property
      </h2>
      <p>
        Without prior authorisation in writing from the Provider, Visitors are not
        authorised to copy, modify, tamper with, distribute, transmit, display,
        reproduce, transfer, upload, download or otherwise use or alter any of the
        content of the App.
      </p>
      <p>
        FutbolVerileri  is a trademark of the Livesport s.r.o.. Use of the trademark and
        related marks is prohibited and all rights are reserved.
      </p>
      <p>
        Any breach of this clause may be tantamount to a violation of applicable
        intellectual property rights within the European Union and other applicable
        laws. The Provider and any other party authorised on its behalf reserves
        the right to seek damages to the fullest extent permitted by law against
        any party committing directly or indirectly this breach.
      </p>
      <h2>
        8&nbsp;Type of Relationship
      </h2>
      <p>
        These ToU are not intended to create any partnership, agency or joint
        venture between the Provider and the Visitor.
      </p>
      <h2>
        9&nbsp;Breach of ToU
      </h2>
      <p>
        If the Visitor fails to adhere to any clause in the ToU or if the Provider
        reasonably suspects that a Visitor whether directly or indirectly fails to
        comply with any clause in the ToU, the Provider reserves the right and all
        remedies at its disposition, and at its sole discretion, to close or block
        the Visitor from the App and close any account registered in the Visitor’s
        name and related to it and retains the right to bring a lawsuit against the
        Visitor at its sole discretion.
      </p>
      <h2>
        10&nbsp;Legal Compliance
      </h2>
      <p>
        Visitors are advised to comply with applicable legislation in the
        jurisdiction in which they are domiciled and/or resident and/or present.
        The Provider does not accept responsibility for any action taken by any
        authority against any Visitor in connection with their use of the App.
      </p>
      <h2>
        11&nbsp;Law &amp; Forum and/or community
      </h2>
      <p>
        These ToU shall be governed by and construed in accordance with the laws of
        the country of Malta without giving effect to conflicts of law principles.
        The Parties submit to the exclusive jurisdiction of the court of the
        country of Malta for the settlement of any disputes arising out of or
        concerning these ToU. These ToU will not be governed by the United Nations
        Convention on Contracts for the International Sale of Goods, the
        application of which is expressly excluded.
      </p>
      <h2>
        12&nbsp;Headings
      </h2>
      <p>
        Headings are intended for clarity and to facilitate reading of these ToU.
        They are not intended as a means of interpretation for the content of the
        paragraph that follows each heading. Headings are not intended to bind the
        Provider in any manner whatsoever.
      </p>
      <h2>
        13&nbsp;Waiver
      </h2>
      <p>
        Any waiver by the Provider of any breach by any Visitor of any provision of
        these ToU shall not be considered as a waiver of any subsequent breach of
        the same or any other provision of these ToU.
      </p>
      <h2>
        14&nbsp;Disclaimer
      </h2>
      <h2>
        14.1&nbsp;Warranties and Representations
      </h2>
      <p>
        It is hereby being specified that the Provider makes no representation,
        pledge or warranty (either explicit or implicit) that the content of the
        App is accurate and/or suitable for any particular purpose other than those
        warranties which cannot be expressly excluded under the governing law of
        these ToU.
      </p>
      <p>
        Use of the App is entirely at the Visitor’s risk. The App is not a gaming
        or gambling App. The Provider of the App does not provide gaming or
        gambling services; therefore it does not hold or control player funds and
        it is not involved in any gaming transactions. Betting odds which are
        displayed on the App are part of information and functions of the App.
      </p>
      <p>
        The Provider does not guarantee that:- any of the functions provided by the
        App are authorised, that the operation will fully satisfy the Visitor, that
        it is entirely secure and exempt from error, that it is updated regularly,
        that any software defect is regularly corrected, that it is uninterrupted,
        that the App are virus or bug free, that they are continually operational,
        that they are adequate, that the information and functions available
        thereon is reliable, or that all other information obtained and functions
        used on the App are adequate and reliable. Those who choose to access the
        Site do so on their own initiative and are responsible for compliance with
        local laws, if and to the extent local laws are applicable.
      </p>
      <p>
        The App may contain links and references to third party
        websites/apps/adverts/content. Those other websites/apps/adverts/content
        will be subject to separate terms and conditions, normally contained in
        those websites/apps/adverts/content. These are provided for the convenience
        and interest of the Visitor and does not imply responsibility for, nor
        approval of, information contained in these websites/apps/adverts/content
        by the Provider. The Provider gives no warranty, either expressed or
        implied, as to the accuracy, availability of content or information, text
        or graphics which are not under its domain. The Provider has not tested any
        software located on other websites/apps and does not make any
        representation as to the quality, safety, reliability or suitability of
        such software.
      </p>
      <h2>
        14.2&nbsp;Loss or Damage
      </h2>
      <p>
        The Provider is not responsible for any loss or damage, direct or indirect,
        that the Visitor or a third party might have suffered as a result of using
        the App, including but not limited to damages caused by a commercial loss,
        a loss of benefits, a loss on anticipated earnings, winnings or other
        profit, interruption of business, loss of commercial information, or any
        other pecuniary and or consecutive loss.
      </p>
      <p>
        The Provider is not responsible for winnings made or losses suffered on
        third party websites/apps which result from the use of information
        displayed on the App.
      </p>
      <p>
        Without limitation to the generality of the preceding two clauses, no
        responsibility is being acknowledged or accepted hereunder for, inter alia,
        the following matters:
      </p>
      <ol>
        <li>
          mistake(s), misprint(s), misinterpretation(s), mishearing(s),
          misreading(s), mistranslation(s), spelling mistake(s), fault(s) in
          reading, transaction error(s), technical hazard(s), registration
          error(s), manifest error(s), Force(s) Majeure and/or any other similar
          mistake(s)/error(s);
        </li>
        <li>
          violation of the Provider’s rules;
        </li>
        <li>
          criminal actions;
        </li>
        <li>
          advice, in whichever form, provided by the Provider;
        </li>
        <li>
          legal actions and/or other remedies;
        </li>
        <li>
          loss or damage that Visitors or third parties might have suffered as a
          result of their use of the App, its content or that of any link
          suggested by the Provider;
        </li>
        <li>
          loss or damage that Visitors or third parties might have suffered as a
          result of any modification, suspension or interruption of the App;
        </li>
        <li>
          criminal use of the App or of its content by any person, of a defect,
          or omission or of any other factor beyond the control of the Provider;
        </li>
        <li>
          any use made of the App due to a third party accessing the private
          areas requiring login and password by using a Visitor’s Username and
          Password;
        </li>
        <li>
          in case of discrepancies in the services, functions and any other
          feature offered by the App due to viruses or bugs as it relates to all
          parameters that make up the App, any damage, costs, expenses, losses,
          or claims brought about by said discrepancies;
        </li>
        <li>
          any act or omission by an internet provider or of any other third party
          with whom Visitors may have contracted in order to have access to the
          App. In case of litigation between the internet provider and Visitors,
          the Provider cannot be a party to the suit, and such suit shall in no
          way affect these ToU; and
        </li>
        <li>
          any claim arising as a result of damages incurred by a Visitor due to
          the content of any material posted by another Visitor or other third
          party not authorised by the Provider on the App.
        </li>
      </ol>
      <h2>
        15&nbsp;Privacy Policy
      </h2>
      <p>
        FutbolVerileri.com website and its mobile applications (herein referred to as
        the ‘App’) respects the privacy of all parties viewing and otherwise making
        use of the App. The App may collect and use
        ‘Personal Data’ (defined hereunder) relating to its Visitors in order to
        provide them with the services provided by the App and only for any purpose
        which has been expressly stated hereunder.
      </p>
      <p>
        This Privacy Policy complies with international Conventions, and EU laws
        including the General Data Protection Regulation (Regulation (EU)
        2016/679), as transposed into Maltese law in Data Protection Act (Chapter
        440 of the laws of Malta), the Processing of Personal Data (Electronic
        Communications Sector) Regulations (Legal Notice 16 of 2003 inclusive of
        the later amendments), it adopts Recommendation 2/2001 of the Article 29
        Data Protection Working Party, adopted on 17 May 2001, on certain minimum
        requirements for collecting personal data on-line and implements also any
        other applicable rules and practices.
      </p>
      <p>
        The Controller of data collected and used from Visitors of the App is
        Livesport s.r.o., Bucharova 2928/14a, 158 00 Prague, Czech Republic and is
        responsible for this App.
      </p>
      <p>
        The Data Controller can be contacted on support@fu.... .
      </p>
      <p>
        The Provider can be contacted on support@fu.... .
      </p>
      <h2>
        16&nbsp;Purpose of this Statement
      </h2>
      <p>
        The purpose of this Statement is to:
      </p>
      <ul>
        <li>
          set out the type of personal data the Controller will collect from you
          and how we will use your personal information;
        </li>
        <li>
          the basis on which any personal data is processed by the Controller;
        </li>
        <li>
          make you aware of how the Controller will handle your personal data;
        </li>
        <li>
          clarify the Controller’s obligations under the data protection
          regulations with regards to processing your personal data lawfully and
          responsibly; and
        </li>
        <li>
          inform you of your data protection rights.
        </li>
      </ul>
      <p>
        We process your personal data in an appropriate and lawful manner, in
        accordance with applicable data protection regulations and the General Data
        Protection Regulation EU 2016/679 (the “<strong>GDPR</strong>”) which is in
        force as of <strong>25 May 2018</strong>.
      </p>
      <h2>
        17&nbsp;Collection of Personal Data
      </h2>
      <p>
        Save where specifically indicated in this Privacy Policy, the App does not
        collect any Personal Data when Visitors simply browse the App. However, the
        App does require that Visitors supply some Personal Data when using
        additional or advanced services provided on the App after the registration.
        On these occasions the App will ask Visitors for their respective consent
        prior to the collection and use of the Visitors’ Personal Data. Upon
        registration or at other times the App may ask Visitors to submit their
        login name (an e-mail address typically) and password.
      </p>
      <p>
        Visitors are under no obligation to provide their Personal Data or to
        permit their Personal Data to be collected by the App. However, the App may
        not be able to provide the Visitors not consenting to the collection of
        their Personal Data with all services offered by the App.
      </p>
      <p>
        Although Visitors may have consented to the App using their respective
        Personal Data, they are entitled to subsequently revoke their respective
        consent for whatever reason by contacting support@fu.... . In case of
        revocation of consent, the additional or advance services provided on the
        App after registration will no longer be available.
      </p>
      <h2>
        18&nbsp;Your Rights as a Data Subject
      </h2>
      <p>
        18.1.1&nbsp;The Right of Access
      </p>
      <p>
        Visitors are entitled to request that the Controller (defined hereafter)
        provides them with written information on which of their respective
        Personal Data it has collected and/or used. A request can be made by
        submitting a request in writing to the Controller (defined hereafter).
      </p>
      <p>
        The App undertakes to make all reasonable efforts to keep the Personal Data
        collected updated. However, Visitors are invited to inform the App of any
        changes to their Personal Data which is held by the App.
      </p>
      <p>
        18.1.2&nbsp;Correction Blocking or Deletion of Data
      </p>
      <p>
        Visitors who consider that any of their respective Personal Data is
        inaccurate, may request the Controller in writing to correct the data.
        Visitors also have the right to request the Controller to block or delete
        their respective Personal Data if it has been processed unlawfully.
      </p>
      <p>
        18.1.3&nbsp;Right to object
      </p>
      <p>
        You may contact us at any time at support@fu.... to ask us not to
        process your Personal Data for marketing purposes e.g. receiving
        information from us about upcoming events, newsletters and publications and
        your data will no longer be processed for such purposes.
      </p>
      <p>
        18.1.4&nbsp;Right to withdraw consent
      </p>
      <p>
        You have the right to withdraw your consent to this
        statement, and the processing practices described herein, at any time by
        sending an email to support@fu.... . This will not affect the lawfulness
        of processing which we carried out on the basis of such consent before its
        withdrawal. Withdrawal of consent will result in us having to terminate our
        services immediately.
      </p>
      <p>
        18.1.5&nbsp;Right to rectification
      </p>
      <p>
        You have the right to obtain rectification of any inaccurate Personal Data
        about you that we have processed, update any data which is out-of-date and
        the right to have incomplete Personal Data completed, including by means of
        a supplementary statement.
      </p>
      <p>
        18.1.6&nbsp;Right to erasure
      </p>
      <p>
        You have the right to obtain the erasure of Personal Data we have
        concerning you when your personal data is no longer required where:
      </p>
      <ul>
        <li>
          you withdraw your consent to us processing your Personal Data;
        </li>
        <li>
          your Personal Data no longer needs to be processed; or
        </li>
        <li>
          your Personal Data has been unlawfully processed.
        </li>
      </ul>
      <p>
        18.1.7&nbsp;Right to Restriction of Processing
      </p>
      <p>
        You have the right to restrict our processing activities where:
      </p>
      <ul>
        <li>
          you consent the accuracy of this Personal Data, for a period enabling
          us to verify the accuracy of the same Personal Data;
        </li>
        <li>
          our processing is deemed unlawful, and you oppose the erasure of your
          Personal Data and request restriction of its use instead;
        </li>
        <li>
          we no longer need your Personal Data for the purposes stated herein,
          but you require it for the establishment, exercising or defending of
          legal claims; or
        </li>
        <li>
          you have objected to our processing pending the verification whether
          the legitimate grounds of our processing activities overrode those
          pertaining to you.
        </li>
      </ul>
      <p>
        18.1.8&nbsp;Right of Data Portability
      </p>
      <p>
        As from <strong>25 May 2018</strong>, you shall have the right to receive
        your Personal Data in a structured and machine-readable format and transmit
        this data to another Controller (as defined in the GDPR).
      </p>
      <h2>
        19&nbsp;Purposes for the collection and use of Personal Data
      </h2>
      <p>
        The Personal Data collected by the App shall be processed in accordance
        with the provisions of the Data Protection Act (Chapter 440 of the Laws of
        Malta) and subsidiary legislation enacted thereunder and solely processed
        for the purposes of:
      </p>
      <ol>
        <li>
          Communicating with the Visitors;
        </li>
        <li>
          Sending Visitors new password to their respective personal account;
        </li>
        <li>
          Providing any advanced services which are possible to configure App to
          some extent;
        </li>
        <li>
          Improving the content offered by the App;
        </li>
        <li>
          Providing Visitors with personalised App content and/or layout.
        </li>
      </ol>
      <h2>
        20&nbsp;Legal basis for processing
      </h2>
      <p>
        We shall only process your Personal Data where you have provided your
        consent or insofar as this is necessary for us to be able to provide the
        services we offer and/or for the purposes indicated in this statement.
      </p>
      <p>
        We may also process your Personal Data on the basis of any legitimate
        interest or in order to comply with any legal obligations at law. This may
        include the exercise of defense of legal claims or in order to comply with
        an order of any court, tribunal or authority.
      </p>
      <h2>
        21&nbsp;Marketing
      </h2>
      <p>
        You will receive marketing communication from us if you have requested such
        marketing information from us by providing us with your details through
        this App and have opted-in to receiving such information.
      </p>
      <p>
        We will not share your Personal Data with any third party for marketing
        purposes without your unambiguous consent.
      </p>
      <h2>
        22&nbsp;Disclosure of Personal Data to third parties
      </h2>
      <p>
        The Provider does not sell, trade or rent or otherwise disclose Personal
        Data appertaining to Visitors to any third party without their prior
        respective consent. However Personal Data would be disclosed to third
        parties in the eventuality of a sale of the App.
      </p>
      <p>
        The above is without prejudice to any legal obligation incumbent on the App
        to disclose Visitors’ Personal Data to third parties.
      </p>
      <p>
        The above is also without prejudice to disclosures which are absolutely
        necessary as part of one or more of the Purposes for collecting and using
        Personal Data. In this case the App shall seek the prior express consent of
        the Visitors concerned.
      </p>
      <p>
        The Provider reserves the right to provide statistics about Visitors,
        sales, traffic, and other statistical information relating to the App to
        third parties, however, without identifying any particular Visitor.
      </p>
      <h2>
        23&nbsp;Log Files
      </h2>
      <p>
        In order to better administer the App and to collect broad demographic
        information of Visitors for aggregate use, the App automatically logs the
        IP address of all Visitors and the pages viewed by each Visitor
        respectively.
      </p>
      <h2>
        24&nbsp;Cookie Disclaimer
      </h2>
      <p>
        A "cookie" is information stored on a Visitor’s computer by a web server
        and used to customise their web service. The App uses cookies to store
        information about Visitors’ interactions that may be needed later to
        perform a function. Visitors can choose to disable cookies in browser
        settings. We use cookies to personalise content and ads, to provide social
        media features and to analyse our traffic. We also share information about
        your use of our site with our App analytics partners, some advertising
        partners (programmatic) and social media (only when you log in via your
        social account). See details <a href="">here</a>.
      </p>
      <p>
        This statement should be read in conjunction with our <a href="privacy/storage">Cookie Policy</a> and any
        other <strong>Privacy Notice</strong> we may provide on specific occasions
        when we are collecting or processing Personal Data about you so that you
        are fully aware of how and why we are using your Personal Data.
      </p>
      <h2>
        25&nbsp;Transfers of Personal Data to Third Countries
      </h2>
      <p>
        The Controller does not transfer any Personal Data outside the European
        Economic Area and, if it is required to, it will first ensure that there
        are appropriate safeguards in place to ensure that your Personal Data is
        adequately protected.
      </p>
      <h2>
        26&nbsp;Security
      </h2>
      <p>
        The App and the Controller have adopted various measures, both technical
        and organisational, to help protect communication against the destruction,
        loss, misuse and alteration of Personal Data which has been collected and
        used (including ensuring that any transfers of data are secured) through
        Secured communication between Visitor's device and Provider's servers
        “HTTPS”. Additionally, all passwords you set up are stored using BCrypt
        standard. Notwithstanding these efforts, the Provider cannot guarantee that
        such event will not occur.
      </p>
      <h2>
        27&nbsp;The period for which Personal Data is kept
      </h2>
      <p>
        The Personal Data is kept only for the time period required to meet the
        purposes for which it was collected. In case of user account inactivity for
        one (1) year, all collected user Personal Data will be deleted.
      </p>
      <h2>
        28&nbsp;Third party websites/apps
      </h2>
      <p>
        Third party websites/apps are not covered by this Privacy Policy. The App
        provides links to other sites for the convenience of Visitors. The App is
        not responsible for the contents displayed on third party websites/apps and
        measures they adopt to protect your privacy. Any other websites/apps which
        Visitors access from the App may not have an adequate Privacy Policy.
      </p>
      <h2>
        29&nbsp;Acceptance of the Privacy Policy
      </h2>
      <p>
        The Visitors’ consent to the collection and use of their respective
        Personal Data by the App and the Controller is made subject to the Privacy
        Policy set out above. Visitors are advised to view the Privacy Policy on a
        regular basis in order to become familiar with the terms and clauses
        therein and with any amendments which from time to time may be implemented
        by the App.
      </p>
      <h2>
        30&nbsp;Provider
      </h2>
      <p>
        The Provider is Livesport Media Ltd a company duly registered under the
        laws of Malta on the 22nd November 2011 with registration number C 54555,
        and having its registered address at CMS House, First Floor, St. Peter's
        Street, San Gwann, SGN 2310, Malta. To contact us, please email us at support@fu.... .
      </p>
      <h2>
        31&nbsp;Version and Date of ToU
      </h2>
      <p>
        This statement in version 1 was last updated in 25.5.2018
        (FS.COM-ToU-20180525-1)
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "Privacy"
}
</script>

<style scoped>

</style>