<template>
  <div class="const_width">
    <div @click="toNewsDetail(news.id)" v-for="(news,i) in this.newsList" :key="i" class="content_container">
      <div class="news-general-container">
        <div class="news-img-container">
          <v-img class="news-img" :src="`${news.imageUrl}`"/>
        </div>

        <div class="news-title-and-subtitle-container">
          <span class="news-title">{{news.title}}</span>
          <span class="subtitle">{{news.subTitle}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchNewsLists} from "../../api";

export default {
  name: "NewsList",
  data() {
    return {
      newsList : null
    }
  },
  mounted() {
    this.fetchNewsLists();
  },
  methods: {
    async fetchNewsLists() {
      const data = await fetchNewsLists();
      this.newsList = data.data.data;
      console.log("newslist", this.list)
    },
    toNewsDetail(NewsId) {
        let routeData = this.$router.resolve({name: 'NewsView', params: {id: NewsId}});
        window.open(routeData.href, '_blank');
    }
  }
}
</script>

<style scoped>

.const_width {
  display: block;
  width: 100%;
}

.content_container {
  width: 60%;
  padding: 10px;
  margin: 10px 0;
}

.news-general-container {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.news-general-container:hover :not(:last-child){
    text-decoration: underline;
}

.news-img {
  width: 10rem;
  height: 7rem;
  border-radius: 10px;
}

.news-title-and-subtitle-container {
  display: flex;
  flex-direction: column;
  margin: auto 0 auto 1rem;
}

.news-title {
  font-size: 1.2rem;
}

.subtitle {
  font-size: 0.80rem;
  font-weight: normal;
}

@media only screen and (max-width: 650px) {
  .content_container {
    width: 100%;
  }

  .news-img {
    width: 8rem;
    height: 5rem;
    border-radius: 10px;
  }

  .news-title {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .subtitle {
    display: none;
  }
}

</style>