import Vue from 'vue';
import Vuex from 'vuex';
import api3 from "./api";
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('user-token') || '',
        user: null,
        ls_fav_games: JSON.parse(localStorage.getItem("fav_games")) || [],
        tv_fav_games: JSON.parse(localStorage.getItem("tv_matches")) || [],
        bm_fav_games: JSON.parse(localStorage.getItem("bm_fav_games")) || [],
        location: null,
        selectItems: null
    },
    getters: {
        user: state => state.user,
        token: state => state.token,
        ls_fav_games: state => state.ls_fav_games,
        tv_fav_games: state => state.tv_fav_games,
        bm_fav_games: state => state.bm_fav_games,
        location: state => state.location,
        selectItems: state => state.selectItems,
        vip: state => state.user?.details?.vip
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },

        SET_USER: (state, data) => {
            state.user = data;
        },

        SET_LS_FAV_GAMES: (state, value) => {
            state.ls_fav_games = value;
        },

        SET_TV_FAV_GAMES: (state, value) => {
            state.tv_fav_games = value;
        },

        SET_BM_FAV_GAMES: (state, value) => {
            state.bm_fav_games = value;
        },

        SET_LOCATION: (state, value) => {
            state.location = value;
        },

        SET_SELECT_ITEMS: (state, data) => {
            state.selectItems = data;
        },
    },
    actions: {
        set_fav_games({commit}, favGames) {
            commit("SET_LS_FAV_GAMES", favGames);
            localStorage.setItem("fav_games", JSON.stringify(favGames));
        },

        set_Tv_fav_games({commit}, favGames) {
            commit("SET_TV_FAV_GAMES", favGames);
            localStorage.setItem("tv_matches", JSON.stringify(favGames));
        },

        set_bm_fav_games({commit}, favGames) {
            commit("SET_BM_FAV_GAMES", favGames);
            localStorage.setItem("bm_fav_games", JSON.stringify(favGames));
        },

        set_live_score_fav_status({state, dispatch}, match) {
            if (state.ls_fav_games.find(item => item.id === match.id)) {
                let favs = state.ls_fav_games.filter(item => item.id !== match.id);
                dispatch("set_fav_games", favs);
            } else {
                let favs = state.ls_fav_games;
                favs.push(match);
                dispatch("set_fav_games", favs);
            }
        },

        set_Tv_fav_status({state, dispatch}, match) {
            if (state.tv_fav_games.find(item => item.id === match.id)) {
                let favs = state.tv_fav_games.filter(item => item.id !== match.id);
                dispatch("set_Tv_fav_games", favs);
            } else {
                let favs = state.tv_fav_games;
                favs.push(match);
                dispatch("set_Tv_fav_games", favs);
            }
        },

        set_bet_mode_fav_status({state, dispatch}, match) {
            if (state.bm_fav_games.find(item => item.id === match.id)) {
                let favs = state.bm_fav_games.filter(item => item.id !== match.id);
                dispatch("set_bm_fav_games", favs);
            } else {
                let favs = state.bm_fav_games;
                favs.push(match);
                dispatch("set_bm_fav_games", favs);
            }
        },

        auth_request: ({commit, dispatch}, auth) => {
            return new Promise((resolve, reject) => {
                api3.post('api/v5/user/login', auth,
                    {
                        headers: {
                            Platform: 'FutbolVerileri'
                        }
                    }
                ).then((data) => {
                    if (data.data.success) {
                        const token = data.data.data.token;
                        commit("SET_TOKEN", token);
                        localStorage.setItem("user-token", token);
                        dispatch('fetch_user_info');
                    }
                    resolve(data);
                })
                    .catch(error => {
                        reject(error);
                    })
            });
        },

        logout: ({commit}) => {
            delete api3.defaults.headers.common['Authorization'];
            localStorage.removeItem('user-token');
            commit("SET_USER", null);
        },

        fetch_user_info: ({commit, state, dispatch}) => {
            return new Promise((resolve, reject) => {
                if (state.token !== '' || state.token) {
                    api3.get('api/v5/user/me', {
                        headers:
                            {
                                Authorization: `Bearer ${state.token}`,
                                Platform: 'FutbolVerileri'
                            }
                    }).then((data) => {
                        if (data.data.success) {
                            commit("SET_USER", data.data.data);
                        } else {
                            dispatch('logout');
                        }
                        resolve(data);
                    }).catch((error) => {
                        reject(error);
                    })
                } else {
                    reject(false);
                }
            })
        },

        get_location: ({commit}) => {
            return new Promise((resolve, reject) => {
                axios.get('https://freegeoip.app/json/')
                    .then((response) => {
                        if (response.data) {
                            commit("SET_LOCATION", response.data)
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    }).catch(() => {
                    reject(false);
                });
            })
        },
        fetch_select_items: ({commit}) => {
            return new Promise((resolve, reject) => {
                api3.get('api/v5/fvtips/markets')
                    .then((data) => {
                        if (data.data.success) {
                            commit("SET_SELECT_ITEMS", data.data.data);
                            resolve(data);
                        }
                    }).catch((error) => {
                    reject(error);
                })
            })
        },
    }
});

