<template>
  <v-col cols="12" md="6">
    <div class="tipster-card">
      <div class="tipster-card--title">
        <span>{{ tip.eventName }}</span>
      </div>
      <div class="tipster-card--subtitle">
        <span class="text-muted">{{ tip.tournamentName }}</span>
      </div>
      <!--            <div class="tipster-card&#45;&#45;subtitle">
                      <span class="text-muted">{{fixDate(tip.eventDate)}}</span>
                  </div>-->
      <div class="stake-container">
        <span class="stake-and-single-or-double">{{ tip.type }} @ {{tip.odds}}</span>
        <span class="stake-and-single-or-double">Stake: {{ tip.stake }}</span>
      </div>
      <div class="tipster-card--diff-bg">
        <v-row v-for="s in tip.bets" :key="s.id">
          <v-col class="py-1">
            <span class="tipster-card--subtitle">Seçim:</span><br>
            <div class="w-100 tipster-card--title">
              <span class="mx-auto">{{ s.selection }}</span>
            </div>
          </v-col>
          <v-col class="py-1">
            <span class="tipster-card--subtitle">Oran</span><br>
            <div class="w-100 tipster-card--title">
              <span class="mx-auto">{{ s.odds }}</span>
            </div>
          </v-col>
          <v-col class="py-1">
            <span class="tipster-card--subtitle">Karşılaşma</span><br>
            <div class="w-100 tipster-card--title">
              <span class="mx-auto">{{ s.event }}</span>
            </div>
          </v-col>
          <!--          <v-col class="py-1">
                      <span class="tipster-card&#45;&#45;subtitle">Güven</span><br>
                      <div class="w-100 tipster-card&#45;&#45;title">
                        <span class="mx-auto">{{ s.stake }}</span>
                      </div>
                    </v-col>-->
          <v-col class="py-1">
            <span class="tipster-card--subtitle">Durum</span><br>
            <div class="w-100 tipster-card--title">
              <img class="icon-size" alt="" v-if="s.status === 'Waiting'" src="../../assets/back-arrow.png">
              <img class="icon-size" alt="" v-if="s.status === 'Lost'" src="../../assets/Lost@3x.png">
              <img class="icon-size" alt="" v-if="s.status === 'Won'" src="../../assets/Won@3x.png">
            </div>
          </v-col>
        </v-row>
      </div>
      <v-list dense class="py-0">
        <v-list-item>
          <v-list-item-avatar class="my-0 mr-2">
            <v-img src="@/assets/logo-avatar.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-title>{{ tip.user.username }}</v-list-item-title>
          <v-list-item-action class="my-0" v-if="tip.comment !== ''">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="accent" v-on="on">comment</v-icon>
              </template>
              <span>{{ tip.comment }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </v-col>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TipCard",
  props: ['tip'],
  methods: {
    fixDate(value) {
      dayjs.locale('tr');
      return dayjs(value).format("DD MMMM, hh:mm");
    }
  }
}
</script>

<style scoped>

.tipster-card {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 5px 0 5px 0;
}

.tipster-card--title {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: rgba(12, 33, 57, 1);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tipster-card--subtitle {
  font-size: 14px;
  color: #6c757d;
  text-align: center;
}

.tipster-card--diff-bg {
  text-align: center;
  background-color: rgba(237, 243, 245, 1);
  margin: 5px 0 20px 0;
  padding: 0 15px;
}

.stake-container {
  display: flex;
  padding: 5px 10px 10px 10px;
  place-items: center;
  justify-content: space-between;
}

.stake-and-single-or-double {
  font-size: 14px;
}

.icon-size {
  width: 20px;
  height: 20px;
}

</style>
