<template>
  <div v-if="lineups">
    <h2 class="fv_title">Kadrolar</h2>

    <div class="lineups_container">
      <div class="team_lineup_container" v-if="teams">
        <div class="lineups_team_header">
          <v-img :src="`https://arvsports.com/cdn/images/teams/${teams.homeId}.png`"
                 class="lineups_team_logo"/>
          <div class="lineups_team_name">
            {{ teams.home }}
          </div>
        </div>
        <div class="lineups_sub_header _header _accent--text">İlk 11</div>
        <div
            class="lineups_row"
            v-for="(p,pi) in lineups.startingLineupHome"
            :key="`htl-${pi}`"
        >
          <div class="lineups_jersey_num">{{ p.playerJerseyNumber }}</div>
          <div class="lineups_player_name">{{ p.playerName }}</div>
          <div class="lineups_player_position">{{ POSITION_MAP[p.playerType] }}</div>
        </div>
        <div class="lineups_sub_header _header _accent--text">Yedekler</div>
        <div
            class="lineups_row"
            v-for="(p,pi) in lineups.substitutesHome"
            :key="`atls-${pi}`"
        >
          <div class="lineups_jersey_num">{{ p.playerJerseyNumber }}</div>
          <div class="lineups_player_name">{{ p.playerName }}</div>
          <div class="lineups_player_position">{{ POSITION_MAP[p.playerType] }}</div>
        </div>
      </div>
      <div class="team_lineup_container" v-if="teams">
        <div class="lineups_team_header">
          <v-img :src="`https://arvsports.com/cdn/images/teams/${teams.awayId}.png`"
                 class="lineups_team_logo"/>
          <div class="lineups_team_name">
            {{ teams.away }}
          </div>
        </div>
        <div class="lineups_sub_header _header _accent--text">İlk 11</div>
        <div
            class="lineups_row"
            v-for="(p,pi) in lineups.startingLineupAway"
            :key="`atl-${pi}`"
        >
          <div class="lineups_jersey_num">{{ p.playerJerseyNumber }}</div>
          <div class="lineups_player_name">{{ p.playerName }}</div>
          <div class="lineups_player_position">{{ POSITION_MAP[p.playerType] }}</div>
        </div>
        <div class="lineups_sub_header _header _accent--text">Yedekler</div>
        <div
            class="lineups_row"
            v-for="(p,pi) in lineups.substitutesAway"
            :key="`atls-${pi}`"
        >
          <div class="lineups_jersey_num">{{ p.playerJerseyNumber }}</div>
          <div class="lineups_player_name">{{ p.playerName }}</div>
          <div class="lineups_player_position">{{ POSITION_MAP[p.playerType] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import api from "../../api";

export default {
  name: "Lineups",
  data() {
    return {
      lineups: null,
      POSITION_MAP
    }
  },
  //mounted() {
  //    this.fetchLineups();
  //},
  //methods: {
  //    async fetchLineups() {
  //        const data = await api.get(`api/fvscore/match/${this.$route.params.id}/lineups`);
  //        this.lineups = data.data.data;
  //    }
  //},
  computed: {
    teams() {
      if (this.$parent.$data.match) {
        return {
          home: this.$parent.$data.match.homeTeam,
          homeId: this.$parent.$data.match.homeTeamId,
          away: this.$parent.$data.match.awayTeam,
          awayId: this.$parent.$data.match.awayTeamId,
        }
      } else {
        return null;
      }
    }
  },
}

const POSITION_MAP = {
  "goalkeeper": "Kaleci",
  "defender": "Defans",
  "midfielder": "Orta Saha",
  "forward": "Forvet",
};
</script>

<style scoped>

.team_lineup_container {
  padding: 10px;
}

.lineups_team_header {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 40px 30px;
}

.lineups_team_logo {
  margin: 0 auto;
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
}

.lineups_team_name {
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineups_sub_header {
  line-height: 24px;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}

.lineups_row {
  display: grid;
  line-height: 23px;
  border-bottom: 1px solid #edf2f4;
  border-left: 1px solid #edf2f4;
  border-right: 1px solid #edf2f4;
  grid-template-columns: 30px auto 70px;
}

.lineups_player_name {
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineups_player_position {
  text-align: center;
  color: #b2b2b2;
}

.lineups_jersey_num {
  text-align: center;
  border-right: 1px solid #edf2f4;
}

@media only screen and (min-width: 750px) {
  .lineups_container {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
</style>
