<template>
  <div>
    <div class="filter-bar">
      <div class="filter-bar-container text-right">
        <!--<v-btn small outlined color="#e20432" class="text-capitalize">
            Kazanma oranı azalan
            <v-icon right>
                arrow_drop_down
            </v-icon>
        </v-btn>-->
      </div>
    </div>
    <v-container>
      <div class="content-container">
        <LoadingBar v-show="loading"/>
        <v-col v-if="!loading">
          <div class="w-100 text-center">
            <div></div>
            <span v-if="tips || tips.length === 0" class="text-muted"><b>{{ tips.length }}</b>&nbsp;<span class="result-text">Sonuç bulundu</span></span>
            <span v-else class="text-muted">Sonuç bulunamadı!</span>
            <div @click="addPred()" class="add-prediction-button">
              <span>Tahmin Ekle</span>
            </div>
          </div>
          <v-row class="cards-layout" v-if="tips">
            <tip-card v-for="t in tips" :key="t.id" :tip="t"/>
          </v-row>
        </v-col>
      </div>
    </v-container>
  </div>
</template>

<script>
import api3 from "../../api";
import 'dayjs/locale/tr'
import LoadingBar from "./LoadingBar";
import TipCard from "./TipCard";

export default {
  name: "Tips",
  components: {TipCard, LoadingBar},
  data: () => ({
    tips: null,
    loading: true,
  }),
  mounted() {
    this.fetchTips();
  },
  methods: {
    async fetchTips() {
      const data = await api3.get("api/v5/whotips/tips");
      this.tips = data.data.data;
      this.loading = false;
    },
    addPred() {
      return this.$router.push('addPrediction')
    }
  }
}
</script>

<style scoped>

@media only screen and (max-width: 960px) {

}

.content-container {
  font-size: 14px;
}

.text-center {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.add-prediction-button {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: dodgerblue;
}

.add-prediction-button:hover {
  background-color: #60a0ff;
}

.text-muted {
  font-size: 16px;
  color: #454545;
}

.result-text {
  color: #727272;
}

.cards-layout {
  display: grid;
  flex-direction: column;
  place-items: center;
}

</style>
