<template>
  <div v-if="!loading" class="banner-wrapper">
    <div class="outer-banner-blur-bg" @click="redirect">
      <div
          class="banner-blur-bg"
          v-bind:style="{ 'background-image': 'url(' + ad.src + ')' }"
      />
    </div>

  </div>
  <v-skeleton-loader
      v-else
      class="skeleton-loader"
      type="image"
  ></v-skeleton-loader>
</template>

<script>
import {fetchBanner} from "../../api";

export default {
  name: "BannerArea",
  data() {
    return {
      ad: null,
      loading: true
    }
  },
  mounted() {
    this.fetchAd();
  },
  methods: {
    async fetchAd() {
      const data = await fetchBanner();
      this.ad = data.data.data;
      this.loading = false;
    },
    redirect() {
      const win = window.open(this.ad.target, '_blank');
      win.focus();
      this.$ga.event({
        eventCategory: 'WebHomeBilyoner',
        eventAction: 'click',
      });
    }
  },
}
</script>

<style>
.banner-wrapper {
  width: 100%;
  height: 310px;
  display: flex;
  justify-content: flex-start;
}

.outer-banner-blur-bg {
  width: 100%;
  border-radius: 4px;
  top: 10px;
  cursor: pointer;
}

.banner-blur-bg {
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.v-skeleton-loader__image {
  height: 274px;
}

.b-image {
  border-radius: 4px;
  margin-top: auto;
}

.b-container {
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
}

.skeleton-loader {
  margin-top: 10px;
  height: 300px !important;
}

.v-skeleton-loader__image {
  height: 300px !important;
}

@media only screen and (max-width: 426px) {
  .banner-wrapper {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: flex-start;
  }

  .banner-blur-bg {
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
  }
}
</style>
