<template>
    <div class="twitter_timeline_container content_container mb-3">
        <a class="twitter-timeline" data-height="600"
           href="https://twitter.com/futbolverileri?ref_src=twsrc%5Etfw"></a>
    </div>
</template>
<script>
    export default {
        name: "TwitterTimeline",
        data() {
            return {
                twitter_script: document.createElement('script'),
            }
        },
        mounted() {
            this.appendTwitterTimeline();
        },
        methods: {
            async appendTwitterTimeline() {
                await this.twitter_script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
                document.head.appendChild(this.twitter_script);
            }
        },
        beforeDestroy() {
            document.head.removeChild(this.twitter_script);
        }
    }
</script>

<style scoped>
.twitter_timeline_container{
    min-height: 600px;
}
</style>
