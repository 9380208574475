<template>
  <div class="position-container">

    <div class="shot-on-target-section">
      <div class="away-icon" v-if="selectedEvent.team === 'away'">
        <v-img src="@/assets/lmt/shotOnTarget.svg" class="match-situation-icon img-reverse" contain/>
      </div>

      <div class="shot-on-target-container">
        <div class="shot-on-target">
          <div class="shoton-target-info">
            <div class="match-time">
              {{ selectedEvent.time }}'
            </div>
            <div class="font-size">
              <span style="font-weight: 500">{{i18n.shotOnTarget}}</span> <br> <span class="team-name">{{ matchTeam.teams[selectedEvent.team].name.toUpperCase() }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="home-icon" v-if="selectedEvent.team === 'home'">
        <v-img src="@/assets/lmt/shotOnTarget.svg" class="match-situation-icon" contain/>
      </div>
    </div>

<!--    <div class="shot-on-target-section">
      <div class="shot-on-target-section-v2">
        <div class="shot-on-target-image-container">
          <v-img src="@/assets/lmt/shotOnTarget.svg" class="shot-on-target-img" contain/>
        </div>
        <div class="shot-on-target-text-container">
          <span class="shot-on-target-text">{{ i18n.shotOnTarget }}</span>
        </div>
        <div class="shot-on-target-team-container">
          <span class="team-text">{{ matchTeam.teams[selectedEvent.team].name.toUpperCase() }}</span>
          <span class="empty-span"></span>
        </div>
      </div>
    </div>-->

  </div>
</template>

<script>
export default {
  name: "ShotOnTarget",
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    i18n: Object
  },
}
</script>

<style scoped>

.blink {
  animation: reveal .5s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(100% 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.img-reverse {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.shot-on-target-section {
  display: flex;
  place-items: center;
}

.shoton-target-info {
  display: flex;
  place-items: center;
  padding: 0 8px;
  justify-content: space-between;
  background-color: white;
  border-radius: 1px;
  width: 170px;
  height: 40px;
}

.match-situation-icon {
  width: 3.8rem;
  height: 3.8rem;
}

.away-icon {
  position: absolute;
  display: flex;
  place-items: center;
  height: 100%;
  left: 0;
}

.home-icon {
  position: absolute;
  display: flex;
  place-items: center;
  height: 100%;
  right: 0;
}

.match-time {
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 12px;
  min-width: 24px;
  padding: 0 4px;
  height: 24px;
  border-radius: 2px;
  color: white;
  background-color: #034586;
}

.font-size {
  font-size: 13px;
  font-weight: normal;
  text-align: right;
  min-width: 100px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-name {
  font-size: 10px;
}

.shot-on-target-container {
  position: absolute;
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.shot-on-target {
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: space-between;
  min-width: 25%;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

/* New Mackolik Style */

.shot-on-target-section {
  position: absolute;
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
}

.shot-on-target-section-v2 {
  display: flex;
  place-items: center;
  flex-direction: column;
  width: 10rem;
}

.shot-on-target-text-container {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  background: #333;
  width: 100%;
  height: 100%;
}

.shot-on-target-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}

.shot-on-target-team-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 5px solid blue;
}

.shot-on-target-image-container {
  background: black;
  opacity: .5;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.team-text {
  z-index: 2;
  color: white;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  padding: 0.3rem;
  align-content: center;
  height: 100%;
  display: flex;
  place-items: center;
  width: 100%;
  justify-content: center;
}

.empty-span {
  background: black;
  opacity: 0.5;
  width: 100%;
  height: 1.5rem;
  padding: 1.3rem;
}

.shot-on-target-img {
  width: 4.2rem;
  height: 4.2rem;
}

/**/

@media only screen and (max-width: 1264px) {
  .match-situation-icon {
    width: 3.5rem;
    height: 3.5rem;
  }
}

@media only screen and (max-width: 960px) {
  .match-situation-icon {
    width: 3.3rem;
    height: 3.3rem;
  }
}

@media only screen and (max-width: 540px) {
  .match-situation-icon {
    width: 2.7rem;
    height: 2.7rem;
  }

  .shot-on-target-section-v2 {
    width: 9rem;
  }

  .empty-span {
    padding: 1rem;
  }

  .shot-on-target-img {
    width: 3rem;
    height: 3rem;
  }
}

</style>