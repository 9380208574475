<template>
  <div class="carousel">
        <div class="carousel-items-container">
          <div class="carousel-item item-1"> <match-result :selectedEvent="selectedEvent" :match-team="matchTeam" :referee="referee" :manager="manager"></match-result> </div>
          <div class="carousel-item item-2"> <stadium-info :selectedEvent="selectedEvent" :match-team="matchTeam" :stadium="stadium"></stadium-info> </div>
          <div v-if="matchTeam.status._id !== 0" class="carousel-item item-3"> <cards-info :cards="cards" :corner="corner"></cards-info> </div>
          <div v-if="matchTeam.status._id !== 0" class="carousel-item item-4"> <position-info :ballPossession="ballPossession" :dangerousAttack="dangerousAttack" :attack="attack" :ballSafe="ballSafe"></position-info> </div>
        </div>
        <div class="carousel-buttons-container">
          <div class="carousel-button button-1" v-on:click="selectPage(0)"></div>
          <div class="carousel-button button-2" v-on:click="selectPage(1)"></div>
          <div v-if="matchTeam.status._id !== 0" class="carousel-button button-3" v-on:click="selectPage(2)"></div>
          <div v-if="matchTeam.status._id !== 0" class="carousel-button button-3" v-on:click="selectPage(3)"></div>
        </div>
  </div>
</template>

<script>
import MatchResult from "./Statistics/MatchResult";
import StadiumInfo from "./Statistics/StadiumInfo";
import CardsInfo from "./Statistics/CardsInfo";
import PositionInfo from "./Statistics/PositionInfo";


export default {
  name: "Statistics",
  components: {
    MatchResult,
    StadiumInfo,
    CardsInfo,
    PositionInfo
  },
  data() {
    return {
      currentPage: 0
    }
  },
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    referee: Object,
    manager: Object,
    stadium: Object,
    corner: Object,
    ballPossession: Object,
    dangerousAttack: Object,
    attack: Object,
    ballSafe: Object,
    cards: Object
  },
  methods: {
    selectPage(pageNumber){
      this.currentPage = pageNumber
      const items = document.getElementsByClassName("carousel-item");
      for(let i = 0; i < items.length; i += 1) {
        const marginMultiplier = (i - pageNumber);
        const left = (marginMultiplier * 100) + '%';
        items[i].style['margin-left'] = left;
      }
      const buttons = document.getElementsByClassName("carousel-button");
      for(let i = 0; i < items.length; i += 1) {
        buttons[i].style['background'] = pageNumber === i ? 'red' : 'white';
      }
    }
  },
  mounted() {
   const itemsCount = document.getElementsByClassName("carousel-item").length;

   this.selectPage(0);
   setInterval(() => {
      this.currentPage = (this.currentPage +1) % itemsCount
      this.selectPage(this.currentPage)
    }, 8000)
  }

}
</script>

<style scoped>

.carousel {
  width: 70%;
  height: 70%;
  overflow: hidden;
  margin: 0 auto;
}

.carousel-items-container {
  position: relative;
  height: 80%;
}

.carousel-item {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: margin .3s;
}


.item-1 {
  background: white;
}

.item-2 {
  margin-left: 100%;
  background: white;
}

.item-3 {
  margin-left: 200%;
  background: white;
}

.item-4 {
  margin-left: 300%;
  background: white;
}

.carousel-buttons-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.carousel-button {
  margin: 0 3px;
  border: 1px solid #555;
  background: #555;
  width: 25px;
  height: 5px;
}

@media only screen and (max-width: 960px) {
  .carousel-items-container {
    height: 85%;
  }
}

@media only screen and (max-width: 540px) {
 .carousel-items-container {
   height: 85%;
 }
}

@media only screen and (max-width: 420px) {
  .carousel-items-container {
    height: 80%;
  }
}

</style>