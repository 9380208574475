<template>
  <div class="const_width">
    <div class="filter-bar">
      <div class="content-container">
        <div class="search-container">
          <v-autocomplete
              v-model="searchText"
              :loading="loading"
              :items="getSearchName"
              class="mx-4"
              hide-no-data
              hide-details
              color="blue"
              flat
              solo
              label="Search Team"
              background-color="rgba(0, 0, 0, 0.16)"
              @change="changeName($event)"
              return-object
              append-icon="search"
              style="width: 30%"
              :search-input.sync="locationInput"
          >
            <!--          <template v-slot:item="{item}">
                        {{ item }}
                      </template>-->
          </v-autocomplete>
          <div v-if="searchText !== ''" @click="clearForm()">
            <v-icon
                size="40"
                class="delete-icon"
                color="#2196F3"
                v-text="'clear'"
            />
          </div>
        </div>
        <v-form style="width: 50%;" ref="form" lazy-validation>
          <v-text-field
              v-model="teamId"
              color="c2"
              type="text"
              label="Id"
              required
              readonly
          ></v-text-field>

          <v-text-field
              v-model="teamName"
              color="c2"
              type="text"
              label="Name"
              required
          ></v-text-field>

          <v-text-field
              v-model="mediumName"
              type="email"
              color="c2"
              label="Medium Name"
              required
          ></v-text-field>

          <v-text-field
              v-model="teamAbbr"
              color="c2"
              type="text"
              label="Abbr"
              required
              readonly
          ></v-text-field>

          <v-card-actions class="px-0">
            <v-btn
                color="_c1"
                dark
                @click="postTeamDetail()"
                :disabled="buttonRule()"
                retain-focus-on-click
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>

        <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
        ></v-progress-circular>
        <v-snackbar
            top
            :color="messageObj.style"
            timeout="2000"
            v-model="snackbar"
        >
          {{ messageObj.text }}
        </v-snackbar>


      </div>
    </div>
  </div>
</template>

<script>
import {fetchTeamSearch, postTeamDetail, postTeamDetailForLiveSantra} from "../../api";


export default {
  name: "UpdateTeamDetail",
  data() {
    return {
      loading: false,
      clickBtn: false,
      searchText: '',
      teams: [],
      teamId: null,
      teamName: '',
      mediumName: null,
      locationInput:null,
      teamAbbr: null,
      snackbar: false,
      sendPost: false,
      messageObj: {
        text: '',
        style: '',
      },
      requireRule: [
        v => !!v || 'Bu alan boş bırakılamaz!',
        v => v.length <= 100 || '100 karakteri geçemezsiniz!',
      ]
    }
  },
  methods: {
    async postTeamDetail() {
      this.clickBtn = !this.clickBtn;
      this.loading = true;
      const teamData = {
        id: this.teamId,
        name: this.teamName,
        mediumname: this.mediumName,
        abbr: this.teamAbbr
      }
      await postTeamDetail(teamData).then((data) => {
        if (data.data.data) {
          this.loading = false;
          this.snackbar = true;
          this.sendPost = true;
          this.messageObj = {text: 'Güncelleme başarılı!', style: 'success'};
        } else {
          this.loading = true;
        }
      })
      if (this.sendPost === true) {
        await postTeamDetailForLiveSantra(teamData).then((data) => {
          this.loading = true;
          this.snackbar = false;
          if (data.data.data) {
            this.loading = false;
            this.snackbar = true;
            this.messageObj = {text: 'İkinci güncelleme de başarılı!', style: 'primary'}
          } else {
            this.loading = true;
          }
        })
      }
    },
    changeName(event) {
      const team = event.value;
      this.teamId = team.id;
      this.teamName = team.name;
      this.mediumName = team.mediumname;
      this.teamAbbr = team.abbr;
    },
    buttonRule() {
      if (this.teamId === '' || this.teamName === '' || this.mediumName === '' || this.teamAbbr === '') {
        return true
      } else {
        return false
      }
    },
    clearForm() {
      this.searchText = '';
      this.teamId = '';
      this.teamName = '';
      this.mediumName = '';
      this.teamAbbr = '';
    }
  },
  computed: {
    getSearchName() {
      return this.teams.map(team => {
        return {
          text:`${team.id}-${team.name}`,
          value: team,
        }
      })
    }
  },
  watch: {
     locationInput: async function(searchText) {
      if (searchText === '') {
        this.teams = [];
        return
      }
       const data =  await fetchTeamSearch(searchText);
       this.teams = data.data.data;
    }
  }
}
</script>

<style scoped>

.const_width {
  display: block;
  width: 100%;
}

.filter-bar {
  padding: 10px 0;
}

.content-container {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  place-items: center;
}

.search-container {
  display: flex;
  place-items: center;
}

.theme--light .v-btn:focus::before {
  opacity: 0.90 !important;
  background-color: #c1c1c1 !important;
}

.delete-icon:hover {
  cursor: pointer;
  color: red !important;
}

</style>