<template>
  <div>
    <h2 class="fv_title">Forum Mesajları</h2>
    <v-container class="pt-0">
      <template v-if="anyMessage">
        <v-row class="msg" v-for="(msg, i) in forumMessages" :key="i">
          <v-col cols="12" sm="3" class="py-2">
            <p class="mb-0"><span class="c2--text font-weight-bold">{{ msg.username }}</span>
            </p>
            <p class="mb-0 grey--text">{{ formatDate(msg.createdDate) }}</p>
          </v-col>
          <v-col cols="12" sm="9" class="py-2 text-model">
            <p class="mb-0">
              {{ msg.message }}
            </p>
          </v-col>
        </v-row>
      </template>
      <h3 v-else class="text-center grey--text mt-3">Bu maç için mesaj bulunmuyor.</h3>
      <v-row v-if="totalPages > 1">
        <v-col sm="8" class="mx-auto">
          <v-pagination
              v-model="paginationModel"
              :length="totalPages"
              total-visible="5"
              color="_c1"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="8" class="mx-auto mt-3 text-right">
          <v-form
              ref="form"
              v-model="valid"
          >
            <v-textarea
                :rules="requireRule"
                v-model="messageModel"
                counter="500"
                color="c2"
                outlined
                label="Bu alana maç ile ilgili görüşlerinizi yazabilirsiniz."
            ></v-textarea>
          </v-form>
          <v-btn color="c2" dark @click.stop="validateMessage">Gönder</v-btn>
        </v-col>
      </v-row>
      <v-snackbar
          top
          :color="sb.color"
          timeout="2000"
          v-model="snackbar"
      >
        {{ this.sb.message }}
      </v-snackbar>
    </v-container>


    <v-dialog
        v-model="dialog"
        max-width="400"
        :transition="false"
    >
      <v-card>
        <v-card-title class="_c1">
          <img src="@/assets/logo.png" class="mx-auto my-2" width="220px">
        </v-card-title>
        <v-card-text class="pb-0 pt-4">
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="validate()"
          >
            <v-text-field
                v-model="login.username"
                type="text"
                color="c2"
                label="Kullanıcı Adı / E-posta"
                :rules="requiredRule"
                required
            />
            <v-text-field
                v-model="login.password"
                type="password"
                color="c2"
                label="Şifre"
                :rules="requiredRule"
                required
            />
            <v-container class="py-0">
              <v-col cols="12" sm="12" class="pb-0">
                <v-btn
                    block
                    color="_accent"
                    dark
                    type="submit"
                >
                  GİRİŞ
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" class="pb-0 text-center">
                <v-btn
                    small
                    text
                    color="blue"
                    class="text-capitalize font-weight-regular"
                    @click="toPath('Register')"
                >
                  Kayıt Ol
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" class="text-center pt-0">
                <v-btn
                    small
                    text
                    color="blue"
                    class="text-capitalize font-weight-regular"
                    @click="toPath('ForgotPassword')"
                >
                  Şifremi Unuttum
                </v-btn>
              </v-col>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api2, {fetchForum} from "../../api";
import dayjs from 'dayjs';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Forum",
  data: () => ({
    dialog: false,
    snackbar: false,
    errorMessage: '',
    login: {
      username: '',
      password: '',
    },
    valid: false,
    requiredRule: [
      v => !!v || 'Bu alan boş bırakılamaz!',
    ],
    forumMessages: [],
    userDialog: false,
    index: 0,
    size: 10,
    totalPages: 0,
    pageNumber: 0,
    totalElements: 0,
    paginationModel: 1,
    anyMessage: true,
    requireRule: [
      v => !!v || 'Bu alan boş bırakılamaz!',
      v => v.length <= 500 || '500 karakteri geçemezsiniz!'
    ],
    messageModel: '',
    sb: {
      message: '',
      color: '',
    }
  }),

  created() {
    this.fetchForumMessages();
    this.fetchUser();
  },

  computed: {
    matchId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      user: 'user',
    })
  },
  methods: {
    ...mapActions({
      loginRequest: 'auth_request',
      logout: 'logout',
      fetchUser: 'fetch_user_info',
    }),
    toPath(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({name: routeName});
      }
      if (this.dialog) {
        this.dialog = false;
      }
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.loginRequest(this.login)
            .then((data) => {
              if (data.data.success) {
                this.dialog = false;
              } else {
                this.errorMessage = data.data.apiError.errorMessage;
                this.snackbar = true;
              }
            });
      }
    },

    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async fetchForumMessages() {
      const data = await fetchForum(this.matchId,this.paginationModel,this.size);
      (data.data.data.content.length < 1) ? this.anyMessage = false : this.anyMessage = true;
      this.forumMessages = data.data.data.content;
      this.totalPages = data.data.data.totalPages;
      this.totalElements = data.data.data.numberOfElements;
    },

    formatDate(value) {
      return dayjs(value).format('YYYY-MM-DD - HH:mm');
    },

    validateMessage() {
      if (this.$refs.form.validate()) {
        if (this.$store.getters.token) {
          this.sendForumMessage();
        } else {
          this.dialog = true;
        }
      }
    },

    sendForumMessage() {
      const messageBody = {
        matchId: this.matchId,
        message: this.messageModel,
        clientType: 'WEB',
        platform: "FutbolVerileri"
      };
      api2.post(`api/v5/forum/message`, messageBody, {
        headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`,
            }
      })
          .then(data => {
            if (data.data.success) {
              this.sendingSuccessAlert();
              this.paginationModel = 1;
              this.fetchForumMessages();
              this.messageModel = '';
              this.$refs.form.reset()
            } else {
              this.errorAlert('Mesajınız gönderilemedi!');
            }
          })
    },

    sendingSuccessAlert() {
      this.sb.message = 'Mesajınız gönderildi!';
      this.sb.color = 'success';
      this.snackbar = true;
    },

    errorAlert(message) {
      this.sb.message = message;
      this.sb.color = 'error';
      this.snackbar = true;
    },
  },

  watch: {
    paginationModel() {
      this.fetchForumMessages();
    },
    valid(value) {
      if (!value) {
        setTimeout(this.resetValidation, 2000);
      }
    },

    dialog(value) {
      if (!value) {
        this.$refs.form.reset();
        this.resetValidation();
      }
    }
  }
}
</script>

<style scoped>
.msg {
  border-bottom: 1px solid #edf2f4;
}

.msg:nth-child(even) {
  background-color: #f4f5f7;
}

.fv_title {
  margin: 15px 0;
}

.text-model {
  display: flex;
  place-items: center;
}
</style>