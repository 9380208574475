<template>
  <div class="position-container">
    <div class="injure-container blink">
      <div class="injure">
        <div class="svg-background">
          <svg class="match-situation-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="56" height="56" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none"  font-size="none"  style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#e74c3c"><path d="M65.84375,22.57605c-2.28438,0 -4.03125,1.74688 -4.03125,4.03125c0,2.28438 1.74687,4.03125 4.03125,4.03125h40.3125c2.28437,0 4.03125,-1.74687 4.03125,-4.03125c0,-2.28437 -1.74688,-4.03125 -4.03125,-4.03125zM43,35.07135c-9.675,0 -17.46875,7.79375 -17.46875,17.46875v66.9198c0,9.675 7.79375,17.46875 17.46875,17.46875h86c9.675,0 17.46875,-7.79375 17.46875,-17.46875v-66.9198c0,-9.675 -7.79375,-17.46875 -17.46875,-17.46875zM43,43.13385h86c5.24062,0 9.40625,4.16563 9.40625,9.40625v66.9198c0,5.24063 -4.16563,9.40625 -9.40625,9.40625h-86c-5.24063,0 -9.40625,-4.16562 -9.40625,-9.40625v-66.9198c0,-5.24062 4.16562,-9.40625 9.40625,-9.40625zM86,64.5c-3.7625,0 -6.71875,2.95625 -6.71875,6.71875v9.67395h-9.67395c-3.7625,0 -6.71875,2.95625 -6.71875,6.71875c0,3.7625 2.95625,6.71875 6.71875,6.71875h9.67395v9.67657c0,3.7625 2.95625,6.71875 6.71875,6.71875c3.7625,0 6.71875,-2.95625 6.71875,-6.71875v-9.67657h9.67395c3.7625,0 6.71875,-2.95625 6.71875,-6.71875c0,-3.7625 -3.09063,-6.71875 -6.71875,-6.71875h-9.67395v-9.67395c0,-3.7625 -2.95625,-6.71875 -6.71875,-6.71875z"></path></g></g></svg>
        </div>
        <div class="injure-info">
          <div class="match-time">
            {{ selectedEvent.time }}'
          </div>
          <div class="font-size">
            <span style="font-weight: 500">{{i18n.injury}}</span> <br> <span class="team-name">{{ matchTeam.teams[selectedEvent.team].name.toUpperCase() }}</span>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="injure-section">
      <div class="injure-section-v2">
        <div class="injure-image-container">
          <svg class="injure-image" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="56" height="56" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none"  font-size="none"  style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#e74c3c"><path d="M65.84375,22.57605c-2.28438,0 -4.03125,1.74688 -4.03125,4.03125c0,2.28438 1.74687,4.03125 4.03125,4.03125h40.3125c2.28437,0 4.03125,-1.74687 4.03125,-4.03125c0,-2.28437 -1.74688,-4.03125 -4.03125,-4.03125zM43,35.07135c-9.675,0 -17.46875,7.79375 -17.46875,17.46875v66.9198c0,9.675 7.79375,17.46875 17.46875,17.46875h86c9.675,0 17.46875,-7.79375 17.46875,-17.46875v-66.9198c0,-9.675 -7.79375,-17.46875 -17.46875,-17.46875zM43,43.13385h86c5.24062,0 9.40625,4.16563 9.40625,9.40625v66.9198c0,5.24063 -4.16563,9.40625 -9.40625,9.40625h-86c-5.24063,0 -9.40625,-4.16562 -9.40625,-9.40625v-66.9198c0,-5.24062 4.16562,-9.40625 9.40625,-9.40625zM86,64.5c-3.7625,0 -6.71875,2.95625 -6.71875,6.71875v9.67395h-9.67395c-3.7625,0 -6.71875,2.95625 -6.71875,6.71875c0,3.7625 2.95625,6.71875 6.71875,6.71875h9.67395v9.67657c0,3.7625 2.95625,6.71875 6.71875,6.71875c3.7625,0 6.71875,-2.95625 6.71875,-6.71875v-9.67657h9.67395c3.7625,0 6.71875,-2.95625 6.71875,-6.71875c0,-3.7625 -3.09063,-6.71875 -6.71875,-6.71875h-9.67395v-9.67395c0,-3.7625 -2.95625,-6.71875 -6.71875,-6.71875z"></path></g></g></svg>
        </div>
        <div class="injure-text-container">
          <span class="injure-text">{{ i18n.injury }}</span>
        </div>
        <div class="injure-team-container">
          <span class="team-text">{{ matchTeam.teams[selectedEvent.team].name.toUpperCase() }}</span>
          <span class="empty-span"></span>
        </div>
      </div>
    </div>-->
  </div>

</template>

<script>
export default {
  name: "Injure",
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    i18n: Object
  },
}
</script>

<style scoped>

.svg-background {
  display: flex;
  width: auto;
  justify-content: center;
  background-color: white;
  border-radius: 3px;
}

.blink {
  animation: reveal .5s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(100% 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.injure-info {
  display: flex;
  place-items: center;
  padding: 0 8px;
  justify-content: space-between;
  background-color: white;
  border-radius: 1px;
  width: 170px;
  height: 40px;
}

.match-situation-icon {
  width: 4rem;
  height: 4rem;
}

.match-time {
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 12px;
  min-width: 24px;
  padding: 0 4px;
  height: 24px;
  border-radius: 2px;
  color: white;
  background-color: red;
}

.injure-container {
  position: absolute;
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.injure {
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: space-between;
  min-width: 25%;
  height: 120px;
}

.font-size {
  font-size: 13px;
  font-weight: normal;
  text-align: right;
  margin: 0 4px;
  min-width: 100px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-name {
  font-size: 10px;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

/* New Mackolik Style */

.injure-section {
  position: absolute;
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
}

.injure-section-v2 {
  display: flex;
  place-items: center;
  flex-direction: column;
  width: 10rem;
}

.injure-text-container {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  background: #333;
  width: 100%;
  height: 100%;
}

.injure-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}

.injure-team-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 5px solid red;
}

.injure-image-container {
  background: white;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.team-text {
  z-index: 2;
  color: white;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  padding: 0.3rem;
  align-content: center;
  height: 100%;
  display: flex;
  place-items: center;
  width: 100%;
  justify-content: center;
}

.empty-span {
  background: black;
  opacity: 0.5;
  width: 100%;
  height: 1.5rem;
  padding: 1.3rem;
}

.injure-image {
  width: 4.2rem;
  height: 4.2rem;
}

/* **************** */


@media only screen and (max-width: 1264px) {
  .match-situation-icon {
    width: 3.5rem;
    height: 3.5rem;
  }
}

@media only screen and (max-width: 960px) {
  .match-situation-icon {
    width: 3rem;
    height: 3rem;
  }
}

@media only screen and (max-width: 540px) {
  .match-situation-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .injure-section-v2 {
    width: 9rem;
  }

  .injure-text {
    font-size: 1rem;
  }

  .empty-span {
    padding: 1rem;
  }

  .injure-image {
    width: 3rem;
    height: 3rem;
  }
}

</style>