<template>
<div class="position-container">
  <div class="offside-container blink">
    <div class="offside">
      <div>
        <v-img src="@/assets/lmt/offsideFlag.svg" class="match-situation-icon" contain/>
      </div>
      <div class="offside-info">
        <div class="match-time">
          {{ selectedEvent.time }}'
        </div>
        <div class="font-size">
          <span style="font-weight: 500">{{i18n.offside}}</span> <br> <span class="team-name">{{ matchTeam.teams[selectedEvent.team].name.toUpperCase() }}</span>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="offside-section">
    <div class="offside-section-v2">
      <div class="offside-image-container">
        <v-img src="@/assets/lmt/offsideFlag.svg" class="offside-img" contain/>
      </div>
      <div class="offside-text-container">
        <span class="offside-text">{{i18n.offside}}</span>
      </div>
      <div class="offside-team-container">
        <span class="team-text">{{ matchTeam.teams[selectedEvent.team].name.toUpperCase() }}</span>
        <span class="empty-span"></span>
      </div>
    </div>
  </div>-->

</div>
</template>

<script>
export default {
  name: "Offside",
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    i18n: Object
  },
}
</script>

<style scoped>

.blink {
  animation: reveal .5s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(100% 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.offside-container {
  position: absolute;
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.offside {
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: space-between;
  min-width: 25%;
  height: 120px;
}

.font-size {
  font-size: 13px;
  font-weight: normal;
  text-align: right;
  min-width: 100px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-name {
  font-size: 10px;
}

.offside-info {
  display: flex;
  place-items: center;
  padding: 0 8px;
  justify-content: space-between;
  background-color: white;
  border-radius: 1px;
  width: 170px;
  height: 40px;
}

.match-situation-icon {
  width: 3.5rem;
  height: 3.5rem;
}

.match-time {
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 12px;
  min-width: 24px;
  padding: 0 4px;
  height: 24px;
  border-radius: 2px;
  color: white;
  background-color: #939393;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

/* New Mackolik Style */

.offside-section {
  position: absolute;
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
}

.offside-section-v2 {
  display: flex;
  place-items: center;
  flex-direction: column;
  width: 10rem;
}

.offside-text-container {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  background: #333;
  width: 100%;
  height: 100%;
}

.offside-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}

.offside-team-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 5px solid gray;
}

.offside-image-container {
  margin-top: 20px;
}

.team-text {
  z-index: 2;
  color: white;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  padding: 0.3rem;
  align-content: center;
  height: 100%;
  display: flex;
  place-items: center;
  width: 100%;
  justify-content: center;
}

.empty-span {
  background: black;
  opacity: 0.5;
  width: 100%;
  height: 1.5rem;
  padding: 1.3rem;
}
.offside-img {
  width: 4.2rem;
  height: 4.2rem;
}

/* ****************** */

@media only screen and (max-width: 1264px) {
  .match-situation-icon {
    width: 3.3rem;
    height: 3.3rem;
  }
}

@media only screen and (max-width: 960px) {
  .match-situation-icon {
    width: 3rem;
    height: 3rem;
  }
}

@media only screen and (max-width: 540px) {
  .match-situation-icon {
    width: 2.7rem;
    height: 2.7rem;
  }

  .offside-section-v2 {
    width: 9rem;
  }

  .empty-span {
    padding: 1rem;
  }

  .offside-img {
    width: 3rem;
    height: 3rem;
  }
}


</style>