<template>
  <div class="const_width">
    <div class="content_container">
      <div class="news-general-container">
        <div class="news-title-container">
          <span class="news-title">{{newsDetail.title}}</span>
        </div>

        <div class="news-img-container">
          <v-img class="news-img" :src="`${newsDetail.imageUrl}`"/>
        </div>

        <div class="subtitle-container">
          <span class="subtitle">{{newsDetail.subTitle}}</span>
        </div>

        <div class="news-content-container">
          <span v-html="newsDetail.body"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchNewsLists} from "../../api";

export default {
  name: "NewsView",
  data() {
    return {
      newsDetail: null
    }
  },
  mounted() {
    this.fetchNewsLists();
  },
  methods: {
    async fetchNewsLists() {
      const data = await fetchNewsLists();
      const newsList = data.data.data;

      let newsId = this.$route.params.id;
      console.log('---')
      console.log(newsList)
      console.log('---')
      const result = data.data.data.filter(({id}) => `${id}` === newsId)
      this.newsDetail = result[0];
      console.log("result",result[0])
    },
    prettyPrint(body) {
      return body
    },
    mert(e) {
      let input = e;
      input = input.replace(/[\n]{2}/, '</p><p>');
      input = input.replace(/[\n]{1}/, '<br/>');
      input = input.replace(/\*\*(.+)\*\*/, '<strong>$1</strong>');
      input = input.replace(/\*(.+)\*/, '<em>$1</em>');
      return input
    }
  }
}
</script>

<style scoped>

.const_width {
  display: block;
  width: 100%;
}

.content_container {
  width: 60%;
  padding: 10px;
  margin: 10px 0;
}

.news-general-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.news-img {
  width: 25rem;
  border-radius: 10px;
}

.news-title-container {
  display: flex;
  flex-direction: column;
}

.news-title {
  font-size: 1.2rem;
}

.subtitle {
  font-size: 0.85rem;
  font-weight: 500;
}

.news-content-container {
  margin-top: 10px;
}

@media only screen and (max-width: 650px) {
  .content_container {
    width: 100%;
  }

  .news-img {
    width: 18rem;
    height: 10rem;
    border-radius: 10px;
  }

  .news-title {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .subtitle {
    display: none;
  }
}

</style>