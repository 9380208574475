<template>
  <div style="padding: 10px 20px">
    <h1>IP Address and Cookie Policy</h1>
    <p>
      <strong>Please read the following carefully to understand how and why this Website and/or App uses your IP address, cookies and local storage. You must give your express consent in order to access and browse our website <a href="https://www.futbolverileri.com/">FutbolVerileri.com</a> or our mobile application bearing the name FutbolVerileri and available on Google PlayStore, AppStore or for you to be possible to download directly from our Website, both Website and mobile application hereafter referred as the "App". If you do not accept the provisions of this Policy, you must exit our App immediately.</strong>
    </p>
    <p>
      <strong>Last Updated:</strong> 25.5.2018
    </p>
    <p>
      (hereinafter, the "<strong>IP and Cookie Policy</strong>").
    </p>
    <p>
      Livesport s.r.o., Bucharova 2928/14a, 158 00 Prague, Czech Republic ("<strong>We</strong>", "<strong>Us</strong>" "<strong>Our</strong>" or "<strong>Livesport</strong>") collects two types of information about you resulting from your use of its App (i) personal information, including your name and e-mail address (if registered user) and (ii) aggregate information, this being generated from the practices which We have in place in relation to "cookies" and the Internet Protocol Address ("<strong>IP Address</strong>") of its users.
    </p>
    <p>
      Our practices in relation to the collection and processing of such personal information are set out in our privacy policy as part of general ToU of the <a href="https://www.futbolverileri.com/terms/">Website</a>.
    </p>
    <p>
      Correspondingly, the practices which we have in place regarding the generation of aggregate information (as mentioned above) are described here in our IP and Cookie Policy. In order to be able to browse and use the App, as well as the online platform which you, the user, are connected to by means of the App, you must expressly agree to the practices set out herein.
    </p>
    <h2>(A) IP Address</h2>
    <h2>What Happens When You Visit the App</h2>
    <p>
      Every time you connect to the internet or, if you use an always-on connection, such as broadband or ADSL, when you boot up or restart your computer, you are automatically assigned a unique identifying number known as an IP Address. This IP Address, which contains information regarding the location of your computer on the internet (your country of origin) and the name of your internet service provider ("<strong>ISP</strong>"), is automatically logged by Our App.
    </p>
    <ul>
      <li>
        <strong>What is an IP Address?</strong><br>
        <p>
          When you first started your internet session (i.e. your computer connected to the internet), your computer was automatically assigned a unique number, known as an IP Address. This is your computer's unique address on the internet. Without such an IP Address, websites would not be able to deliver their content to you as they would not be able to find your computer on the internet. Since each time you disconnect and reconnect to the internet a new IP Address can be automatically assigned to your computer, IP Addresses are not inherently capable of identifying you as an individual (at least, by themselves).
        </p>
        <p>
          An IP Address does, however, contain information regarding the location of your computer on the internet (your country) and the name of your ISP.
        </p>
      </li>
      <li>
        <strong>How Do We Collect Your IP Address</strong><br>
        <p>
          Each time you visit a page on our App, your computer sends out a message to our servers asking for the content. This message sent by your computer also encloses your IP Address as a form of "return address" so that the App may find your computer in order to send it the requested content. Our web-server automatically logs all these messages.
        </p>
      </li>
      <li>
        <strong>What Do We Do With Your IP Address?</strong><br>
        <p>
          When we log your IP Address, the data collected is grouped up with the other logged IP Addresses in order to provide us with the statistics on the geographic location of visitors to our App, which are the most viewed pages and for other statistical reasons.
        </p>
        <p>
          We gather your IP address automatically and store it in log files. These files also contain information relating to your browser type, ISP, operating system, date/time stamp, clickstream data and the files viewed on our App. Collecting this type of information allows us to generate aggregate information for the purposes of our App, including in terms of overall user trends and activities online (such as the number of unique visitors, pages accessed most frequently or the search terms entered). It also allows us to administer our App, diagnose any potential server problems, analyse visitor trends and statistics, and generally help us to provide you with a better internet experience. IP Addresses are not stored for longer than necessary for the above stated purposes.
        </p>
      </li>
    </ul>
    <h2>(B) Cookies</h2>
    <h2>Information About Our Use of Cookies</h2>
    <p>
      Our App uses cookies and other client-side storages (like local storage, etc.) to distinguish from other users of our App. They allow us to analyse trends and administer the App to help us to provide you with a good experience when you browse our App and to improve it at the same time.
    </p>
    <p>
      A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. A cookie, enables the website to remember users that have already visited. Without a cookie, every time you open a new web page, the server where that page is stored will treat you like a completely new visitor. Cookies allow us to remember your custom preferences such as your language preference and allow you to complete tasks without having to re-enter your information when browsing in order to make browsing on our Website easier.
    </p>
    <p>
      We use the following cookies:
    </p>
    <ul>
      <li>
        <strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of Our App. Namely, by way of illustration, they include cookies that enable you to log into secure areas of Our App and make use of its check-out functionality.
      </li>
      <li>
        <strong>Analytical/Performance cookies.</strong> They allow us to recognise and count the number of visitors and to see how visitors move around Our App when they are using it. This helps us to improve the way the App works, for example, by ensuring that users are finding what they are looking for easily.
      </li>
      <li>
        <strong>Functionality cookies.</strong> These are used to recognise you when your return to the App. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
      </li>
      <li>
        <strong>Targeting cookies.</strong> These cookies record your visit to the App, the pages you have visited and the links you have followed. We will use this information to make the App and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
      </li>
      <li>
        <strong>Session cookies.</strong> These cookies contain encrypted information to allow our system to uniquely identify you while you are logged in. Session cookies exist only during an online session. They disappear from your computer when you close your browser software. These allow us to process your requests and help us make sure you are who you say you are after you've logged in.
      </li>
      <li>
        <strong>Third party cookies.</strong> These cookies allow our App to interact with different websites and providers which may provide them useful information.
      </li>
    </ul>
    <p>
      You can find more information about the individual cookies we use and the purposes which we use them in the table below:
    </p>
    <table>
      <thead>
      <tr><th>Cookie</th><th>Purpose</th><th>More information</th></tr>
      </thead>
      <tbody>
      <tr>
        <td>lsid_email</td>
        <td>Your e-mail (if any) to identify registered and logged user.</td>
        <td>Registration adds advanced functionality (e.g. data synchronisation between devices - desktop, mobile, tablet).</td>
      </tr>
      <tr>
        <td>lsid_hash</td>
        <td>A string to verify you as a logged user.</td>
        <td></td>
      </tr>
      <tr>
        <td>lsid_innerData</td>
        <td>A string holding some basic App data like user's preferred leagues and teams.</td>
        <td></td>
      </tr>
      <tr>
        <td>lsid_nickname</td>
        <td>A user's nickname (if any).</td>
        <td></td>
      </tr>
      <tr>
        <td>lsid_persistent</td>
        <td>Info if a registered user wishes to log in automatically</td>
        <td></td>
      </tr>
      <tr>
        <td>ts_tz</td>
        <td>Preferred time zone</td>
        <td></td>
      </tr>
      </tbody>
    </table>
    <p>
      Please note that third parties (including advertising networks and providers of external services like web traffic analysis services or social networks) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.
    </p>
    <p>
      You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies), you may not be able to access all or parts of our App. Moreover, some parts of our App may not function properly if you choose not to accept cookies.
    </p>
    <p>
      Except for essential and third party cookies, all cookies will expire after 12 months period.
    </p>
    <p>
      You can control the use of cookies at the individual browser level. If you disable cookies, you may still use our App, but your ability to use some features or areas of our App may be limited. Many browsers allow you to browse privately whereby cookies are automatically erased after you visit a site. The following is a list of the most common browsers and the way in which you can activate private browsing:
    </p>
    <ul>
      <li><strong>Internet Explorer 8 and later versions:</strong> In Private</li>
      <li><strong>Safari 2 and later versions:</strong> Private Navigation/Browsing</li>
      <li><strong>Firefox 3.5 and later versions:</strong> Private Navigation/Browsing</li>
      <li><strong>Google Chrome 10 and later versions:</strong> Incognito</li>
    </ul>
    <p>
      Further information on cookies can be found on various sites such as <a href="http://www.allaboutcookies.com">www.allaboutcookies.com</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "CookiePolicy"
}
</script>

<style scoped>

</style>