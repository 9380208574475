<template>
  <div class="const_width">
    <div class="filter-bar" v-if="this.tvMatches">
      <!--    <template v-if="tv_fav_games.length > 0">
            <div class="live_scores_header _header">
              <div>Favoriler</div>
            </div>
            <TvContent v-for="(item,i) in this.tv_fav_games" :key="i" :item="item"></TvContent>
          </template>-->

      <TvContent v-for="(item,i) in this.matches" :key="i" :item="item"></TvContent>
    </div>
  </div>
</template>

<script>
import TvContent from "./TvContent";
import {mapGetters} from "vuex";
import {fetchMatchesTv} from "../../api";

export default {
  name: "Tv",
  mounted() {
    this.fetchMatchTv();
    setInterval(() => {
      this.fetchMatchTv();
    },30000)
  },
  data() {
    return {
      tvMatches: null
    }
  },
  components: {
    TvContent
  },
  methods: {
    async fetchMatchTv() {
      const data = await fetchMatchesTv();
      this.tvMatches = data.data.data;
    },
  },
  computed: {
    ...mapGetters([
      'tv_fav_games'
    ]),
    matches() {
      return this.tvMatches;
    }
  }

}
</script>

<style scoped>

.const_width {
  display: block;
  width: 100%;
}

.filter-bar {
  padding: 10px 0;
}

@media only screen and (max-width: 426px) {
  .const_width {
    width: 100%;
  }
}


</style>