<template>
  <div class="general-section">
    <h2 v-if="this.tr === activeLanguage()" class="fv_title">Görsel Anlatım</h2>
    <h2 v-if="this.en === activeLanguage()" class="fv_title">Visual</h2>
    <div v-if="!active" class="lmt_placeholder">
      <v-btn icon large class="play_button" @click="active = true">
        <v-icon color="white" x-large>play_circle_outline</v-icon>
      </v-btn>
    </div>
    <FVLmtV2 v-if="active" :match-id="this.$route.params.id"/>

    <timeline/>
  </div>
</template>

<script>
import Timeline from "./Timeline";
import FVLmtV2 from "../fv-lmt-v2/views/FVLmtV2";

export default {
  name: "MatchInfo",
  components: {Timeline, FVLmtV2},
  data() {
    return {
      active: false,
      tr: false,
      en: false,
    }
  },
  methods: {
    activeLanguage() {
      if (localStorage.getItem('language') === 'tr') {
        return this.tr = true;
      } else {
        return this.en = true;
      }
    },
  }
}
</script>
<style scoped>

.general-section {
  position: relative;
}

.lmt_placeholder {
  height: 100px;
  text-align: center;
  background-image: url("../../assets/images/stadium.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.play_button {
  margin-top: 28px;
}

.play_button i {
  font-size: 60px !important;
}
</style>
