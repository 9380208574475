<template>

  <div class="position-container" v-if="!!matchTeam">
    <div class="card-container" v-if="matchTeam.status._id === 0 || matchTeam.status._id === 31 || matchTeam.status._id === 100">
      <statistics
          :selectedEvent="selectedEvent" :match-team="matchTeam" :referee="referee" :manager="manager"
          :stadium="stadium" :corner="corner"
          :ballPossession="ballPossession" :dangerousAttack="dangerousAttack" :attack="attack" :ballSafe="ballSafe"
          :cards="cards"
      ></statistics>
    </div>
    <div class="position-container" v-if="matchTeam.status._id !== 31 && matchTeam.status._id !== 100">
      <injury-time v-if="this.matchTeam.timeinfo.injurytime !== null" :i18n="this.strings.positionIndicators" :match-team="matchTeam"></injury-time>
      <match-situation v-if="this.type === 'matchsituation'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></match-situation>
      <freekick v-else-if="this.type === 'freekick'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></freekick>
      <throwin v-else-if="this.type === 'throwin'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></throwin>
      <!--<goal-animation-v2-home v-else-if="this.type === 'goal'  && selectedEvent.team === 'home'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></goal-animation-v2-home>
      <goal-animation-v2-away v-else-if="this.type === 'goal' && selectedEvent.team === 'away'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></goal-animation-v2-away>-->
      <corner-animation v-else-if="this.type === 'corner'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></corner-animation>
      <!--<goal-animation-v3-home v-else-if="this.type === 'goal' && selectedEvent.team === 'home'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></goal-animation-v3-home>
      <goal-animation-v3-away v-else-if="this.type === 'goal' && selectedEvent.team === 'away'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></goal-animation-v3-away>-->
      <substitution v-else-if="this.type === 'substitutions'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></substitution>
      <goal-kick v-else-if="this.type === 'goal_kick'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></goal-kick>
      <shot-on-target v-else-if="this.type === 'shotontarget'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></shot-on-target>
      <goal v-else-if="this.type === 'goal'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></goal>
      <!--<corner v-else-if="this.type === 'corner'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></corner>-->
      <offside v-else-if="this.type === 'offside'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></offside>
      <injure v-else-if="this.type === 'injury'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></injure>
      <cards v-else-if="this.type === 'card'" :i18n="this.strings.positionIndicators" :selectedEvent="selectedEvent" :match-team="matchTeam"></cards>
    </div>
  </div>
</template>

<script>
import MatchSituation from "./Positions/MatchSituation";
import Freekick from "./Positions/Freekick";
import Throwin from "./Positions/Throwin";
import GoalKick from "./Positions/GoalKick";
import ShotOnTarget from "./Positions/ShotOnTarget";
import Goal from "./Positions/Goal";
//import Corner from "./Positions/Corner";
import Offside from "./Positions/Offside";
import Injure from "./Positions/Injure";
import Cards from "./Positions/Cards";
import InjuryTime from "./Positions/InjuryTime";
import Substitution from "./Positions/Substitution";
import CornerAnimation from "./Positions/CornerAnimation";
import Statistics from "./Statistics";
//import GoalAnimationV2Away from "./Positions/GoalAnimationV2Away";
//import GoalAnimationV2Home from "./Positions/GoalAnimationV2Home";
//import GoalAnimationV3Home from "./Positions/GoalAnimationV3Home";
//import GoalAnimationV3Away from "./Positions/GoalAnimationV3Away";

export default {
  name: "PositionIndicators",
  components: {
    //GoalAnimationV2Home,
    //GoalAnimationV3Home,
    //GoalAnimationV3Away,
    MatchSituation,
    Freekick,
    Throwin,
    GoalKick,
    ShotOnTarget,
    Goal,
    //Corner,
    Offside,
    Injure,
    Cards,
    Statistics,
    InjuryTime,
    Substitution,
    CornerAnimation,
    //GoalAnimationV2Away
  },
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    referee: Object,
    manager: Object,
    stadium: Object,
    corner: Object,
    ballPossession: Object,
    dangerousAttack: Object,
    attack: Object,
    ballSafe: Object,
    cards: Object
  },
  mounted() {
    const params = new URLSearchParams(window.location.search)
    const language = params.get('language') || localStorage.getItem('language') || 'tr';
    this.strings = this.i18n[language];
  },
  data() {
    return {
      type: null,
      i18n: {
        tr: {
          positionIndicators: {
            yCard: 'Sarı Kart',
            rCard: 'Kırmızı Kart',
            corner: 'Korner',
            freekick: 'Serbest Vuruş',
            goal: 'Gol',
            goalKick: 'Kale Vuruşu',
            injury: 'Sakatlık',
            injuryTime: 'Uzatma Süresi',
            matchSituationSafe: 'Güvenli Bölge',
            matchSituationAttack: 'Atak',
            matchSituationDangerous: 'Tehlikeli Atak',
            offside: 'Ofsayt',
            shotOnTarget: 'İsabetli Şut',
            substitionIn: 'Giren',
            substitionOut: 'Çıkan',
            throwin: 'Taç',
          },
        },
        en: {
          positionIndicators: {
            yCard: 'Yellow Card',
            rCard: 'Red Card',
            corner: 'Corner',
            freekick: 'Freekick',
            goal: 'Goal',
            goalKick: 'Gaol Kick',
            injury: 'Injury',
            injuryTime: 'Injury Time',
            matchSituationSafe: 'Safe Ball',
            matchSituationAttack: 'Attack',
            matchSituationDangerous: 'Dangerous Attack',
            offside: 'Offside',
            shotOnTarget: 'Shot On Target',
            substitionIn: 'In',
            substitionOut: 'Out',
            throwin: 'Throwin',
          },
        }
      },
      strings: null
    }
  },
  methods: {
    handleEventChange(value) {
      if (!value) {
        return
      }

      const type = value?.type

      if (type) {
        this.type = type
      }
    }
  },
  watch: {
    selectedEvent(value) {
      this.handleEventChange(value)
    }
  },
}
</script>


<style scoped>

.card-container {
  position: absolute;
  display: flex;
  place-items: center;
  width: 100%;
  height: 100%;
}

.position-container {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

/*

@media only screen and (max-width: 1264px) {
  .card-container {
    left: 10%;
    width: 80%;
    height: 80%;
    top: 20%;
    right: 18%;
    bottom: 17%;
  }
}

@media only screen and (max-width: 960px) {

  .card-container {
    top: 22%;
  }
}

@media only screen and (max-width: 768px) {
  .card-container {
    left: 10%;
    top: 18%;
    width: 80%;
    height: 80%;
  }
}

@media only screen and (max-width: 414px) {
  .card-container {
    left: 10%;
    top: 20%;
    width: 80%;
    height: 80%;
  }
}

@media only screen and (max-width: 360px) {
  .card-container {
    left: 10%;
    top: 20%;
    width: 80%;
    height: 80%;
  }
}

 */

</style>
