<template>
  <div class="const_width">
    <div class="left_part">
      <div class="content_container">
        <template v-if="!vip">
          <PremiumAlert/>
        </template>
        <template v-else>
          <div class="filter_bar">
            <div class="toggle_buttons">
              <div class="toggle_button" :class="{'active': !live}" @click="live = false">Maç Önü
                Tahminler
              </div>
              <div class="toggle_button" :class="{'active': live}" @click="live = true">Canlı Tahminler
              </div>
              <div></div>
            </div>
          </div>
          <div class="predictions_container">
            <div class="prediction" v-for="(item, index) in predictions" :key="index">
              <div class="prediction_header _header _accent--text">
                <div class="p_league_name">{{ item.match.competitionName }}</div>
                <div class="prediction_header_date">{{ formatDate(item.dateTimeUtc) }}</div>
              </div>
              <div class="prediction_first_row">
                <div class="p_status">
                  <v-icon v-if="item.match.matchStatus === 'Played'" color="success">done</v-icon>
                  <v-icon v-if="item.match.matchStatus === 'Playing'" color="warning">access_time</v-icon>
                  <v-icon v-if="item.match.matchStatus === 'Fixture'" color="error">clear</v-icon>
                  <span>{{ STATUS[item.match.matchStatus] }}</span>
                </div>
                <div class="p_home_team">{{ item.match.homeTeam }}</div>
                <div v-if="item.match.matchStatus === 'Played' || item.match.matchStatus === 'Playing'" class="p_score">
                  <router-link :to="toMatchDetail(item)" target="_blank">
                    {{ item.match.score }}
                  </router-link>
                </div>
                <div v-else class="p_score">
                  <router-link :to="toMatchDetail(item)" target="_blank">
                    v
                  </router-link>
                </div>
                <div class="p_away_team">{{ item.match.awayTeam }}</div>
                <div class="p_half_time" v-if="item.match.halfTime">İY: <b>{{ item.match.score1h }}</b></div>
              </div>
              <div class="prediction_second_row">
                <div class="p_selection">{{ item.tips[0].comment}}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="right_part">
      <mobile-apps-card/>
      <twitter-timeline/>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import api2 from "../../api";
import MobileAppsCard from '../layout/MobileAppsCard'
import TwitterTimeline from "../layout/TwitterTimeline";
import {mapGetters} from "vuex";
import PremiumAlert from "@/components/premium/PremiumAlert";

export default {
  name: "Predictions",
  components: {PremiumAlert, TwitterTimeline, MobileAppsCard},

  data() {
    return {
      predictions: null,
      live: false,
      BET_VALUES,
      STATUS,
    }
  },

  mounted() {
    if (this.vip) {
      this.fetchPredictions();
    }
  },

  methods: {
    async fetchPredictions() {
      const data = await api2.get(`api/v2/betting/iddaa-tips?live=${this.live}`, {
        headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`,
              Platform: 'FutbolVerileri'
            }
      });
      this.predictions = data.data.data;
    },

    teamLogo(id) {
      return `https://futbolverileri.com/cdn/img/Opt_teams/${id}.png`;
    },

    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD - HH:mm')
    },

    toMatchDetail(match) {
      return {name: 'MDCompare', params: {id: match.match.id}}
    }
  },

  computed: {
    ...mapGetters(['vip']),
  },

  watch: {
    live() {
      this.predictions = null;
      this.fetchPredictions();
    },

    vip(value) {
      if (value) {
        this.fetchPredictions();
      }
    }
  }
}
const STATUS = {
  'WON': 'Kazandı',
  'PENDING': 'Beklemede',
  'LOST': 'Kaybetti',
};

const BET_VALUES = {
  "All": "Tümü",
  "Over_0_5_Goals": "Gol 0.5 Üst",
  "Over_1_5_Goals": "Gol 1.5 Üst",
  "Over_2_5_Goals": "Gol 2.5 Üst",
  "Over_3_5_Goals": "Gol 3.5 Üst",
  "Under_0_5_Goals": "Gol 0.5 Alt",
  "Under_1_5_Goals": "Gol 1.5 Alt",
  "Under_2_5_Goals": "Gol 2.5 Alt",
  "Under_3_5_Goals": "Gol 3.5 Alt",
  "HT_Over_1_5_Goals": "Gol İY 1.5 Üst",
  "HT_Over_0_5_Goals": "Gol İY 0.5 Üst",
  "HT_Under_1_5_Goals": "Gol İY 1.5 Alt",
  "HT_Under_0_5_Goals": "Gol İY 0.5 Alt",
  "Over_5_5_Corners": "Korner 5.5 Üst",
  "Over_6_5_Corners": "Korner 6.5 Üst",
  "Over_7_5_Corners": "Korner 7.5 Üst",
  "Over_8_5_Corners": "Korner 8.5 Üst",
  "Over_9_5_Corners": "Korner 9.5 Üst",
  "Over_10_5_Corners": "Korner 10.5 Üst",
  "Under_5_5_Corners": "Korner 5.5 Alt",
  "Under_6_5_Corners": "Korner 6.5 Alt",
  "Under_7_5_Corners": "Korner 7.5 Alt",
  "Under_8_5_Corners": "Korner 8.5 Alt",
  "Under_9_5_Corners": "Korner 9.5 Alt",
  "Under_10_5_Corners": "Korner 10.5 Alt",
  "Over_2_5_Cards": "Kart 2.5 Üst",
  "Over_3_5_Cards": "Kart 3.5 Üst",
  "Over_4_5_Cards": "Kart 4.5 Üst",
  "Over_5_5_Cards": "Kart 5.5 Üst",
  "Under_2_5_Cards": "Kart 2.5 Alt",
  "Under_3_5_Cards": "Kart 3.5 Alt",
  "Under_4_5_Cards": "Kart 4.5 Alt",
  "Under_5_5_Cards": "Kart 5.5 Alt",
  "Over_2_5_YellowCards": "Sarı Kart 2.5 Üst",
  "Over_3_5_YellowCards": "Sarı Kart 3.5 Üst",
  "Over_4_5_YellowCards": "Sarı Kart 4.5 Üst",
  "Over_5_5_YellowCards": "Sarı Kart 5.5 Üst",
  "Under_2_5_YellowCards": "Sarı Kart 2.5 Alt",
  "Under_3_5_YellowCards": "Sarı Kart 3.5 Alt",
  "Under_4_5_YellowCards": "Sarı Kart 4.5 Alt",
  "Under_5_5_YellowCards": "Sarı Kart 5.5 Alt",
  "BTTS": "İki Takım da Gol Atar",
  "Goals_2_3": "2-3 Gol",
  "F_Half_Over_0_5_Goals": "Gol 1.Y 0.5 Üst",
  "S_Half_Over_0_5_Goals": "Gol 2.Y 0.5 Üst",
  "Both_Halves_Goal": "Her İki Yarıda Gol",
};
</script>

<style scoped>
.toggle_button {
  padding: 0 10px;
}

.prediction_header {
  display: grid;
  padding: 0 10px;
  line-height: 24px;
  color: #ffffff;
  grid-template-columns: auto minmax(100px, 150px);
}

.p_league_name, .p_home_team, .p_away_team {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p_status span {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  line-height: 24px;
  padding: 0 10px;
  border-radius: 4px;
}

.p_status:hover span {
  display: block;
}

.prediction_header_date {
  text-align: right;
}

.p_score {
  text-align: center;
  font-weight: 900;
  cursor: pointer;
}

.p_selection {
  color: black;
}

.p_half_time, .p_home_team {
  text-align: right;
}

.p_half_time {
  color: #959595;
}

.prediction:not(:last-child) {
  margin-bottom: 10px;
}

.prediction {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 125px;
  grid-template-rows: 24px 36px 24px;
  color: #3e2d56;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.prediction_first_row {
  display: grid;
  grid-template-columns: minmax(30px, 50px) minmax(100px, 303px) minmax(30px, 40px) minmax(100px, 303px) 50px;
  line-height: 36px;
  padding: 0 10px;
  border-left: 1px solid #edf2f4;
  border-right: 1px solid #edf2f4;
  border-bottom: 1px solid #edf2f4;
}

.prediction_second_row {
  text-align: center;
  font-weight: 900;
  padding: 10px;
  border-left: 1px solid #edf2f4;
  border-right: 1px solid #edf2f4;
}

@media only screen and (min-width: 800px) {
  .predictions_container {
    padding: 0 12px 12px;
  }

}

@media only screen and (max-width: 800px) {
  .predictions_container {
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 650px) {
  .filter_bar .toggle_buttons {
    margin: 0 auto 0 auto;
  }
}
</style>
