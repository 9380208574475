<template>
  <div class="tm_container">
    <div class="filter_bar">
      <v-tabs hide-slider height="30" color="#41a775" v-model="sortBy">
        <v-tab class="sub_tab">Lige Göre</v-tab>
        <v-tab class="sub_tab">Tarihe Göre</v-tab>
      </v-tabs>
      <v-tabs hide-slider height="30" color="#41a775" v-model="side">
        <v-tab class="sub_tab only_mobile">Genel</v-tab>
        <v-tab class="sub_tab">Ev Sahibi</v-tab>
        <v-tab class="sub_tab">Deplasman</v-tab>
      </v-tabs>
    </div>
    <v-skeleton-loader
        v-if="this.$parent.$data.seasonMatches.length < 1 || this.$parent.$data.matches.length < 1"
        class="w-100"
        type="table-heading@20"
    ></v-skeleton-loader>
    <template v-if="matchList && sortBy === 0">
      <div v-for="league in matchList" :key="league.id">
        <div class="c_header_league _header _accent--text">
          <div class="ch_league">{{ league.league }}</div>
          <div class="ch_half_time">İY</div>
          <div class="ch_corner">Korner</div>
          <div class="ch_under_over">A/Ü</div>
          <div class="ch_bts">KG</div>
        </div>
        <div class="team-matches-for-web" v-for="match in league.matches" :key="`bl-${match.id}`">
          <div class="cm_date">{{ matchDate(match.dateTimeUtc) }}</div>
          <div class="cards">
            <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
            <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
          </div>
          <div class="cm_home_team">
            <span :class="teamNameClass(match.homeTeamId)"
                  @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
          </div>
          <div class="cm_score">
            <router-link v-if="match.live || match.ended" :to="{name: 'MDInfo', params: {id: match.id}}"
                         target="_blank">
              {{ match.hscoreFT }} - {{ match.ascoreFT }}
            </router-link>
            <router-link v-else :to="{name: 'MDCompare', params: {id: match.id}}" target="_blank">
              -
            </router-link>
          </div>
          <div class="cm_away_team">
            <span :class="teamNameClass(match.awayTeamId)" @click="toTeamDetail(match.awayTeamId)">{{match.awayTeam}}</span>
          </div>
          <div class="cards">
            <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
            <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
          </div>
          <div class="cm_half_time">
            <span><span class="only_mobile">İY<br></span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
          </div>
          <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.hcorner }} - {{ match.acorner }}
          </div>
          <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
          <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
          <div class="cm_match_status">
            <div :class="matchStatus(match).style" v-text="matchStatus(match).text"/>
          </div>
        </div>

        <div class="team-matches-for-mobile" v-for="match in league.matches" :key="`bl-${match.id}`">
          <div class="cm_date">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
          <div class="cm_home_team">
            <span :class="teamNameClass(match.homeTeamId)"
                  @click="toTeamDetail(match.homeTeamId)">{{ match.homeTeam }}</span>
          </div>
          <div class="cm_score">
            <router-link v-if="match.live || match.ended" :to="{name: 'MDInfo', params: {id: match.id}}"
                         target="_blank">
              {{ match.hscoreFT }} - {{ match.ascoreFT }}
            </router-link>
            <router-link v-else :to="{name: 'MDCompare', params: {id: match.id}}" target="_blank">
              -
            </router-link>
          </div>
          <div class="cm_away_team">
            <span :class="teamNameClass(match.awayTeamId)" @click="toTeamDetail(match.awayTeamId)">{{match.awayTeam}}</span>
          </div>
          <div></div>
          <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
          <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
          <div class="cm_match_status">
            <div :class="matchStatus(match).style" v-text="matchStatus(match).text"/>
          </div>
        </div>
      </div>
    </template>
    <template v-if="matchList && sortBy === 1">
      <div v-for="league in matchList" :key="league.id">
        <div class="c_header _header _accent--text">
          <div class="ch_date">Tarih</div>
          <div class="ch_home_team"></div>
          <div class="ch_score"></div>
          <div class="ch_away_team"></div>
          <div class="ch_half_time">İY</div>
          <div class="ch_corner">Korner</div>
          <div class="ch_under_over">A/Ü</div>
          <div class="ch_bts">KG</div>
        </div>
        <div class="team-matches-for-web" v-for="match in league.matches" :key="`bt-${match.id}`">
          <div class="cm_date">{{ matchDate(match.dateTimeUtc) }}</div>
          <div class="cards">
            <div v-if="match.hyellow > 0" class="card yellow-card">{{ match.hyellow }}</div>
            <div v-if="match.hred > 0" class="card red-card">{{ match.hred }}</div>
          </div>
          <div class="cm_home_team">
          <span :class="teamNameClass(match.homeTeamId)" @click="toTeamDetail(match.homeTeamId)">{{
              match.homeTeam
            }}</span>
          </div>
          <div class="cm_score">
            <router-link v-if="match.live || match.ended" :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
              {{ match.hscoreFT }} - {{ match.ascoreFT }}
            </router-link>
            <router-link v-else :to="{name: 'MDCompare', params: {id: match.id}}" target="_blank">
              -
            </router-link>
          </div>
          <div class="cm_away_team">
          <span :class="teamNameClass(match.awayTeamId)" @click="toTeamDetail(match.awayTeamId)">{{
              match.awayTeam
            }}</span>
          </div>
          <div class="cards">
            <div v-if="match.ayellow > 0" class="card yellow-card">{{ match.ayellow }}</div>
            <div v-if="match.ared > 0" class="card red-card">{{ match.ared }}</div>
          </div>
          <div class="cm_half_time">
            <span><span class="only_mobile">İY<br></span>{{ match.hscoreHT }} - {{ match.ascoreHT }}</span>
          </div>
          <div class="cm_corner"><span class="only_mobile">K<br></span>{{ match.hcorner }} - {{ match.acorner }}
          </div>
          <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
          <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
          <div class="cm_match_status">
            <div :class="matchStatus(match).style" v-text="matchStatus(match).text"/>
          </div>
        </div>

        <div class="team-matches-for-mobile" v-for="match in league.matches" :key="`bt-${match.id}`">
          <div class="cm_date">{{ matchDateForMobile(match.dateTimeUtc) }}</div>
          <div class="cm_home_team">
          <span :class="teamNameClass(match.homeTeamId)" @click="toTeamDetail(match.homeTeamId)">{{
              match.homeTeam
            }}</span>
          </div>
          <div class="cm_score">
            <router-link v-if="match.live || match.ended" :to="{name: 'MDInfo', params: {id: match.id}}" target="_blank">
              {{ match.hscoreFT }} - {{ match.ascoreFT }}
            </router-link>
            <router-link v-else :to="{name: 'MDCompare', params: {id: match.id}}" target="_blank">
              -
            </router-link>
          </div>
          <div class="cm_away_team">
          <span :class="teamNameClass(match.awayTeamId)" @click="toTeamDetail(match.awayTeamId)">{{
              match.awayTeam
            }}</span>
          </div>
          <div class="cm_under_over" :class="underOver(match).style" v-text="underOver(match).value"></div>
          <div class="cm_bts" :class="bts(match).style" v-text="bts(match).value"></div>
          <div class="cm_match_status">
            <div :class="matchStatus(match).style" v-text="matchStatus(match).text"/>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TeamMatches",
  data() {
    return {
      sortBy: 0,
      side: 0,
    }
  },
  methods: {
    matchDate(value) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    matchDateForMobile(value) {
      return dayjs(value).format("DD/MM/YY");
    },

    underOver(match) {
      if (match.live || match.ended) {
        return (match.hscoreFT + match.ascoreFT) > 2.5 ?
            {value: 'Ü', style: 'success--text'} :
            {value: 'A', style: 'error--text'}
      } else {
        return {value: '', style: ''};
      }
    },

    bts(match) {
      if (match.live || match.ended) {
        return (match.hscoreFT > 0 && match.ascoreFT > 0) ?
            {value: 'Var', style: 'success--text'} :
            {value: 'Yok', style: 'error--text'}
      } else {
        return {value: '', style: ''};
      }

    },

    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },

    teamNameClass(id) {
      if (id === this.$parent.$data.team.id) {
        return 'team_highlight';
      } else {
        return '';
      }
    },


    matchStatus(match) {
      if (match) {
        //match.wi === this.$parent.$data.team.id
        if (match.homeTeamId === this.$route.params.id) {
          if (match.hscoreFT > match.ascoreFT) {
            return {style: 'win', text: 'G'}
          } else {
            return {style: 'loss', text: 'M'}
          }
        } else {
          if ( match.hscoreFT>match.ascoreFT) {
            return {style: 'win', text: 'G'}
          } else {
            return {style: 'loss', text: 'M'}
          }
        }
        /*if (match.hscoreTOT > match.ascoreTOT) {
          return {style: 'win', text: 'G'}
        } else {
          return {style: 'loss', text: 'M'}
        }*/
      } else {
        if (match.ended || match.live) {
          return {style: 'draw', text: 'B'}
        } else {
          return {style: '', text: ''}
        }
      }
    }
  },

  computed: {
    matchList() {
      if (this.sortBy === 0) {
        if (this.side === 0) {
          return this.$parent.$data.seasonMatches;
        } else if (this.side === 1) {
          let leagues = [];
          let data = this.$parent.$data.seasonMatches;
          data.map(league => {
            let matches = league.matches.filter(m => m.homeTeamId === this.$parent.$data.team);
            if (matches.length > 0) {
              let leagueObj = [];
              leagueObj.league = league.matches[0].competitionName;
              leagueObj.matches = matches;
              leagues.push(leagueObj);
            }
          });
          return leagues;
        } else {
          let leagues = [];
          let data = this.$parent.$data.seasonMatches;
          data.map(league => {
            let matches = league.matches.filter(m => m.awayTeamId === this.$parent.$data.team);
            if (matches.length > 0) {
              let leagueObj = [];
              leagueObj.league = league.matches[0].competitionName;
              leagueObj.matches = matches;
              leagues.push(leagueObj);
            }
          });
          return leagues;
        }
      } else {
        if (this.side === 0) {
          return this.$parent.$data.matches;
        } else if (this.side === 1) {
          let leagues = [];
          let data = this.$parent.$data.matches;
          data.map(league => {
            let matches = league.matches.filter(m => m.homeTeamId === this.$parent.$data.team);
            if (matches.length > 0) {
              let leagueObj = [];
              leagueObj.league = league.matches[0].competitionName;
              leagueObj.matches = matches;
              leagues.push(leagueObj);
            }
          });
          return leagues;
        } else {
          let leagues = [];
          let data = this.$parent.$data.matches;
          data.map(league => {
            let matches = league.matches.filter(m => m.awayTeamId === this.$parent.$data.team);
            if (matches.length > 0) {
              let leagueObj = [];
              leagueObj.league = league.matches[0].competitionName;
              leagueObj.matches = matches;
              leagues.push(leagueObj);
            }
          });
          return leagues;
        }
      }
    }
  },
}
</script>

<style scoped>
.team_highlight {
  font-weight: bold;
}

.team-matches-for-mobile {
  display: none;
}

.filter_bar {
  padding-right: 10px;
  padding-left: 10px;
}

.sub_tab {
  text-transform: capitalize;
  letter-spacing: 0;
  min-width: 0;
  font-size: 12px;
  padding: 0 7px;
}

.cm_match_status div {
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  padding-top: 1px;
  text-align: center;
  color: white;
  margin: 3px auto 0 auto;
}

.cm_match_status .win {
  background-color: #44ac45;
}

.cm_match_status .loss {
  background-color: #eb3f30;
}

.cm_match_status .draw {
  background-color: #ffb22f;
}

.cm_score, .cm_half_time, .cm_bts, .cm_under_over, .ch_bts, .ch_under_over, .ch_corner, .cm_corner, .ch_half_time {
  text-align: center;
}

.cm_bts, .cm_under_over {
  font-weight: bold;
}

.cm_score {
  font-weight: 800;
}

.cm_half_time, .cm_corner {
  color: #b2b2b2;
}

.cm_league, .cm_home_team, .cm_away_team {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cm_home_team span, .cm_away_team span {
  cursor: pointer;
}

@media only screen and (min-width: 650px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(120px, 300px) 50px minmax(120px, 300px) 45px 45px 30px 30px 30px;
  }

  .c_header_league {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: auto 45px 45px 30px 30px 30px;
  }

  .team-matches-for-web {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px 36px minmax(84px, 300px) 50px minmax(84px, 300px) 36px 45px 45px 30px 30px 30px;
  }

  .cm_home_team {
    text-align: right;
  }

  .cm_home_team .cards {
    margin-left: auto;
  }

  .cm_half_time .only_mobile, .cm_corner .only_mobile {
    display: none;
  }

  .sub_tab.only_mobile {
    margin-left: auto;
  }

}

@media only screen and (max-width: 650px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px 30px;
    grid-template-areas: "date teams score underover bts status";
  }

  .c_header_league {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns:auto 40px 30px 30px 30px;
    grid-template-areas: "league score underover bts status";
  }

  .team-matches-for-mobile {
    display: grid;
    place-items: center;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(50px, 150px) auto minmax(50px, 150px) auto repeat(2, 30px);
    grid-template-areas: "date hometeam score awayteam underover bts status";
  }

  .ch_date {
    grid-area: date
  }

  .ch_score {
    grid-area: score
  }

  .ch_half_time {
    grid-area: halftime
  }

  .ch_under_over {
    grid-area: underover
  }

  .ch_bts {
    grid-area: bts
  }

  .ch_away_team {
    display: none
  }

  .ch_home_team {
    grid-area: teams
  }

  .team-matches-for-web {
    display: none;
  }

  .ch_half_time, .ch_corner {
    display: none;
  }

  .cm_date {
    padding-top: 0;
    grid-area: date;
  }

  .cm_home_team {
    grid-area: hometeam;
    text-align: right;
    width: 100%;
  }

  .cm_away_team {
    grid-area: awayteam;
    text-align: left;

    width: 100%;
  }

  .cm_score {
    padding-top: 0;
    grid-area: score;
  }

  .cm_half_time {
    display: none;
  }

  .cm_corner {
    display: none;
  }

  .cm_under_over {
    padding-top: 0;
    grid-area: underover;
  }

  .cm_bts {
    padding-top: 0;
    grid-area: bts;
  }

  .cards {
    display: none;
  }

  .cm_match_status {
    padding-top: 0;
    grid-area: status;
  }
}

@media only screen and (max-width: 512px) {
  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding-left: 5px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px 30px;
    grid-template-areas: "date teams score underover bts status";
  }

  .c_header_league {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding-left: 5px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns:auto 40px 30px 30px 30px;
    grid-template-areas: "league score underover bts status";
  }

  .team-matches-for-mobile {
    display: grid;
    place-items: center;
    line-height: 23px;
    padding: 0;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(50px, 100px) auto minmax(50px, 100px) repeat(3, 30px);
    grid-template-areas: "date hometeam score awayteam underover bts status";
  }
}

@media only screen and (max-width: 426px) {

  .team-matches-for-web {
    display: none;
  }

  .c_header {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(90px, auto) 40px 30px 30px 30px;
    grid-template-areas: "date teams score underover bts status";
  }

  .c_header_league {
    display: grid;
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0;
    color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns:auto 40px 30px 30px 30px;
    grid-template-areas: "league score underover bts status";
  }

  .team-matches-for-mobile {
    display: grid;
    place-items: center;
    line-height: 23px;
    padding: 0;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 60px minmax(50px, 100px) auto minmax(50px, 100px) repeat(3,30px);
    grid-template-areas: "date hometeam score awayteam underover bts status";
  }

  .cm_home_team {
    grid-area: hometeam;
    text-align: right;
    width: 100%;
  }

  .cm_away_team {
    grid-area: awayteam;
    text-align: left;
    width: 100%;
  }

  .cm_date {
    padding-top: 0;
    grid-area: date;
  }

  .cm_score {
    padding-top: 0;
    margin: 0 5px;
    grid-area: score;
  }

  .cm_under_over {
    padding-top: 0;
    grid-area: underover;
  }

  .cm_bts {
    padding-top: 0;
    grid-area: bts;
  }

  .cm_match_status {
    padding-top: 0;
    grid-area: status;
  }

}
</style>
