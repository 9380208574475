<template>
    <div v-if="loaded" class="counter-section">
      <div>
        <h3 class="kickoff-and-match-starting" v-if="!expired">BAŞLAMA SAATİ</h3>
        <h3 class="kickoff-and-match-starting" v-else>MAÇ BAŞLIYOR</h3>
      </div>

      <div class="counter-container">
          <div class="time-container">
            <span class="time"> {{displayDays}}</span>
            <span class="font-size" style="font-weight: lighter">DAYS</span>
          </div>
          <div class="time-container">
            <span class="time">{{displayHours}}</span>
            <span class="font-size" style="font-weight: lighter">HRS</span>
          </div>
          <div class="time-container">
            <span class="time">{{displayMinutes}}</span>
            <span class="font-size" style="font-weight: lighter">MINS</span>
          </div>
          <div class="time-container">
            <span class="time">{{displaySeconds}}</span>
            <span class="font-size" style="font-weight: lighter">SECS</span>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Counter",
  data() {
    return {
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      loaded: false,
      expired: false,
    }
  },
  props: {
    matchTeam: Object
  },
  computed: {
    _seconds: () => 1000,
    _minutes () {
      return this._seconds * 60;
    },
    _hours () {
      return this._minutes * 60;
    },
    _days () {
      return this._hours * 24;
    },
  },
  mounted() {
    this.showRemaining();
  },
  methods: {
    formatNum: num => (num < 10 ? "0" + num : num),
    showRemaining () {
      const timer = setInterval(() => {
        const now = new Date();
        const unixTime = this.matchTeam._dt.uts;
        const unixTimeMiliseconds = unixTime * 1000;
        const distance = unixTimeMiliseconds - now.getTime();

        if (distance < 0 ) {
          clearInterval(timer);
          this.expired = true;
          this.loaded = true;
          return;
        }

        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displayMinutes = this.formatNum(minutes);
        this.displaySeconds = this.formatNum(seconds);
        this.displayHours = this.formatNum(hours);
        this.displayDays = this.formatNum(days);
        this.loaded = true;
      }, 1000)
    }
  }
}
</script>

<style scoped>

.counter-section {
  display: flex;
  place-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.counter-container {
  display: flex;
  justify-content: space-between;
  place-items: center;
  padding: 0 0.5rem;
}

.counter {
  width: 25%;
  display: flex;
  flex-direction: row;
  background-color: #00aa56;
}

.kickoff-and-match-starting {
  font-size: 12px;
}

.time-container {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
}

.time {
  display: grid;
  place-items: center;
  padding: 5px;
  background-color: red;
  border-radius: 3px;
  color: white;
  margin: 0 0.5rem;
  font-size: 12px;
  font-weight: bold;
}


@media only screen and (max-width: 1264px) {

  .kickoff-and-match-starting {
    font-size: 14px;
  }

  .time {
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .kickoff-and-match-starting {
    font-size: 12px;
  }

  .time {
    font-size: 15px;
    margin: 0 0.3rem;
  }
}

@media only screen and (max-width: 540px) {
  .kickoff-and-match-starting {
    font-size: 10px;
  }

  .time {
    font-size: 10px;
    margin: 0 0.2rem;
  }
}

@media only screen and (max-width: 420px) {
  .kickoff-and-match-starting {
    font-size: 8px;
  }

  .time {
    font-size: 8px;
    margin: 0 0.2rem;
  }
}
</style>