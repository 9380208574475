<template>
    <div

            class="popular_leagues_container"
    >
        <v-skeleton-loader
                v-if="!popularLeagues"
                type="image"
                class="popular_leagues_skeleton"
                height="50"
        ></v-skeleton-loader>
        <v-slide-group v-if="popularLeagues" show-arrows>
            <v-slide-item
                    v-for="league in popularLeagues"
                    :key="league.seasonId"
                    class="slide-item"
            >
                <router-link class="league-button-a" :to="{
                                    name: 'LeagueDetail',
                                    params:
                                    {
                                        categoryId: league.areaId,
                                        seasonId: league.seasonId,
                                        leagueId: league.leagueId,
                                    }}">
                    <div class="league-button">
                        <v-img
                                class="float-left league-button-img"
                                :src="getImgUrl(league.areaId)"
                                :lazy-src="getImgUrl(league.areaId)"
                        />
                        <span class="float-left">{{league.league}}</span>
                    </div>
                </router-link>
            </v-slide-item>
        </v-slide-group>
    </div>
</template>

<script>
    import {fetchPopularLeagues} from "../../api";

    export default {
        name: "PopularLeagues",
        data: () => ({
            popularLeagues: null,
        }),

        mounted() {
            this.fetchPopularLeagues();
        },

        methods: {
            async fetchPopularLeagues() {
               const data = await fetchPopularLeagues();
               this.popularLeagues = data.data.data
            },

            getImgUrl(code) {
                if (code === '46') {
                    let images = require.context('../../assets/league-icons/', false, /\.png$/);
                    return images('./' + code + ".png")
                } else {
                    let images = require.context('../../assets/league-icons/', false, /\.svg$/);
                    return images('./' + code + ".svg")
                }
            }
        }
    }
</script>

<style scoped>
    .popular_leagues_container{
        width: 100%;
        max-width: 1115px;
        padding: 0 10px;
        margin: 10px auto 0 auto;
    }

    .popular_leagues_skeleton{
        margin: 10px 0;
    }

    .league-button {
        height: 40px;
        line-height: 40px;
        background-color: #ffffff;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 4px;
        padding-right: 17px;
        padding-left: 5px;
        font-size: 12px;
        box-shadow: 0 2px 5px 0 rgba(36, 94, 113, 0.2);
    }

    .league-button-img {
        width: 30px;
        height: 30px;
        margin-top: 5px;
        margin-right: 5px;
        border-radius: 60%;
    }

    @media only screen and (min-width: 1115px) {
        .slide-item.league-button-a:not(:first-child):not(:last-child) {
            margin-left: auto;
            margin-right: auto;
        }

        .slide-item.league-button-a:first-child {
            margin-right: auto;
        }

        .slide-item.league-button-a:last-child {
            margin-left: auto;
        }
    }
    @media only screen and (max-width: 1115px) {
        .slide-item.league-button-a {
            margin-right: 5px;
        }
    }

    @media only screen and (max-width: 426px) {
      .popular_leagues_container {
        display: none;
      }

    }
</style>
